import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('Permission')
export class Permission extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('name', String)
    private _name: string;
    @prop()
    get id() {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }
    @prop()
    get name(): string {
        return this._name;
    }

    set name(name: string) {
        this._name = name;
    }
}
