
import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('ODdurationCountDetail')
export class ODdurationCountDetail extends Base{
    @JsonProperty('seconds', Number)
    private _seconds: number;
    @JsonProperty('counts', Number)
    private _counts: number;
    
    

    @prop()
    get seconds(): number {
        return this._seconds;
    }

    set seconds(seconds: number) {
        this._seconds = seconds;
    }

    @prop()
    get counts(): number {
        return this._counts;
    }

    set counts(counts: number) {
        this._counts = counts;
    }
    

}
