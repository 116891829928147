import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';


@JsonObject('TimeForEndurance')
export class TimeForEndurance {

    @JsonProperty('time_flag', String)
    private _time_flag: string;
    @JsonProperty('time_for_endurance', Number)
    private _time_for_endurance: number;
    @JsonProperty('success_threshold_pressure', Number)
    private _success_threshold_pressure: number;
    @JsonProperty('fail_threshold_pressure', Number)
    private _fail_threshold_pressure: number;

    @prop()
    get time_flag(): string {
        return this._time_flag;
    }
    set time_flag(time_flag: string) {
        this._time_flag = time_flag;
    }

    @prop()
    get time_for_endurance(): number {
        return this._time_for_endurance;
    }
    set time_for_endurance(time_for_endurance: number) {
        this._time_for_endurance = time_for_endurance;
    }

    @prop()
    get success_threshold_pressure(): number {
        return this._success_threshold_pressure;
    }
    set success_threshold_pressure(success_threshold_pressure: number) {
        this._success_threshold_pressure = success_threshold_pressure;
    }

    @prop()
    get fail_threshold_pressure(): number {
        return this._fail_threshold_pressure;
    }
    set fail_threshold_pressure(fail_threshold_pressure: number) {
        this._fail_threshold_pressure = fail_threshold_pressure;
    }
}
