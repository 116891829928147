import { Injectable, InjectionToken, Inject, Injector} from '@angular/core';
import { LocationService } from 'src/app/service/location-service';
import { User } from 'src/app/model/user';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { UserDao } from 'src/app/dao/user-dao';
import { LocationDao } from 'src/app/dao/location-dao';
import { Location } from 'src/app/model/location';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const apiUrl = 'https://jsonplaceholder.typicode.com/posts/42';

@Injectable({
    providedIn: 'root'
})
export class LocationServiceImpl implements LocationService {
    userDao: UserDao = this.injector.get(UserDao);
    locationDao: LocationDao = this.injector.get(LocationDao);
    constructor(private injector: Injector,private http:HttpClient) {}

    getLocation(): any {
        return this.locationDao.getLocation();
    }

    getLocationList(): Observable<any> {
        return this.locationDao.getLocationList();
    }
    
    addLocation(location): Observable<any> {
        //console.log(user);
        return this.locationDao.addLocation(location);
    }
    editLocation(location): Observable<any> {
        console.log(location);
        return this.locationDao.editLocation(location);
    }
    getUserAPI(user): Observable<any> {
        console.log(user);
        return this.locationDao.getUserAPI(user);
    }
    getTestUser(): Observable<any> {
        return this.locationDao.getTestUser();
    }
    login(user): Observable<any> {
        return this.locationDao.login(user).pipe(
            map(userWebResponse => {
                console.log(userWebResponse.success);
                if ( userWebResponse.success === true ) {
                    return new User(userWebResponse.data);
                }
                else {
                    return false;
                }
            })
        );
    }
    forgotpassword(user): Observable<any> {
        return this.locationDao.forgotpassword(user).pipe(
            map(userWebResponse => {
                console.log(userWebResponse.success);
                return  userWebResponse.success;
            })
        );
    }
    logout(user): Observable<any> {
        console.log(user);
        return this.locationDao.logout(user);
    }

    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
      
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
      
          // TODO: better job of transforming error for user consumption
          console.log(`${operation} failed: ${error.message}`);
      
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }

}

// export const UserServiceProvider = new InjectionToken(
//     'UserServiceProvider',
//     { providedIn: 'root', factory: () => new UserServiceImpl(@Inject(ENV) private environment) }
// );
