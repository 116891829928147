import { Injectable, InjectionToken } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { User } from 'src/app/model/user';
import { UserWebResponse } from 'src/app/model/user-web-response';

@Injectable()
export abstract class UserDao {
    abstract getUser(): any;
    abstract getUserList(): Observable<any>;
    abstract addUser(user): Observable<any>;
    abstract editUser(user): Observable<any>;
    abstract editProfile(user): Observable<any>;
    abstract editPassword(user): Observable<any>;
    abstract getUserAPI(user): Observable<any>;
    abstract getPhoto(): Observable<any>;
    abstract editPhoto(file): Observable<any>;
    abstract getTestUser(): Observable<any>;
    abstract login(user): Observable<any>;
    abstract getLoginUser(user_id): Observable<any>;
    abstract getMapList(filtering,map_access_token): Observable<any>;
    abstract forgotpassword(user): Observable<any>;
    abstract logout(user): Observable<any>;
}
