import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('PieDetail')
export class PieDetail extends Base{
    
    @JsonProperty('pieLable', String)
    private _pieLable: string;
    @JsonProperty('pieValue', Number)
    private _pieValue: number;

    @prop()
    get pieLable(): string {
        return this._pieLable;
    }
    set pieLable(pieLable: string) {
        this._pieLable = pieLable;
    }

    @prop()
    get pieValue(): number {
        return this._pieValue;
    }
    set pieValue(pieValue: number) {
        this._pieValue = pieValue;
    }

}