
import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('OddurationDetail')
export class OddurationDetail extends Base{
    @JsonProperty('start', String)
    private _start: string;
    @JsonProperty('duration', Number)
    private _duration: number;
    
    

    @prop()
    get start(): string {
        return this._start;
    }

    set start(start: string) {
        this._start = start;
    }

    @prop()
    get duration(): number {
        return this._duration;
    }

    set duration(duration: number) {
        this._duration = duration;
    }
    

}
