import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../core/settings/settings.service';
import { environment } from 'src/environments/environment';
@Component({
    selector: '[app-footer]',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    china_license:boolean;//20231017
    constructor(public settings: SettingsService) { }

    ngOnInit() {
        this.china_license = environment.china_license//20231017
    }

}
