import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert, Any} from 'json2typescript';

@JsonObject('WebDevice')
export class WebDevice extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('patient_id', Number)
    private _patient_id: number;
    @JsonProperty('type', String)
    private _type: string;
    @JsonProperty('serial', String)
    private _serial: string;
    @JsonProperty('cloud_device_id', Number)
    private _cloud_device_id: number;
    @JsonProperty('cloud_device_link', Any)
    private _cloud_device_link: any;

    @prop()
    get id() {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }
    
    @prop()
    get patient_id(): number {
        return this._patient_id;
    }

    set patient_id(patient_id: number) {
        this._patient_id = patient_id;
    }

    @prop()
    get type(): string {
        return this._type;
    }

    set type(type: string) {
        this._type = type;
    }

    @prop()
    get serial(): string {
        return this._serial;
    }

    set serial(serial: string) {
        this._serial = serial;
    }

    @prop()
    get cloud_device_id(): number {
        return this._cloud_device_id;
    }

    set cloud_device_id(cloud_device_id: number) {
        this._cloud_device_id = cloud_device_id;
    }

    @prop()
    get cloud_device_link(): any {
        return this._cloud_device_link;
    }

    set cloud_device_link(cloud_device_link: any) {
        this._cloud_device_link = cloud_device_link;
    }


}