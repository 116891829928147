
import { Injectable, InjectionToken } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { User } from 'src/app/model/user';
import { UserWebResponse } from 'src/app/model/user-web-response';
import { Location } from 'src/app/model/location';
import { Organization } from 'src/app/model/organization';
@Injectable()
export abstract class OrganizationDao {
    abstract getOrganization(): any;
    abstract getOrganizationList(): Observable<any>;
    abstract addOrganization(organization): Observable<any>;
    abstract editOrganization(organization): Observable<any>;
    abstract getUserAPI(user): Observable<any>;
    abstract getTestUser(): Observable<any>;
    abstract login(user): Observable<any>;
    abstract forgotpassword(user): Observable<any>;
    abstract logout(user): Observable<any>;
}
