import * as d3 from 'd3';
import d3_save_pdf from 'd3-save-pdf';
import { jsPDF } from "jspdf";
import { Injectable, InjectionToken, Inject, Injector } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user-service';
import { Router } from '@angular/router';

import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';
import { PatientService } from 'src/app/service/patient-service';
import { Patient } from 'src/app/model/patient';
import { DeviceSetting } from 'src/app/model/device-setting';
import { Data } from 'src/app/model/data';
import { ChartListLength } from 'src/app/model/chart-list-length';
import * as CryptoJS from 'crypto-js';
@Injectable({
    providedIn: 'root',
})

export class SdkViewModel {
    userService: UserService = this.injector.get(UserService);
    patientService: PatientService = this.injector.get(PatientService);
    router: Router;
    selectPatient:Patient;
    loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
    
    private totalImg = 11;
    private countImg = 0;
    private totalReportImg = 7;
    private countReportImg = 0;
    //private doc = new jsPDF('p', 'pt', [1024,5000]);//new jsPDF('p', 'pt', [1024,4096]);
    private docReport = new jsPDF('p', 'pt', [1024,3000]);//new jsPDF('p', 'pt', [1024,5000]);
    private w: number = 1000;
    private h: number = 400;
    private h_pie: number = 280;
    private margin = { top: 10, right: 50, bottom: 60, left: 100 };
    private width = this.w - this.margin.left - this.margin.right;
    private height = this.h - this.margin.top - this.margin.bottom;
    private height_pie = this.h_pie - this.margin.top - this.margin.bottom;
    

    private wordData: any;
    private chartListLength: ChartListLength;
    //-- Chart Image Data
    private treatmentChartData: any;
    private complianceChartData: any;
    private odiChartData: any;
    private pressureChartData: any;
    private spo2ChartData: any;
    private heartrateChartData: any;
    private odDurationChartData: any;
    private odDurationCountChartData: any;
    private pumpingtTimeChartData: any;
    private fitbitHeartChartData: any;
    private fitbitSleepChartData: any;
    //---Report
    private wordSomnicsLogoData: any;
    private wordPatientData: any;
    private wordSomnicsData: any;
    private wordOverviewData: any;
    private wordPatientDetailData: any;
    private wordTherapyData: any;
    private wordReportData: any;
    private imgPieData: any;
    private imgCompliancePieData: any;
    private imgUsageData: any;
    private imgUsageComplianceData: any;
    private imgODIData: any;
    private imgTreatData: any;
    private imgTreatComplianceData: any;
    private pdfName = '';
    private pdfReportName = '';
    private chart_detail = 0;

    constructor(private injector: Injector) {
        this.router = this.injector.get(Router);
        console.log(this.loginViewModel.loginUser.token.access_token);
    }

    public input = new class {
        public update: Observable<any>;
        public chooseDate: Observable<any>;
        public chooseType: Observable<any>;
        public editDeviceSetting: Observable<any>;
        public editremotetherapy: Observable<any>;
        public editmemonote: Observable<any>;
        public chooseTongueDate: Observable<any>;
        public chooseTongueTime: Observable<any>;

        public downloadImage: Observable<any>;
        public treatmentImage: Observable<any>;
        public complianceImage: Observable<any>;
        public odiImage: Observable<any>;
        public pressureImage: Observable<any>;
        public spo2Image: Observable<any>;
        public heartrateImage: Observable<any>;
        public odDurationImage: Observable<any>;
        public odDurationCountImage: Observable<any>;
        public pumpingtTimeImage: Observable<any>;
        public fitbitHeartImage: Observable<any>;
        public fitbitSleepImage: Observable<any>;

        //----Report-----
        public report: Observable<any>;
        public downloadReportImage: Observable<any>;
        public reportPieImage: Observable<any>;
        public reportCompliancePieImage: Observable<any>;
        public reportUsageImage: Observable<any>;
        public reportUsageComplianceImage: Observable<any>;
        public reportODIImage: Observable<any>;
        public reportTreatImage: Observable<any>;
        public reportTreatComplianceImage: Observable<any>;
    
    
    };

    public output = new class {
        public chart = new Subject<User>();
        public dateTrend = new Subject<Data>();
        public typeTrend = new Subject<any>();
        public devicesetting = new Subject<any>();
        public remotetherapy = new Subject<any>();
        public memonote = new Subject<any>();
        public dateTongue = new Subject<User>();
        public timeTongue = new Subject<User>();

        public getHeartrateImgData = new Subject<any>();
        public getOdiImgData = new Subject<any>();
        public getPressureImgData = new Subject<any>();
        public getSpo2ImgData = new Subject<any>();
        public getTreatmentImgData = new Subject<any>();
        public getComplianceImgData = new Subject<any>();
        public getOdDurationImageData = new Subject<any>();
        public getOdDurationCountImageData = new Subject<any>();
        public getPumpingtTimeImageData = new Subject<any>();
        public getFitbitHeartImageData = new Subject<any>();
        public getFitbitSleepImageData = new Subject<any>();
    
        //----Report-----
        public getReportPieImgData = new Subject<any>();
        public getReportCompliancePieImgData = new Subject<any>();
        public getReportUsageImgData = new Subject<any>();
        public getReportUsageComplianceImgData = new Subject<any>();
        public getReportODIImgData = new Subject<any>();
        public getReportTreatImgData = new Subject<any>();
        public getReportTreatComplianceImgData = new Subject<any>();
    
    
    };

    setup_report(): boolean {
        let isSetUp = false;
        if (this.input.report != null) {
            this.onViewClick_report();
            isSetUp = true;
        }
        return isSetUp;
    }

    onViewClick_report(): void {
        this.input.report.subscribe((patient) => {
            this.patientService
            .getReport(patient)
            .subscribe((report) => {
                patient.report = report;
                this.output.chart.next(patient);
            });
        });
    }


    setup_update(): boolean {
        let isSetUp = false;
        if (this.input.update != null) {
            this.onViewClick_update();
            isSetUp = true;
        }
        return isSetUp;
    }

    onViewClick_update(): void {
        this.input.update.subscribe((patient) => {
            this.patientService
            .getClouddata(patient)
            .subscribe((data) => {

                try {
                    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'somnicSecretKey').toString();
                      localStorage.setItem('selectChartData', ciphertext);
                } catch (error) {
                    console.error('Error saving to localStorage', error);
                }

                patient.data = data;
                this.pdfName = '';
                if(patient.email != 'email'){
                    this.pdfName += patient.email+'_';
                }
                if(data.serial != undefined && data.datetime_full != undefined){
                    let datetime_full = data.datetime_full.replace('/', '-').replace('/', '-');
                    this.pdfName += datetime_full;
                    var re = /,/g;
                    var serial = data.serial.replace(re, '_');
                    this.pdfName += serial;
                }
                this.pdfName += '.pdf';
                
                this.output.chart.next(patient);
            });
        });
    }

    setup_downloadImage(): boolean {
        let isSetUp = false;
        if (this.input.downloadImage != null) {
            this.onViewClick_downloadImage();
            isSetUp = true;
        }
        return isSetUp;
    }
    onViewClick_downloadImage(): void {
        this.input.downloadImage.subscribe((chartData) => {
            //this.wordData = imgData;
            this.chart_detail = chartData.chart_detail;
            if(this.chart_detail == 0){
                this.totalImg = 10;
            }
            
            this.wordData = chartData.wordData;
            this.chartListLength = chartData.chartListLength;
            this.countImg = 0;
            this.output.getHeartrateImgData.next();
            this.output.getOdiImgData.next();
            this.output.getPressureImgData.next();
            this.output.getSpo2ImgData.next();
            this.output.getTreatmentImgData.next();
            this.output.getComplianceImgData.next();
            this.output.getOdDurationImageData.next();
            this.output.getOdDurationCountImageData.next();
            this.output.getPumpingtTimeImageData.next();
            this.output.getFitbitHeartImageData.next();
            this.output.getFitbitSleepImageData.next();
        
        });
    }

    //不分頁
    saveChartPDF_no_page(): void {
        //for debug
        /*this.chartListLength.treatment = 1;
        this.chartListLength.compliance = 1;
        this.chartListLength.odDuration = 1;
        this.chartListLength.odDurationCount = 1;
        this.chartListLength.odi = 1;
        this.chartListLength.pumping = 1;
        this.chartListLength.pressure = 1;
        this.chartListLength.spo2 = 1;
        this.chartListLength.heartrate = 1;
        this.chartListLength.fitbitHeart = 1;
        this.chartListLength.fitbitSleep = 1;*/

        var valueCount = 0;
        if(this.chartListLength.treatment > 0 && this.chart_detail == 1){
            valueCount += 1;
        };
        if(this.chartListLength.compliance > 0){
            valueCount += 1;
        };
        if(this.chartListLength.odDuration > 0){
            valueCount += 1;
        };
        if(this.chartListLength.odDurationCount > 0){
            valueCount += 1;
        };
        if(this.chartListLength.odi > 0){
            valueCount += 1;
        };
        if(this.chartListLength.pumping > 0){
            valueCount += 1;
        };
        if(this.chartListLength.pressure > 0){
            valueCount += 1;
        };
        if(this.chartListLength.spo2 > 0){
            valueCount += 1;
        };
        if(this.chartListLength.heartrate > 0){
            valueCount += 1;
        };
        if(this.chartListLength.fitbitHeart > 0){
            valueCount += 1;
        };
        if(this.chartListLength.fitbitSleep > 0){
            valueCount += 1;
        };
        var docHeight = 200+400*(1+valueCount);
        var doc = new jsPDF('p', 'pt', [1024,docHeight]);//5000]);
        var yPox = 100;
        doc.addImage(this.wordData, 'PNG', 100,100, this.width,this.height,'', ("SLOW") as any);
        
        if(this.chartListLength.treatment > 0  && this.chart_detail == 1){
            yPox += 400;
            doc.addImage(this.treatmentChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
        }
        if(this.chartListLength.compliance > 0){
            yPox += 400;
            doc.addImage(this.complianceChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
        }
        if(this.chartListLength.odDuration > 0){
            yPox += 400;
            doc.addImage(this.odDurationChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
        }
        if(this.chartListLength.odDurationCount > 0){
            yPox += 400;
            doc.addImage(this.odDurationCountChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
        }
        if(this.chartListLength.odi > 0){
            yPox += 400;
            doc.addImage(this.odiChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
        }
        if(this.chartListLength.pumping > 0){
            yPox += 400;
            doc.addImage(this.pumpingtTimeChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
        }
        if(this.chartListLength.pressure > 0){
            yPox += 400;
            doc.addImage(this.pressureChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
        }
        if(this.chartListLength.spo2 > 0){
            yPox += 400;
            doc.addImage(this.spo2ChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
        }
        if(this.chartListLength.heartrate > 0){
            yPox += 400;
            doc.addImage(this.heartrateChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
        }
        if(this.chartListLength.fitbitHeart > 0){
            yPox += 400;
            doc.addImage(this.fitbitHeartChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
        }
        if(this.chartListLength.fitbitSleep > 0){
            yPox += 400;
            doc.addImage(this.fitbitSleepChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
        }
        doc.save(this.pdfName);
    }
    //分頁
    saveChartPDF(): void {
        //for debug
        /*this.chartListLength.treatment = 1;
        this.chartListLength.compliance = 1;
        this.chartListLength.odDuration = 1;
        this.chartListLength.odDurationCount = 1;
        this.chartListLength.odi = 1;
        this.chartListLength.pumping = 1;
        this.chartListLength.pressure = 1;
        this.chartListLength.spo2 = 1;
        this.chartListLength.heartrate = 1;
        this.chartListLength.fitbitHeart = 1;
        this.chartListLength.fitbitSleep = 1;*/

        var doc = new jsPDF('p', 'pt', [1024,1400]);
        var yPox = 100;
        doc.addImage(this.wordData, 'PNG', 100,100, this.width,this.height,'', ("SLOW") as any);
        var pdfHeight = 900;
        var imgHeight = 400;
        var yStartPox = 100;

        if(this.chartListLength.treatment > 0  && this.chart_detail == 1){
            if( (yPox+imgHeight) > pdfHeight){
                doc.addPage();
                yPox = yStartPox;
                doc.addImage(this.treatmentChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
            else{
                yPox += imgHeight;
                doc.addImage(this.treatmentChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
        }
        if(this.chartListLength.compliance > 0){
            if( (yPox+imgHeight) > pdfHeight){
                doc.addPage();
                yPox = yStartPox;
                doc.addImage(this.complianceChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
            else{
                yPox += imgHeight;
                doc.addImage(this.complianceChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
        }
        if(this.chartListLength.odDuration > 0){
            if( (yPox+imgHeight) > pdfHeight){
                doc.addPage();
                yPox = yStartPox;
                doc.addImage(this.odDurationChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
            else{
                yPox += imgHeight;
                doc.addImage(this.odDurationChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
        }
        if(this.chartListLength.odDurationCount > 0){
            if( (yPox+imgHeight) > pdfHeight){
                doc.addPage();
                yPox = yStartPox;
                doc.addImage(this.odDurationCountChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
            else{
                yPox += imgHeight;
                doc.addImage(this.odDurationCountChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
        }
        if(this.chartListLength.odi > 0){
            if( (yPox+imgHeight) > pdfHeight){
                doc.addPage();
                yPox = yStartPox;
                doc.addImage(this.odiChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
            else{
                yPox += imgHeight;
                doc.addImage(this.odiChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
        }
        if(this.chartListLength.pumping > 0){
            if( (yPox+imgHeight) > pdfHeight){
                doc.addPage();
                yPox = yStartPox;
                doc.addImage(this.pumpingtTimeChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
            else{
                yPox += imgHeight;
                doc.addImage(this.pumpingtTimeChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
        }
        if(this.chartListLength.pressure > 0){
            if( (yPox+imgHeight) > pdfHeight){
                doc.addPage();
                yPox = yStartPox;
                doc.addImage(this.pressureChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
            else{
                yPox += imgHeight;
                doc.addImage(this.pressureChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
        }
        if(this.chartListLength.spo2 > 0){
            if( (yPox+imgHeight) > pdfHeight){
                doc.addPage();
                yPox = yStartPox;
                doc.addImage(this.spo2ChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
            else{
                yPox += imgHeight;
                doc.addImage(this.spo2ChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
        }
        if(this.chartListLength.heartrate > 0){
            if( (yPox+imgHeight) > pdfHeight){
                doc.addPage();
                yPox = yStartPox;
                doc.addImage(this.heartrateChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
            else{
                yPox += imgHeight;
                doc.addImage(this.heartrateChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
        }
        if(this.chartListLength.fitbitHeart > 0){
            if( (yPox+imgHeight) > pdfHeight){
                doc.addPage();
                yPox = yStartPox;
                doc.addImage(this.fitbitHeartChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
            else{
                yPox += imgHeight;
                doc.addImage(this.fitbitHeartChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
        }
        if(this.chartListLength.fitbitSleep > 0){
            if( (yPox+imgHeight) > pdfHeight){
                doc.addPage();
                yPox = yStartPox;
                doc.addImage(this.fitbitSleepChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
            else{
                yPox += imgHeight;
                doc.addImage(this.fitbitSleepChartData, 'PNG', 100,yPox, this.width,this.height,'', ("SLOW") as any);
            }
        }
        doc.save(this.pdfName);
    }

    setup_treatmentImage(): boolean {
        let isSetUp = false;
        if (this.input.treatmentImage != null) {
            this.onViewClick_treatmentImage();
            isSetUp = true;
        }
        return isSetUp;
    }

    onViewClick_treatmentImage(): void {
        this.input.treatmentImage.subscribe((imgData) => {
            this.countImg = this.countImg + 1;
            this.treatmentChartData = imgData;
            if(this.countImg == this.totalImg ){
                this.saveChartPDF();
            }
        });
    }

    setup_complianceImage(): boolean {
        let isSetUp = false;
        if (this.input.complianceImage != null) {
            this.onViewClick_complianceImage();
            isSetUp = true;
        }
        return isSetUp;
    }

    onViewClick_complianceImage(): void {
        this.input.complianceImage.subscribe((imgData) => {
            this.countImg = this.countImg + 1;
            this.complianceChartData = imgData;
            if(this.countImg == this.totalImg ){
                this.saveChartPDF();
            }
        });
    }

    setup_odDurationImage(): boolean {
        let isSetUp = false;
        if (this.input.odDurationImage != null) {
            this.onViewClick_odDurationImage();
            isSetUp = true;
        }
        return isSetUp;
    }

    onViewClick_odDurationImage(): void {
        this.input.odDurationImage.subscribe((imgData) => {
            this.countImg = this.countImg + 1;
            this.odDurationChartData = imgData;
            if(this.countImg == this.totalImg ){
                this.saveChartPDF();
            }      
        });
    }

    setup_odDurationCountImage(): boolean {
        let isSetUp = false;
        if (this.input.odDurationCountImage != null) {
            this.onViewClick_odDurationCountImage();
            isSetUp = true;
        }
        return isSetUp;
    }

    onViewClick_odDurationCountImage(): void {
        this.input.odDurationCountImage.subscribe((imgData) => {
            this.countImg = this.countImg + 1;
            this.odDurationCountChartData = imgData;
            if(this.countImg == this.totalImg ){
                this.saveChartPDF();
            }      
        });
    }


    setup_odiImage(): boolean {
        let isSetUp = false;
        if (this.input.odiImage != null) {
            this.onViewClick_odiImage();
            isSetUp = true;
        }
        return isSetUp;
    }

    onViewClick_odiImage(): void {
        this.input.odiImage.subscribe((imgData) => {
            this.countImg = this.countImg + 1;
            this.odiChartData = imgData;
            if(this.countImg == this.totalImg ){
                this.saveChartPDF();
            }      
        });
    }

    setup_pumpingtTimeImage(): boolean {
        let isSetUp = false;
        if (this.input.pumpingtTimeImage != null) {
            this.onViewClick_pumpingtTimeImage();
            isSetUp = true;
        }
        return isSetUp;
    }

    onViewClick_pumpingtTimeImage(): void {
        this.input.pumpingtTimeImage.subscribe((imgData) => {
            this.countImg = this.countImg + 1;
            this.pumpingtTimeChartData = imgData;
            if(this.countImg == this.totalImg ){
                this.saveChartPDF();
            }      
        });
    }

    setup_pressureImage(): boolean {
        let isSetUp = false;
        if (this.input.pressureImage != null) {
            this.onViewClick_pressureImage();
            isSetUp = true;
        }
        return isSetUp;
    }

    onViewClick_pressureImage(): void {
        this.input.pressureImage.subscribe((imgData) => {
            this.countImg = this.countImg + 1;
            this.pressureChartData = imgData;
            if(this.countImg == this.totalImg ){
                this.saveChartPDF();
            }      
        });
    }

    setup_spo2Image(): boolean {
        let isSetUp = false;
        if (this.input.spo2Image != null) {
            this.onViewClick_spo2Image();
            isSetUp = true;
        }
        return isSetUp;
    }
    onViewClick_spo2Image(): void {
        this.input.spo2Image.subscribe((imgData) => {
            this.countImg = this.countImg + 1;
            this.spo2ChartData = imgData;
            if(this.countImg == this.totalImg ){
                this.saveChartPDF();
            }      
        });
    }

    setup_heartrateImage(): boolean {
        let isSetUp = false;
        if (this.input.heartrateImage != null) {
            this.onViewClick_heartrateImage();
            isSetUp = true;
        }
        return isSetUp;
    }

    onViewClick_heartrateImage(): void {
        this.input.heartrateImage.subscribe((imgData) => {
            this.countImg = this.countImg + 1;
            this.heartrateChartData = imgData;
            if(this.countImg == this.totalImg ){
                this.saveChartPDF();
            }      
        });
    }

    setup_fitbitHeartImage(): boolean {
        let isSetUp = false;
        if (this.input.fitbitHeartImage != null) {
            this.onViewClick_fitbitHeartImage();
            isSetUp = true;
        }
        return isSetUp;
    }

    onViewClick_fitbitHeartImage(): void {
        this.input.fitbitHeartImage.subscribe((imgData) => {
            this.countImg = this.countImg + 1;
            this.fitbitHeartChartData = imgData;
            if(this.countImg == this.totalImg ){
                this.saveChartPDF();
            }      
        });
    }

    setup_fitbitSleepImage(): boolean {
        let isSetUp = false;
        if (this.input.fitbitSleepImage != null) {
            this.onViewClick_fitbitSleepImage();
            isSetUp = true;
        }
        return isSetUp;
    }

    onViewClick_fitbitSleepImage(): void {
        this.input.fitbitSleepImage.subscribe((imgData) => {
            this.countImg = this.countImg + 1;
            this.fitbitSleepChartData = imgData;
            if(this.countImg == this.totalImg ){
                this.saveChartPDF();
            }      
        });
    }

    //------Report--------
    //不分頁
    saveReportPDF_no_page(): void {
        if(this.chart_detail == 1){
            this.docReport.addImage(this.wordSomnicsLogoData, 'PNG', 100,100, 150,150,'', ("SLOW") as any);
            this.docReport.addImage(this.wordPatientData, 'PNG', 250,100, this.width-200,this.height,'', ("SLOW") as any);
            this.docReport.addImage(this.wordSomnicsData, 'PNG', 750,250, 200,30,'', ("SLOW") as any);
            this.docReport.addImage(this.wordOverviewData, 'PNG', 100,300, this.width,this.height,'', ("SLOW") as any);
            this.docReport.addImage(this.wordPatientDetailData, 'PNG', 100,400, this.width,this.height,'', ("SLOW") as any);
            this.docReport.addImage(this.wordTherapyData, 'PNG', 100,600, this.width,this.height,'', ("SLOW") as any);
            this.docReport.addImage(this.imgPieData, 'PNG', 100,700, this.width,this.height_pie,'', ("SLOW") as any);
            this.docReport.addImage(this.imgCompliancePieData, 'PNG', 100,900, this.width,this.height_pie,'', ("SLOW") as any);
            this.docReport.addImage(this.wordReportData, 'PNG', 100,1100, this.width,this.height,'', ("SLOW") as any);
            this.docReport.addImage(this.imgUsageData, 'PNG', 100,1500, this.width,this.height_pie,'', ("SLOW") as any);
            this.docReport.addImage(this.imgUsageComplianceData, 'PNG', 100,1800, this.width,this.height_pie,'', ("SLOW") as any);
            this.docReport.addImage(this.imgODIData, 'PNG', 100,2100, this.width,this.height_pie,'', ("SLOW") as any);
            this.docReport.addImage(this.imgTreatData, 'PNG', 100,2400, this.width,this.height_pie,'', ("SLOW") as any);
            this.docReport.addImage(this.imgTreatComplianceData, 'PNG', 100,2700, this.width,this.height_pie,'', ("SLOW") as any);
        }
        else{
            this.docReport.addImage(this.wordSomnicsLogoData, 'PNG', 100,100, 150,150,'', ("SLOW") as any);
            this.docReport.addImage(this.wordPatientData, 'PNG', 250,100, this.width-200,this.height,'', ("SLOW") as any);
            this.docReport.addImage(this.wordSomnicsData, 'PNG', 750,250, 200,30,'', ("SLOW") as any);
            this.docReport.addImage(this.wordOverviewData, 'PNG', 100,300, this.width,this.height,'', ("SLOW") as any);
            this.docReport.addImage(this.wordPatientDetailData, 'PNG', 100,400, this.width,this.height,'', ("SLOW") as any);
            this.docReport.addImage(this.wordTherapyData, 'PNG', 100,600, this.width,this.height,'', ("SLOW") as any);
            this.docReport.addImage(this.imgPieData, 'PNG', 100,700, this.width,this.height_pie,'', ("SLOW") as any);
            this.docReport.addImage(this.imgCompliancePieData, 'PNG', 100,900, this.width,this.height_pie,'', ("SLOW") as any);
            this.docReport.addImage(this.wordReportData, 'PNG', 100,1100, this.width,this.height,'', ("SLOW") as any);
            //this.docReport.addImage(this.imgUsageData, 'PNG', 100,1500, this.width,this.height_pie,'', ("SLOW") as any);
            this.docReport.addImage(this.imgUsageComplianceData, 'PNG', 100,1500, this.width,this.height_pie,'', ("SLOW") as any);
            this.docReport.addImage(this.imgODIData, 'PNG', 100,1800, this.width,this.height_pie,'', ("SLOW") as any);
            //this.docReport.addImage(this.imgTreatData, 'PNG', 100,2400, this.width,this.height_pie,'', ("SLOW") as any);
            this.docReport.addImage(this.imgTreatComplianceData, 'PNG', 100,2100, this.width,this.height_pie,'', ("SLOW") as any);
        }

        this.docReport.save(this.pdfReportName);
    }
    //分頁
    saveReportPDF(): void {
        var pdf = new jsPDF('p', 'pt', [1024,1550]);
        if(this.chart_detail == 1){
            pdf.addImage(this.wordSomnicsLogoData, 'PNG', 100,100, 150,150,'', ("SLOW") as any);
            pdf.addImage(this.wordPatientData, 'PNG', 250,100, this.width-200,this.height,'', ("SLOW") as any);
            pdf.addImage(this.wordSomnicsData, 'PNG', 750,250, 200,30,'', ("SLOW") as any);
            pdf.addImage(this.wordOverviewData, 'PNG', 100,300, this.width,this.height,'', ("SLOW") as any);
            pdf.addImage(this.wordPatientDetailData, 'PNG', 100,400, this.width,this.height,'', ("SLOW") as any);
            pdf.addImage(this.wordTherapyData, 'PNG', 100,600, this.width,this.height,'', ("SLOW") as any);
            pdf.addImage(this.imgPieData, 'PNG', 100,700, this.width,this.height_pie,'', ("SLOW") as any);
            pdf.addImage(this.imgCompliancePieData, 'PNG', 100,900, this.width,this.height_pie,'', ("SLOW") as any);
            pdf.addImage(this.wordReportData, 'PNG', 100,1100, this.width,this.height,'', ("SLOW") as any);
            pdf.addPage();
            pdf.addImage(this.imgUsageData, 'PNG', 100,100, this.width,this.height_pie,'', ("SLOW") as any);
            pdf.addImage(this.imgUsageComplianceData, 'PNG', 100,400, this.width,this.height_pie,'', ("SLOW") as any);
            pdf.addImage(this.imgODIData, 'PNG', 100,700, this.width,this.height_pie,'', ("SLOW") as any);
            pdf.addImage(this.imgTreatData, 'PNG', 100,1000, this.width,this.height_pie,'', ("SLOW") as any);
            pdf.addImage(this.imgTreatComplianceData, 'PNG', 100,1300, this.width,this.height_pie,'', ("SLOW") as any);
        }
        else{
            pdf.addImage(this.wordSomnicsLogoData, 'PNG', 100,100, 150,150,'', ("SLOW") as any);
            pdf.addImage(this.wordPatientData, 'PNG', 250,100, this.width-200,this.height,'', ("SLOW") as any);
            pdf.addImage(this.wordSomnicsData, 'PNG', 750,250, 200,30,'', ("SLOW") as any);
            pdf.addImage(this.wordOverviewData, 'PNG', 100,300, this.width,this.height,'', ("SLOW") as any);
            pdf.addImage(this.wordPatientDetailData, 'PNG', 100,400, this.width,this.height,'', ("SLOW") as any);
            pdf.addImage(this.wordTherapyData, 'PNG', 100,600, this.width,this.height,'', ("SLOW") as any);
            pdf.addImage(this.imgPieData, 'PNG', 100,700, this.width,this.height_pie,'', ("SLOW") as any);
            pdf.addImage(this.imgCompliancePieData, 'PNG', 100,900, this.width,this.height_pie,'', ("SLOW") as any);
            pdf.addImage(this.wordReportData, 'PNG', 100,1100, this.width,this.height,'', ("SLOW") as any);
            pdf.addPage();
            //pdf.addImage(this.imgUsageData, 'PNG', 100,100, this.width,this.height_pie,'', ("SLOW") as any);
            pdf.addImage(this.imgUsageComplianceData, 'PNG', 100,100, this.width,this.height_pie,'', ("SLOW") as any);
            pdf.addImage(this.imgODIData, 'PNG', 100,400, this.width,this.height_pie,'', ("SLOW") as any);
            //pdf.addImage(this.imgTreatData, 'PNG', 100,1000, this.width,this.height_pie,'', ("SLOW") as any);
            pdf.addImage(this.imgTreatComplianceData, 'PNG', 100,700, this.width,this.height_pie,'', ("SLOW") as any);
        }

        pdf.save(this.pdfReportName);
    }

    setup_downloadReportImage(): boolean {
        let isSetUp = false;
        if (this.input.downloadReportImage != null) {
            this.onViewClick_downloadReportImage();
            isSetUp = true;
        }
        return isSetUp;
    }
    onViewClick_downloadReportImage(): void {
        this.input.downloadReportImage.subscribe((reportData) => {
            //this.wordReportData = imgData;
            this.chart_detail = reportData.chart_detail;
            if(this.chart_detail == 0){
                this.totalReportImg = 5;
            }
            this.pdfReportName = reportData.pdfilename
            this.wordSomnicsLogoData = reportData.somnicsLogo
            this.wordPatientData = reportData.patient
            this.wordSomnicsData = reportData.somnics
            this.wordOverviewData = reportData.overview
            this.wordPatientDetailData = reportData.patientDetail
            this.wordTherapyData = reportData.therapy
            
            this.wordReportData = reportData.report

            this.countReportImg = 0;
            this.output.getReportPieImgData.next();
            this.output.getReportCompliancePieImgData.next();
            this.output.getReportUsageImgData.next();
            this.output.getReportUsageComplianceImgData.next();
            this.output.getReportODIImgData.next();
            this.output.getReportTreatImgData.next();
            this.output.getReportTreatComplianceImgData.next();
        
        });
    }

    setup_reportPieImage(): boolean {
        let isSetUp = false;
        if (this.input.reportPieImage != null) {
            this.onViewClick_reportPieImage();
            isSetUp = true;
        }
        return isSetUp;
    }
    onViewClick_reportPieImage(): void {
        this.input.reportPieImage.subscribe((imgData) => {
            this.countReportImg = this.countReportImg + 1;
            //this.docReport.addImage(imgData, 'PNG', 100,700, this.width,this.height_pie);
            this.imgPieData = imgData;
            if(this.countReportImg == this.totalReportImg ){
                this.saveReportPDF();
            }      
        });
    }

    setup_reportCompliancePieImage(): boolean {
        let isSetUp = false;
        if (this.input.reportCompliancePieImage != null) {
            this.onViewClick_reportCompliancePieImage();
            isSetUp = true;
        }
        return isSetUp;
    }
    onViewClick_reportCompliancePieImage(): void {
        this.input.reportCompliancePieImage.subscribe((imgData) => {
            this.countReportImg = this.countReportImg + 1;
            //this.docReport.addImage(imgData, 'PNG', 100,900, this.width,this.height_pie);
            this.imgCompliancePieData = imgData;
            if(this.countReportImg == this.totalReportImg ){
                this.saveReportPDF();
            }     
        });
    }

    setup_reportUsageImage(): boolean {
        let isSetUp = false;
        if (this.input.reportUsageImage != null) {
            this.onViewClick_reportUsageImage();
            isSetUp = true;
        }
        return isSetUp;
    }
    onViewClick_reportUsageImage(): void {
        this.input.reportUsageImage.subscribe((imgData) => {
            this.countReportImg = this.countReportImg + 1;
            //this.docReport.addImage(imgData, 'PNG', 100,900, this.width,this.height_pie);
            this.imgUsageData = imgData;
            if(this.countReportImg == this.totalReportImg ){
                this.saveReportPDF();
            }     
        });
    }


    setup_reportUsageComplianceImage(): boolean {
        let isSetUp = false;
        if (this.input.reportUsageComplianceImage != null) {
            this.onViewClick_reportUsageComplianceImage();
            isSetUp = true;
        }
        return isSetUp;
    }
    onViewClick_reportUsageComplianceImage(): void {
        this.input.reportUsageComplianceImage.subscribe((imgData) => {
            this.countReportImg = this.countReportImg + 1;
            //this.docReport.addImage(imgData, 'PNG', 100,1800, this.width,this.height_pie);
            this.imgUsageComplianceData = imgData;
            if(this.countReportImg == this.totalReportImg ){
                this.saveReportPDF();
            }     
        });
    }

    setup_reportODIImage(): boolean {
        let isSetUp = false;
        if (this.input.reportODIImage != null) {
            this.onViewClick_reportODIImage();
            isSetUp = true;
        }
        return isSetUp;
    }
    onViewClick_reportODIImage(): void {
        this.input.reportODIImage.subscribe((imgData) => {
            this.countReportImg = this.countReportImg + 1;
            //this.docReport.addImage(imgData, 'PNG', 100,2100, this.width,this.height_pie);
            this.imgODIData = imgData;
            if(this.countReportImg == this.totalReportImg ){
                this.saveReportPDF();
            }      
        });
    }

    setup_reportTreatImage(): boolean {
        let isSetUp = false;
        if (this.input.reportTreatImage != null) {
            this.onViewClick_reportTreatImage();
            isSetUp = true;
        }
        return isSetUp;
    }
    onViewClick_reportTreatImage(): void {
        this.input.reportTreatImage.subscribe((imgData) => {
            this.countReportImg = this.countReportImg + 1;
            //this.docReport.addImage(imgData, 'PNG', 100,2400, this.width,this.height_pie);
            this.imgTreatData = imgData;
            if(this.countReportImg == this.totalReportImg ){
                this.saveReportPDF();
            }     
        });
    }

    setup_reportTreatComplianceImage(): boolean {
        let isSetUp = false;
        if (this.input.reportTreatComplianceImage != null) {
            this.onViewClick_reportTreatComplianceImage();
            isSetUp = true;
        }
        return isSetUp;
    }
    onViewClick_reportTreatComplianceImage(): void {
        this.input.reportTreatComplianceImage.subscribe((imgData) => {
            this.countReportImg = this.countReportImg + 1;
            //this.docReport.addImage(imgData, 'PNG', 100,2700, this.width,this.height_pie);
            this.imgTreatComplianceData = imgData;
            if(this.countReportImg == this.totalReportImg ){
                this.saveReportPDF();
            }     
        });
    }

    setup_choose(): boolean {
        let isSetUp = false;
        if (this.input.chooseType != null) {
            this.onViewClick_choose();
            isSetUp = true;
        }
        return isSetUp;
    }

    onViewClick_choose(): void {
        this.input.chooseType.subscribe((patient) => {
            this.patientService
            .getTrenddata(patient)
            .subscribe((trendDataList) => {
                this.output.typeTrend.next(trendDataList);
            });
        });
        this.input.chooseDate.subscribe((patient) => {
            this.patientService
            .getClouddata(patient)
            .subscribe((data) => {
                this.output.dateTrend.next(data);
            });
        });
    }

    setup_devicesetting(): boolean {
        let isSetUp = false;
        if (this.input.editDeviceSetting != null) {
            this.onViewClick_devicesetting();
            isSetUp = true;
        }
        return isSetUp;
    }

    onViewClick_devicesetting(): void {
        this.input.editDeviceSetting.subscribe((devicesetting) => {
            console.log(devicesetting);
            this.patientService
                .editDeviceSetting(devicesetting)
                .subscribe((result) => {
                    this.output.devicesetting.next(result);
                    if(result == true){
                        this.router.navigate(['patientlist/patientdetail/devicesetting']);
                    }
                });
        });
    }

    setup_remotetherapy(): boolean {
        let isSetUp = false;
        if (this.input.editremotetherapy != null) {
            this.onViewClick_remotetherapy();
            isSetUp = true;
        }
        return isSetUp;
    }
    onViewClick_remotetherapy(): void {
        this.input.editremotetherapy.subscribe((devicesetting) => {
            console.log(devicesetting);
            this.patientService
                .editDeviceSetting(devicesetting)
                .subscribe((result) => {
                    this.output.remotetherapy.next(result);
                    if(result == true){
                        this.router.navigate(['patientlist/patientdetail/remotetherapy']);
                    }
                });
        });
    }

    setup_memonote(): boolean {
        let isSetUp = false;
        if (this.input.editmemonote != null) {
            this.onViewClick_memonote();
            isSetUp = true;
        }
        return isSetUp;
    }
    onViewClick_memonote(): void {
        this.input.editmemonote.subscribe((data) => {
            this.patientService
                .editMemonote(data)
                .subscribe((result) => {
                    this.output.memonote.next(result);
                    if(result == true){
                        this.router.navigate(['patientlist/patientdetail/chart']);
                    }
                });
        });
    }

    setup_tongue(): boolean {
        let isSetUp = false;
        if (this.input.chooseTongueDate != null) {
            this.onViewClick_tongue();
            isSetUp = true;
        }
        return isSetUp;
    }

    onViewClick_tongue(): void {
        this.input.chooseTongueDate.subscribe((patient) => {
            this.patientService
            .getTongue(patient)
            .subscribe((tongue) => {
                console.log(tongue);
                patient.tongue = tongue;
                this.output.dateTongue.next(patient);
            });
        });
        this.input.chooseTongueTime.subscribe((patient) => {
            this.patientService
            .getClouddata(patient)
            .subscribe((data) => {
                patient.data = data;
                this.output.timeTongue.next(patient);
            });
        });
    }
}
