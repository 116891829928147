import { Injectable, InjectionToken, Inject, Injector } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user-service';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root',
})
export class LoginViewModel {
    userService: UserService = this.injector.get(UserService);
    loginUser:User;
    router: Router;
    constructor(private injector: Injector) {
        this.router = this.injector.get(Router);
    }

    public input = new class {
        public login: Observable<any>;
        public forgotpassword: Observable<any>;
    };

    public output = new class {
        public loginFail = new Subject< Boolean >();
        public userSubject = new Subject< User| any >();
    };

    setup(): boolean {
        let isSetUp = false;
        if (this.input.login != null) {
            this.onViewClick();
            isSetUp = true;
        }
        return isSetUp;
    }

    onViewClick(): void {
        this.input.login.subscribe((user) => {
            console.log(user);
            this.userService
                .login(user)
                .subscribe((result) => {
                    if (result === false) {
                        this.output.loginFail.next(true);
                        //this.router.navigate(['login']);
                    }
                    else{
                        console.log(result);
                        this.loginUser = new User(result);
                        this.output.userSubject.next(this.loginUser);
                        this.router.navigate(['health/inaphome/']);
                    }
                });
        });
        if(this.input.forgotpassword){ //20240119 Angular紅字訊息除錯 
            this.input.forgotpassword.subscribe((user) => {
                console.log(user);
                this.userService
                .forgotpassword(user)
                .subscribe((result) => {
                    console.log(result);
                    this.output.userSubject.next(result);
                    this.router.navigate(['login']);
                });
            });
        }
    }



}
