import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';


@JsonObject('MaxPressureStrength')
export class MaxPressureStrength {

    @JsonProperty('time_flag', String)
    private _time_flag: string;
    @JsonProperty('first_max_pressure', Number)
    private _first_max_pressure: number;
    @JsonProperty('second_max_pressure', Number)
    private _second_max_pressure: number;
    @JsonProperty('third_max_pressure', Number)
    private _third_max_pressure: number;

    @prop()
    get time_flag(): string {
        return this._time_flag;
    }
    set time_flag(time_flag: string) {
        this._time_flag = time_flag;
    }

    @prop()
    get first_max_pressure(): number {
        return this._first_max_pressure;
    }
    set first_max_pressure(first_max_pressure: number) {
        this._first_max_pressure = first_max_pressure;
    }

    @prop()
    get second_max_pressure(): number {
        return this._second_max_pressure;
    }
    set second_max_pressure(second_max_pressure: number) {
        this._second_max_pressure = second_max_pressure;
    }

    @prop()
    get third_max_pressure(): number {
        return this._third_max_pressure;
    }
    set third_max_pressure(third_max_pressure: number) {
        this._third_max_pressure = third_max_pressure;
    }
}
