import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('ChartListLength')
export class ChartListLength extends Base{

    @JsonProperty('treatment', Number)
    private _treatment: number;
    @JsonProperty('compliance', Number)
    private _compliance: number;
    @JsonProperty('odDuration', Number)
    private _odDuration: number;
    @JsonProperty('odDurationCount', Number)
    private _odDurationCount: number;
    @JsonProperty('odi', Number)
    private _odi: number;
    @JsonProperty('pumping', Number)
    private _pumping: number;
    @JsonProperty('pressure', Number)
    private _pressure: number;
    @JsonProperty('spo2', Number)
    private _spo2: number;
    @JsonProperty('heartrate', Number)
    private _heartrate: number;
    @JsonProperty('fitbitHeart', Number)
    private _fitbitHeart: number;
    @JsonProperty('fitbitSleep', Number)
    private _fitbitSleep: number;
    @JsonProperty('ring_sleep_stage', Number)
    private _ring_sleep_stage: number;
    


    @prop()
    get treatment(): number {
        return this._treatment;
    }
    set treatment(treatment: number) {
        this._treatment = treatment;
    }

    @prop()
    get compliance(): number {
        return this._compliance;
    }
    set compliance(compliance: number) {
        this._compliance = compliance;
    }

    @prop()
    get odDuration(): number {
        return this._odDuration;
    }
    set odDuration(odDuration: number) {
        this._odDuration = odDuration;
    }

    @prop()
    get odDurationCount(): number {
        return this._odDurationCount;
    }
    set odDurationCount(odDurationCount: number) {
        this._odDurationCount = odDurationCount;
    }
    @prop()
    get odi(): number {
        return this._odi;
    }
    set odi(odi: number) {
        this._odi = odi;
    }

    @prop()
    get pumping(): number {
        return this._pumping;
    }
    set pumping(pumping: number) {
        this._pumping = pumping;
    }

    @prop()
    get pressure(): number {
        return this._pressure;
    }
    set pressure(pressure: number) {
        this._pressure = pressure;
    }

    @prop()
    get spo2(): number {
        return this._spo2;
    }
    set spo2(spo2: number) {
        this._spo2 = spo2;
    }
    @prop()
    get heartrate(): number {
        return this._heartrate;
    }
    set heartrate(heartrate: number) {
        this._heartrate = heartrate;
    }

    @prop()
    get fitbitHeart(): number {
        return this._fitbitHeart;
    }
    set fitbitHeart(fitbitHeart: number) {
        this._fitbitHeart = fitbitHeart;
    }

    @prop()
    get fitbitSleep(): number {
        return this._fitbitSleep;
    }
    set fitbitSleep(fitbitSleep: number) {
        this._fitbitSleep = fitbitSleep;
    }

    @prop()
    get ring_sleep_stage(): number {
        return this._ring_sleep_stage;
    }
    set ring_sleep_stage(ring_sleep_stage: number) {
        this._ring_sleep_stage = ring_sleep_stage;
    }

}
