export const environment = {
  production: true,
  environmentName: 'china_production',
  serverURL:'https://www.somnics.online/web/webapi/',
  mqtt: {
		server: 'www.somnics.online',
		protocol: "wss",
		port: 443
	},
  china_license:true,
  sdk_email:"sdk@sdk.sdk", //20240506 程式碼優化
  sdk_password:"8d2c9a59750ce59b308226f79c6f9bb3" //20240506 程式碼優化
};
