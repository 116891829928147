import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';

import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('Organization')
export class Organization extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('name', String)
    private _name: string;
    @JsonProperty('description', String)
    private _description: string;
    @JsonProperty('contact1', String)
    private _contact1: string;
    @JsonProperty('contact2', String)
    private _contact2: string;
    @JsonProperty('address', String)
    private _address: string;
    @JsonProperty('phone', String)
    private _phone: string;
    @JsonProperty('email', String)
    private _email: string;
    @JsonProperty('password', String)
    private _password: string;
    @JsonProperty('manuallypassword', String)
    private _manuallypassword: string;

    @prop()
    get id() {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }
    
    @prop()
    get name(): string {
        return this._name;
    }

    set name(name: string) {
        this._name = name;
    }

    @prop()
    get description(): string {
        return this._description;
    }

    set description(description: string) {
        this._description = description;
    }

   
    @prop()
    get contact1(): string {
        return this._contact1;
    }

    set contact1(contact1: string) {
        this._contact1 = contact1;
    }

    @prop()
    get contact2(): string {
        return this._contact2;
    }

    set contact2(contact2: string) {
        this._contact2 = contact2;
    }

    @prop()
    get address(): string {
        return this._address;
    }

    set address(address: string) {
        this._address = address;
    }

    @prop()
    get phone(): string {
        return this._phone;
    }

    set phone(phone: string) {
        this._phone = phone;
    }
    @prop()
    @email()
    get email(): string {
        return this._email;
    }

    set email(email: string) {
        this._email = email;
    }
    @prop()
    get password(): string {
        return this._password;
    }
    set password(password: string) {
        this._password = password;
    }
    @prop()
    @password({validation:{minLength: 6} })
    get manuallypassword(): string {
        return this._manuallypassword;
    }
    set manuallypassword(manuallypassword: string) {
        this._manuallypassword = manuallypassword;
    }
}