
import { Injectable, InjectionToken, Inject, Injector} from '@angular/core';
import { PatientDao } from 'src/app/dao/patient-dao';
import { GlobalDeviceDao } from 'src/app/dao/global-device-dao';

import { UserDao } from 'src/app/dao/user-dao';
import { User } from 'src/app/model/user';
import { UserWebResponse } from 'src/app/model/user-web-response';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { TableData } from 'src/app/routes/userlist/ng2-table-data';
import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';
import { Patient } from 'src/app/model/patient';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { DeviceSetting } from 'src/app/model/device-setting';
import { Data } from 'src/app/model/data';
import { Tongue } from 'src/app/model/tongue';
import { Report } from 'src/app/model/report';
import { Router } from '@angular/router';
import { OtaUpload } from 'src/app/model/ota-upload';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const apiUrl = 'https://jsonplaceholder.typicode.com/posts/42';

@Injectable({
    providedIn: 'root'
})
export class GlobalDeviceDaoImpl implements GlobalDeviceDao {
    loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
    serverURL:string;
    router: Router;
    constructor(public settings: SettingsService,private injector: Injector,private http:HttpClient) {
        this.serverURL = this.settings.getAppSetting('serverURL');
        this.router = this.injector.get(Router);
    }

    getGlobalDeviceList(): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let url = this.serverURL+'globaldevicelist';
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.data;
            }),
            catchError(this.handleError<string>('getGlobalDeviceList'))
        );
    }

    getOtaList(): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let url = this.serverURL+'otalist';
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.data;
            }),
            catchError(this.handleError<string>('getOtaList'))
        );
    }

    getApkList(): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let url = this.serverURL+'apklist';
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.data;
            }),
            catchError(this.handleError<string>('getApkList'))
        );
    }

    getOtaById(ota_upload_id): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'ota?ota_upload_id='+ota_upload_id;
        return this.http.get<OtaUpload>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                console.log(userWebResponse.data);
                return new OtaUpload(userWebResponse.data);
            }),
            catchError(this.handleError<string>('getOtaById'))
        );
    }

    addOta(otaUpload): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': bearer_token })
        };
        const url = this.serverURL+'ota';
        let formData = new FormData();
        //formData.append("file", file);
        formData.append("file", otaUpload.file);
        formData.append("version", otaUpload.version);
        return this.http.post<boolean>(url, formData, httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.success;
            }),
            catchError(this.handleError<string>('addOta'))
        );
    }

    addApk(apkUpload): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': bearer_token })
        };
        const url = this.serverURL+'apk';
        let formData = new FormData();
        //formData.append("file", file);
        formData.append("file", apkUpload.file);
        formData.append("version", apkUpload.version);
        return this.http.post<boolean>(url, formData, httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.success;
            }),
            catchError(this.handleError<string>('addApk'))
        );
    }

    getImportPatientList(): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let url = this.serverURL+'importpatientlist';
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.data;
            }),
            catchError(this.handleError<string>('getImportPatientList'))
        );
    }

    getAllUserPatientList(): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let url = this.serverURL+'alluserlist';
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.data;
            }),
            catchError(this.handleError<string>('getAllUserPatientList'))
        );
    }

    getVaUserPatientList(): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let url = this.serverURL+'vauserlist';
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.data;
            }),
            catchError(this.handleError<string>('getAllUserPatientList'))
        );
    }
   

    importGlobalDevice(file): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': bearer_token })
        };
        const url = this.serverURL+'importglobaldevice';
        let formData = new FormData();
        formData.append("file", file);
        return this.http.post<boolean>(url, formData, httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.success;
            }),
            catchError(this.handleError<string>('importGlobalDevice'))
        );
    }

    importImportPatient(file): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': bearer_token })
        };
        const url = this.serverURL+'importpatient';
        let formData = new FormData();
        formData.append("file", file);
        return this.http.post<boolean>(url, formData, httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.success;
            }),
            catchError(this.handleError<string>('importImportPatient'))
        );
    }


    getCloudDeviceHistoryList(): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let url = this.serverURL+'clouddevicehistorylist';
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.data;
            }),
            catchError(this.handleError<string>('getCloudDeviceHistoryList'))
        );
    }

    getDisablePressureSerialList(): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let url = this.serverURL+'disablepressureseriallist';
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.data;
            }),
            catchError(this.handleError<string>('getDisablePressureSerialList'))
        );
    }
    
    addGlobalDevice(device): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'globaldevice';
        return this.http.post<boolean>(url, device.toJsonString(), httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.success;
            }),
            catchError(this.handleError<string>('addGlobalDevice'))
        );
    }

    deleteGlobalDevice(devicelist): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'globaldevicelistdelete';
        return this.http.put<boolean>(url, devicelist.toJsonString(), httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse;
            }),
            catchError(this.handleError<string>('deleteGlobalDevice'))
        );
    }

    addCloudDeviceHistory(device): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'clouddevicehistory';
        return this.http.post<boolean>(url, device.toJsonString(), httpOptions).pipe(
            map(response => {
                return new UserWebResponse(response);
            }),
            catchError(this.handleError<string>('addCloudDeviceHistory'))
        );
    }

    addDisablePressure(device): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'disablepressureserial';
        return this.http.post<boolean>(url, device.toJsonString(), httpOptions).pipe(
            map(response => {
                return new UserWebResponse(response);
            }),
            catchError(this.handleError<string>('addDisablePressure'))
        );
    }

    editCloudDeviceHistory(device): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'clouddevicehistory';
        return this.http.put<boolean>(url, device.toJsonString(), httpOptions).pipe(
            map(response => {
                return new UserWebResponse(response);
            }),
            catchError(this.handleError<string>('editCloudDeviceHistory'))
        );
    }


    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          localStorage.clear();//發生錯誤,清空localStorage
          this.router.navigate(['login']);//發生錯誤,導向404頁
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
      
          // TODO: better job of transforming error for user consumption
          console.log(`${operation} failed: ${error.message}`);
      
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }

}

// export const UserServiceProvider = new InjectionToken(
//     'UserServiceProvider',
//     { providedIn: 'root', factory: () => new UserServiceImpl(@Inject(ENV) private environment) }
// );
