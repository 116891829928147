import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import { Token } from 'src/app/model/token';
import { Location } from 'src/app/model/location';
import { UserIdentification } from 'src/app/model/user-identification';
import { ContactDetail } from 'src/app/model/contact-detail';
import { ClinicalUser } from 'src/app/model/clinical-user';
import { WebDevice } from 'src/app/model/web-device';
import { ContactDetailPatient } from 'src/app/model/contact-detail-patient';
import { PatientAddress } from 'src/app/model/patient-address';
import { PatientDetail } from 'src/app/model/patient-detail';
import { Data } from 'src/app/model/data';
import {JsonObject, JsonProperty, JsonConvert, Any} from 'json2typescript';
import { DeviceSetting } from 'src/app/model/device-setting';
import { Tongue } from 'src/app/model/tongue';
import { TongueDetail } from 'src/app/model/tongue-detail';
import { Report } from 'src/app/model/report';
import { GlobalDeviceMultiPatient } from 'src/app/model/global-device-multi-patient';
import { Ecg } from 'src/app/model/ecg';

@JsonObject('Patient')
export class Patient extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('deviceSetting', DeviceSetting)
    private _deviceSetting: DeviceSetting;
    @JsonProperty('ecgList', [Ecg])
    private _ecgList: Array<Ecg>;
    @JsonProperty('ecg', Ecg)
    private _ecg: Ecg;
    @JsonProperty('user_id', Number)
    private _user_id: number;
    @JsonProperty('weight', Number)
    private _weight: number;
    @JsonProperty('height', Number)
    private _height: number;
    @JsonProperty('isAdd', String)
    private _isAdd: string;
    @JsonProperty('username', String)
    private _username: string;
    @JsonProperty('firstname', String)
    private _firstname: string;
    @JsonProperty('lastname', String)
    private _lastname: string;
    @JsonProperty('title', String)
    private _title: string;
    @JsonProperty('birthday', String)
    private _birthday: string;
    @JsonProperty('byear', Number)
    private _byear: number;
    @JsonProperty('byearList', [Number])
    private _byearList: Array<number>;
    @JsonProperty('bmonth', Number)
    private _bmonth: number;
    @JsonProperty('bmonthList', [Number])
    private _bmonthList: Array<number>;
    @JsonProperty('bday', Number)
    private _bday: number;
    @JsonProperty('bdayList', [Number])
    private _bdayList: Array<number>;
    @JsonProperty('location_id', Number)
    private _location_id: number;
    @JsonProperty('create_user_id', Number)
    private _create_user_id: number;
    @JsonProperty('organization_id', Number)
    private _organization_id: number;
    @JsonProperty('organization_name', String)
    private _organization_name: string;
    @JsonProperty('location_name', String)
    private _location_name: string;
    @JsonProperty('use_last_per', Number)
    private _use_last_per: number;
    @JsonProperty('last_upload', String)
    private _last_upload: string;
    @JsonProperty('medical_number', String)
    private _medical_number: string;
    @JsonProperty('email', String)
    private _email: string;
    @JsonProperty('serial', String)
    private _serial: string;
    @JsonProperty('datetime_type', String)
    private _datetime_type: string;
    @JsonProperty('clinicalUserName', String)
    private _clinicalUserName: string;
    @JsonProperty('cloud_user_id', Number)
    private _cloud_user_id: number;
    @JsonProperty('link_result', String)
    private _link_result: string;
    @JsonProperty('enable', Number)
    private _enable: number;
    @JsonProperty('createdate', String)
    private _createdate: string;
    @JsonProperty('availablelocation', [Location])
    private _availablelocation: Array<Location>;
    @JsonProperty('clinicalUserList', [ClinicalUser])
    private _clinicalUserList: Array<ClinicalUser>;
    @JsonProperty('dataList', [Data])
    private _dataList: Array<Data>;
    @JsonProperty('chartDataList', [Data])
    private _chartDataList: Array<Data>;
    @JsonProperty('trendDataList', [Data])
    private _trendDataList: Array<Data>;
    @JsonProperty('reportDataList', [Data])
    private _reportDataList: Array<Data>;
    @JsonProperty('data', Data)
    private _data: Data;

    @JsonProperty('chartPicker', Any)
    private _chartPicker: any;

    @JsonProperty('fromPicker', Any)
    private _fromPicker: any;
    @JsonProperty('toPicker', Any)
    private _toPicker: any;
    @JsonProperty('from_date', String)
    private _from_date: string;
    @JsonProperty('to_date', String)
    private _to_date: string;

    @JsonProperty('language', String)
    private _language: string;
    @JsonProperty('unit', String)
    private _unit: string;

    @JsonProperty('report', Report)
    private _report: Report;
    @JsonProperty('tongue', Tongue)
    private _tongue: Tongue;
    @JsonProperty('tongueDetail', TongueDetail)
    private _tongueDetail: TongueDetail;
    @JsonProperty('tongueDateList', [String])
    private _tongueDateList: Array<string>;
    @JsonProperty('tongueDate', String)
    private _tongueDate: string;
    @JsonProperty('ecgDateList', [String])
    private _ecgDateList: Array<string>;
    @JsonProperty('ecgDate', String)
    private _ecgDate: string;
    @JsonProperty('webDeviceList', [WebDevice])
    private _webDeviceList: Array<WebDevice>;
    @JsonProperty('webDevice', WebDevice)
    private _webDevice: WebDevice;
    @JsonProperty('webDeviceType', String)
    private _webDeviceType: string;
    @JsonProperty('webDeviceSerial', String)
    private _webDeviceSerial: string;
    @JsonProperty('contactDetailPatientList', [ContactDetailPatient])
    private _contactDetailPatientList: Array<ContactDetailPatient>;
    @JsonProperty('contactDetailPatient', ContactDetailPatient)
    private _contactDetailPatient: ContactDetailPatient;
    @JsonProperty('contactDetailPatientName', String)
    private _contactDetailPatientName: string;
    @JsonProperty('contactDetailPatientDescription', String)
    private _contactDetailPatientDescription: string;
    @JsonProperty('patientAddressList', [PatientAddress])
    private _patientAddressList: Array<PatientAddress>;
    @JsonProperty('patientAddress', PatientAddress)
    private _patientAddress: PatientAddress;
    @JsonProperty('patientAddressName', String)
    private _patientAddressName: string;
    @JsonProperty('patientAddressDescription', String)
    private _patientAddressDescription: string;
    @JsonProperty('patientDetailList', [PatientDetail])
    private _patientDetailList: Array<PatientDetail>;
    @JsonProperty('patientDetail', PatientDetail)
    private _patientDetail: PatientDetail;

    @JsonProperty('globalDeviceMultiPatientList', [GlobalDeviceMultiPatient])
    private _globalDeviceMultiPatientList: Array<GlobalDeviceMultiPatient>;
    @JsonProperty('globalDeviceMultiPatient', GlobalDeviceMultiPatient)
    private _globalDeviceMultiPatient: GlobalDeviceMultiPatient;

    @JsonProperty('patientDetailName', String)
    private _patientDetailName: string;
    @JsonProperty('patientDetailDescription', String)
    private _patientDetailDescription: string;


    @prop()
    get id() {
        return this._id;
    }
    set id(id: number) {
        this._id = id;
    }
    @prop()
    get deviceSetting(): DeviceSetting {
        return this._deviceSetting;
    }
    set deviceSetting(deviceSetting: DeviceSetting) {
        this._deviceSetting = deviceSetting;
    }

    @prop()
    get ecgList(): Array<Ecg> {
        return this._ecgList;
    }
    set ecgList(ecgList: Array<Ecg>) {
        this._ecgList = ecgList;
    }

    @prop()
    get ecg(): Ecg {
        return this._ecg;
    }
    set ecg(ecg: Ecg) {
        this._ecg = ecg;
    }
    @prop()
    get user_id(): number {
        return this._user_id;
    }
    set user_id(user_id: number) {
        this._user_id = user_id;
    }

    @prop()
    get weight(): number {
        return this._weight;
    }
    set weight(weight: number) {
        this._weight = weight;
    }

    @prop()
    get height(): number {
        return this._height;
    }
    set height(height: number) {
        this._height = height;
    }
    @prop()
    get isAdd(): string {
        return this._isAdd;
    }
    set isAdd(isAdd: string) {
        this._isAdd = isAdd;
    }
    @prop()
    get username(): string {
        return this._username;
    }
    set username(username: string) {
        this._username = username;
    }
    @prop()
    get firstname(): string {
        return this._firstname;
    }
    set firstname(firstname: string) {
        this._firstname = firstname;
    }
    @prop()
    get lastname(): string {
        return this._lastname;
    }
    set lastname(lastname: string) {
        this._lastname = lastname;
    }
    @prop()
    get title(): string {
        return this._title;
    }
    set title(title: string) {
        this._title = title;
    }
    @prop()
    get birthday(): string {
        return this._birthday;
    }
    set birthday(birthday: string) {
        this._birthday = birthday;
    }

    @prop()
    get byear(): number {
        return this._byear;
    }
    set byear(byear: number) {
        this._byear = byear;
    }

    @prop()
    get byearList(): Array<number> {
        return this._byearList;
    }

    set byearList(byearList: Array<number>) {
        this._byearList = byearList;
    }

    @prop()
    get bmonth(): number {
        return this._bmonth;
    }
    set bmonth(bmonth: number) {
        this._bmonth = bmonth;
    }

    @prop()
    get bmonthList(): Array<number> {
        return this._bmonthList;
    }

    set bmonthList(bmonthList: Array<number>) {
        this._bmonthList = bmonthList;
    }

    @prop()
    get bday(): number {
        return this._bday;
    }
    set bday(bday: number) {
        this._bday = bday;
    }

    @prop()
    get bdayList(): Array<number> {
        return this._bdayList;
    }

    set bdayList(bdayList: Array<number>) {
        this._bdayList = bdayList;
    }
    
    @prop()
    get location_id(): number {
        return this._location_id;
    }
    set location_id(location_id: number) {
        this._location_id = location_id;
    }
    @prop()
    get create_user_id(): number {
        return this._create_user_id;
    }
    set create_user_id(create_user_id: number) {
        this._create_user_id = create_user_id;
    }

    @prop()
    get organization_id(): number {
        return this._organization_id;
    }
    set organization_id(organization_id: number) {
        this._organization_id = organization_id;
    }
    @prop()
    get organization_name(): string {
        return this._organization_name;
    }
    set organization_name(organization_name: string) {
        this._organization_name = organization_name;
    }

    @prop()
    get location_name(): string {
        return this._location_name;
    }
    set location_name(location_name: string) {
        this._location_name = location_name;
    }

    @prop()
    get use_last_per(): number {
        return this._use_last_per;
    }
    set use_last_per(use_last_per: number) {
        this._use_last_per = use_last_per;
    }

    @prop()
    get last_upload(): string {
        return this._last_upload;
    }
    set last_upload(last_upload: string) {
        this._last_upload = last_upload;
    }

    @prop()
    get medical_number(): string {
        return this._medical_number;
    }
    set medical_number(medical_number: string) {
        this._medical_number = medical_number;
    }

    @prop()
    get email(): string {
        return this._email;
    }
    set email(email: string) {
        this._email = email;
    }

    @prop()
    get serial(): string {
        return this._serial;
    }
    set serial(serial: string) {
        this._serial = serial;
    }

    @prop()
    get datetime_type(): string {
        return this._datetime_type;
    }
    set datetime_type(datetime_type: string) {
        this._datetime_type = datetime_type;
    }

    @prop()
    get clinicalUserName(): string {
        return this._clinicalUserName;
    }
    set clinicalUserName(clinicalUserName: string) {
        this._clinicalUserName = clinicalUserName;
    }

    @prop()
    get cloud_user_id(): number {
        return this._cloud_user_id;
    }
    set cloud_user_id(cloud_user_id: number) {
        this._cloud_user_id = cloud_user_id;
    }

    @prop()
    get link_result(): string {
        return this._link_result;
    }
    set link_result(link_result: string) {
        this._link_result = link_result;
    }

    @prop()
    get enable(): number {
        return this._enable;
    }
    set enable(enable: number) {
        this._enable = enable;
    }

    @prop()
    get createdate(): string {
        return this._createdate;
    }
    set createdate(createdate: string) {
        this._createdate = createdate;
    }
    @prop()
    get availablelocation(): Array<Location> {
        return this._availablelocation;
    }

    set availablelocation(availablelocation: Array<Location>) {
        this._availablelocation = availablelocation;
    }

    @prop()
    get clinicalUserList(): Array<ClinicalUser> {
        return this._clinicalUserList;
    }

    set clinicalUserList(clinicalUserList: Array<ClinicalUser>) {
        this._clinicalUserList = clinicalUserList;
    }

    @prop()
    get dataList(): Array<Data> {
        return this._dataList;
    }
    set dataList(dataList: Array<Data>) {
        this._dataList = dataList;
    }

    @prop()
    get chartDataList(): Array<Data> {
        return this._chartDataList;
    }
    set chartDataList(chartDataList: Array<Data>) {
        this._chartDataList = chartDataList;
    }

    @prop()
    get trendDataList(): Array<Data> {
        return this._trendDataList;
    }
    set trendDataList(trendDataList: Array<Data>) {
        this._trendDataList = trendDataList;
    }

    @prop()
    get reportDataList(): Array<Data> {
        return this._reportDataList;
    }
    set reportDataList(reportDataList: Array<Data>) {
        this._reportDataList = reportDataList;
    }

    @prop()
    get data(): Data {
        return this._data;
    }
    set data(data: Data) {
        this._data = data;
    }

    @prop()
    get chartPicker(): any {
        return this._chartPicker;
    }
    set chartPicker(chartPicker: any) {
        this._chartPicker = chartPicker;
    }

    @prop()
    get fromPicker(): any {
        return this._fromPicker;
    }
    set fromPicker(fromPicker: any) {
        this._fromPicker = fromPicker;
    }

    @prop()
    get toPicker(): any {
        return this._toPicker;
    }
    set toPicker(toPicker: any) {
        this._toPicker = toPicker;
    }

    @prop()
    get from_date(): string {
        return this._from_date;
    }
    set from_date(from_date: string) {
        this._from_date = from_date;
    }

    @prop()
    get to_date(): string {
        return this._to_date;
    }
    set to_date(to_date: string) {
        this._to_date = to_date;
    }

    @prop()
    get language(): string {
        return this._language;
    }
    set language(language: string) {
        this._language = language;
    }

    @prop()
    get unit(): string {
        return this._unit;
    }
    set unit(unit: string) {
        this._unit = unit;
    }

    @prop()
    get report(): Report {
        return this._report;
    }

    set report(report: Report) {
        this._report = report;
    }

    @prop()
    get tongue(): Tongue {
        return this._tongue;
    }

    set tongue(tongue: Tongue) {
        this._tongue = tongue;
    }

    @prop()
    get tongueDetail(): TongueDetail {
        return this._tongueDetail;
    }

    set tongueDetail(tongueDetail: TongueDetail) {
        this._tongueDetail = tongueDetail;
    }

    @prop()
    get tongueDateList(): Array<string> {
        return this._tongueDateList;
    }
    set tongueDateList(tongueDateList: Array<string>) {
        this._tongueDateList = tongueDateList;
    }

    @prop()
    get tongueDate(): string {
        return this._tongueDate;
    }
    set tongueDate(tongueDate: string) {
        this._tongueDate = tongueDate;
    }

    @prop()
    get ecgDateList(): Array<string> {
        return this._ecgDateList;
    }
    set ecgDateList(ecgDateList: Array<string>) {
        this._ecgDateList = ecgDateList;
    }

    @prop()
    get ecgDate(): string {
        return this._ecgDate;
    }
    set ecgDate(ecgDate: string) {
        this._ecgDate = ecgDate;
    }

    @prop()
    get webDeviceList(): Array<WebDevice> {
        return this._webDeviceList;
    }
    set webDeviceList(webDeviceList: Array<WebDevice>) {
        this._webDeviceList = webDeviceList;
    }

    @prop()
    get webDevice(): WebDevice {
        return this._webDevice;
    }

    set webDevice(webDevice: WebDevice) {
        this._webDevice = webDevice;
    }

    @prop()
    get webDeviceType(): string {
        return this._webDeviceType;
    }

    set webDeviceType(webDeviceType: string) {
        this._webDeviceType = webDeviceType;
    }
    
    @prop()
    get webDeviceSerial(): string {
        return this._webDeviceSerial;
    }

    set webDeviceSerial(webDeviceSerial: string) {
        this._webDeviceSerial = webDeviceSerial;
    }


    @prop()
    get contactDetailPatientList(): Array<ContactDetailPatient> {
        return this._contactDetailPatientList;
    }

    set contactDetailPatientList(contactDetailPatientList: Array<ContactDetailPatient>) {
        this._contactDetailPatientList = contactDetailPatientList;
    }

    @prop()
    get contactDetailPatient(): ContactDetailPatient {
        return this._contactDetailPatient;
    }

    set contactDetailPatient(contactDetailPatient: ContactDetailPatient) {
        this._contactDetailPatient = contactDetailPatient;
    }

    @prop()
    get contactDetailPatientName(): string {
        return this._contactDetailPatientName;
    }

    set contactDetailPatientName(contactDetailPatientName: string) {
        this._contactDetailPatientName = contactDetailPatientName;
    }
    
    @prop()
    get contactDetailPatientDescription(): string {
        return this._contactDetailPatientDescription;
    }

    set contactDetailPatientDescription(contactDetailPatientDescription: string) {
        this._contactDetailPatientDescription = contactDetailPatientDescription;
    }

    @prop()
    get patientAddressList(): Array<PatientAddress> {
        return this._patientAddressList;
    }

    set patientAddressList(patientAddressList: Array<PatientAddress>) {
        this._patientAddressList = patientAddressList;
    }

    @prop()
    get patientAddress(): PatientAddress {
        return this._patientAddress;
    }

    set patientAddress(patientAddress: PatientAddress) {
        this._patientAddress = patientAddress;
    }

    @prop()
    get patientAddressName(): string {
        return this._patientAddressName;
    }

    set patientAddressName(patientAddressName: string) {
        this._patientAddressName = patientAddressName;
    }
    
    @prop()
    get patientAddressDescription(): string {
        return this._patientAddressDescription;
    }

    set patientAddressDescription(patientAddressDescription: string) {
        this._patientAddressDescription = patientAddressDescription;
    }

    @prop()
    get patientDetailList(): Array<PatientDetail> {
        return this._patientDetailList;
    }
    set patientDetailList(patientDetailList: Array<PatientDetail>) {
        this._patientDetailList = patientDetailList;
    }

    @prop()
    get patientDetail(): PatientDetail {
        return this._patientDetail;
    }
    set patientDetail(patientDetail: PatientDetail) {
        this._patientDetail = patientDetail;
    }


    @prop()
    get globalDeviceMultiPatientList(): Array<GlobalDeviceMultiPatient> {
        return this._globalDeviceMultiPatientList;
    }
    set globalDeviceMultiPatientList(globalDeviceMultiPatientList: Array<GlobalDeviceMultiPatient>) {
        this._globalDeviceMultiPatientList = globalDeviceMultiPatientList;
    }

    @prop()
    get globalDeviceMultiPatient(): GlobalDeviceMultiPatient {
        return this._globalDeviceMultiPatient;
    }
    set globalDeviceMultiPatient(globalDeviceMultiPatient: GlobalDeviceMultiPatient) {
        this._globalDeviceMultiPatient = globalDeviceMultiPatient;
    }

    @prop()
    get patientDetailName(): string {
        return this._patientDetailName;
    }

    set patientDetailName(patientDetailName: string) {
        this._patientDetailName = patientDetailName;
    }
    
    @prop()
    get patientDetailDescription(): string {
        return this._patientDetailDescription;
    }

    set patientDetailDescription(patientDetailDescription: string) {
        this._patientDetailDescription = patientDetailDescription;
    }
    
}
