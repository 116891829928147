import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Injector } from '@angular/core';
import { PatientService } from 'src/app/service/patient-service';
import * as CryptoJS from 'crypto-js';
import { Patient } from 'src/app/model/patient';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { TranslatorService } from 'src/app/core/translator/translator.service';//多國語言
import { UserService } from 'src/app/service/user-service';
import { User } from 'src/app/model/user';
import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';
import { Router } from '@angular/router';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-sdk',
  templateUrl: './sdk.component.html',
  styleUrls: ['./sdk.component.scss']
})
export class SdkComponent implements OnInit {
  private sub: any;
  valid:boolean;
  message:string;
  patientService: PatientService = this.injector.get(PatientService);
  userService: UserService = this.injector.get(UserService);
  userFormGroup: FormGroup;
  patient: Patient;
  public chartbtn:boolean;
  public trendbtn:boolean;
  public reportbtn:boolean;
  loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
  router: Router;
  constructor(public route: ActivatedRoute,public injector: Injector,private rxfb: RxFormBuilder, public translator: TranslatorService) { 
    this.translator.useLanguage('zh');//多國語言 'en'/'zh'
    this.router = this.injector.get(Router);
    this.sub = this.route.params.subscribe(params => {
      //讓sdk直接開網頁,不用再登入
      var user = new User();
      user.email = env.sdk_email //20240506 程式碼優化
      user.password = env.sdk_password //20240506 程式碼優化
      this.userService.login(user).subscribe((result) => {
        console.log(result);
        this.loginViewModel.loginUser = new User(result);
        console.log('=======>' + this.loginViewModel.loginUser.token.access_token);
        //判斷網址是否有效
        var url = params['url'];
        if (!Date.now) {
          Date.now = function now() {
            return new Date().getTime();
          };
        }
        var timestamp = Math.floor(Date.now() / 1000);
        console.log(timestamp);
        url = url +'?timestamp='+String(timestamp);
        console.log(url);
        
        this.patientService.getSDKPatientURL(url).subscribe((result) => {
          console.log(result._message);
          console.log(result._success);
          console.log(result._data);
          this.valid = result._success;
          if(this.valid == true){
            //var patient_id = 137;// ToDo 目前暫時先填上正式機與本機端相同的病人id,先取出資料顯示為主
            var patient_id = result._data;//實際的patient_id
            //取得病人
            this.patientService
            .getPatientById(patient_id)
            .subscribe((patient) => {
              try {
                var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(patient), 'somnicSecretKey').toString();
                localStorage.setItem('sdkPatient', ciphertext);
                this.patient = patient;
                this.userFormGroup = this.rxfb.formGroup(this.patient);
                this.chartbtn = true;
                //跳到預設頁
                var default_url = 'patient/'+params['url']+'/chart'
                console.log(default_url);
                this.router.navigate([default_url]);
              } catch (error) {
                console.error('Error saving to localStorage', error);
              }
            });
          }
          else{
            console.log(result._message);
            this.message = result._message;
          }
        });
      });
    });
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  clickChart(){
    this.chartbtn = true;
    this.trendbtn = false;
    this.reportbtn = false;
  }
  clickTrend(){
    this.chartbtn = false;
    this.trendbtn = true;
    this.reportbtn = false;
  }
  clickReport(){
    this.chartbtn = false;
    this.trendbtn = false;
    this.reportbtn = true;
  }
}
