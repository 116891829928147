import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('SealPercentDetail')
export class SealPercentDetail extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('datetime_time', Number)
    private _datetime_time: number;
    @JsonProperty('pressure_percent', Number)
    private _pressure_percent: number;
    @JsonProperty('treat', Number)
    private _treat: number;
    @JsonProperty('seal', Number)
    private _seal: number;
    @JsonProperty('odi', Number)
    private _odi: number;
    @JsonProperty('pumping_time', Number)
    private _pumping_time: number;

    @prop()
    get id() {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    @prop()
    get datetime_time(): number {
        return this._datetime_time;
    }

    set datetime_time(datetime_time: number) {
        this._datetime_time = datetime_time;
    }

    @prop()
    get pressure_percent(): number {
        return this._pressure_percent;
    }

    set pressure_percent(pressure_percent: number) {
        this._pressure_percent = pressure_percent;
    }
    @prop()
    get treat(): number {
        return this._treat;
    }

    set treat(treat: number) {
        this._treat = treat;
    }
    @prop()
    get seal(): number {
        return this._seal;
    }

    set seal(seal: number) {
        this._seal = seal;
    }

    @prop()
    get odi(): number {
        return this._odi;
    }

    set odi(odi: number) {
        this._odi = odi;
    }

    @prop()
    get pumping_time(): number {
        return this._pumping_time;
    }

    set pumping_time(pumping_time: number) {
        this._pumping_time = pumping_time;
    }

}
