import { prop} from "@rxweb/reactive-form-validators";

import { User } from 'src/app/model/user';
import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('UserWebResponse')
export class UserWebResponse extends Base{
    @JsonProperty('success', Boolean)
    private _success: boolean;
    @JsonProperty('message', String)
    private _message: string;
    @JsonProperty('data', User)
    private _data: User;

    @prop()
    get success(): boolean {
        return this._success;
    }

    set success(success: boolean) {
        this._success = success;
    }
    @prop()
    get message(): string {
        return this._message;
    }

    set message(message: string) {
        this._message = message;
    }
    @prop()
    get data(): User {
        return this._data;
    }

    set data(data: User) {
        this._data = data;
    }
}
