import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';
import { TimeForEndurance } from 'src/app/model/time-for-endurance';
import { MaxPressureStrength } from 'src/app/model/max-pressure-strength';
import { TongueDetail } from 'src/app/model/tongue-detail';



@JsonObject('Tongue')
export class Tongue extends Base{
    
    @JsonProperty('timeForEnduranceList', [TimeForEndurance])
    private _timeForEnduranceList: Array<TimeForEndurance>;
    @JsonProperty('timeForEndurance', TimeForEndurance)
    private _timeForEndurance: TimeForEndurance;
    @JsonProperty('maxPressureStrengthList', [MaxPressureStrength])
    private _maxPressureStrengthList: Array<MaxPressureStrength>;
    @JsonProperty('maxPressureStrength', MaxPressureStrength)
    private _maxPressureStrength: MaxPressureStrength;

    @JsonProperty('tongueDetailList', [TongueDetail])
    private _tongueDetailList: Array<TongueDetail>;
    @JsonProperty('tongueDetail', TongueDetail)
    private _tongueDetail: TongueDetail;


    @prop()
    get timeForEnduranceList(): Array<TimeForEndurance> {
        return this._timeForEnduranceList;
    }
    set timeForEnduranceList(timeForEnduranceList: Array<TimeForEndurance>) {
        this._timeForEnduranceList = timeForEnduranceList;
    }

    @prop()
    get timeForEndurance(): TimeForEndurance {
        return this._timeForEndurance;
    }
    set timeForEndurance(timeForEndurance: TimeForEndurance) {
        this._timeForEndurance = timeForEndurance;
    }


    @prop()
    get maxPressureStrengthList(): Array<MaxPressureStrength> {
        return this._maxPressureStrengthList;
    }
    set maxPressureStrengthList(maxPressureStrengthList: Array<MaxPressureStrength>) {
        this._maxPressureStrengthList = maxPressureStrengthList;
    }

    @prop()
    get maxPressureStrength(): MaxPressureStrength {
        return this._maxPressureStrength;
    }
    set maxPressureStrength(maxPressureStrength: MaxPressureStrength) {
        this._maxPressureStrength = maxPressureStrength;
    }

    @prop()
    get tongueDetailList(): Array<TongueDetail> {
        return this._tongueDetailList;
    }
    set tongueDetailList(tongueDetailList: Array<TongueDetail>) {
        this._tongueDetailList = tongueDetailList;
    }

    @prop()
    get tongueDetail(): TongueDetail {
        return this._tongueDetail;
    }
    set tongueDetail(tongueDetail: TongueDetail) {
        this._tongueDetail = tongueDetail;
    }

}
