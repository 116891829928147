import { Injectable, InjectionToken, Inject, Injector} from '@angular/core';
import { LocationDao } from 'src/app/dao/location-dao';
import { User } from 'src/app/model/user';
import { UserWebResponse } from 'src/app/model/user-web-response';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { TableData } from 'src/app/routes/userlist/ng2-table-data';
import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';
import { Location } from 'src/app/model/location';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { Router } from '@angular/router';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const apiUrl = 'https://jsonplaceholder.typicode.com/posts/42';

@Injectable({
    providedIn: 'root'
})
export class LocationDaoImpl implements LocationDao {
    loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
    serverURL:string;
    router: Router;
    constructor(public settings: SettingsService,private injector: Injector,private http:HttpClient) {
        this.serverURL = this.settings.getAppSetting('serverURL');
        this.router = this.injector.get(Router);
    }

    getLocation(): any {
        const l: Location = new Location();
        // l.organization_id = 1;
        // l.name = '東元醫院';
        // l.country = '台灣';
        // l.address1 = '新竹縣竹北市縣政二路69號';
        // l.address2 = '縣政二路69號';
        // l.city = '新竹縣';
        // l.state = '竹北市';
        // l.zip_code = '302';
        // l.type = '醫院';
        // l.enable = true;       
        return l;
    }

    getLocationList(): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let url = this.serverURL+'locationlist';
        if(this.loginViewModel.loginUser.role_id == 4){
            url = this.serverURL+'managerlocationlist';
        }
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.data;
            }),
            catchError(this.handleError<string>('getLocationList'))
        );
    }

    
    addLocation(location): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'location';
        return this.http.post<boolean>(url, location.toJsonString(), httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.success;
            }),
            catchError(this.handleError<string>('addLocation'))
        );
    }

    editLocation(location): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'location';
        return this.http.put<boolean>(url, location.toJsonString(), httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.success;
            }),
            catchError(this.handleError<string>('editLocation'))
        );
    }


    getUserAPI(user): Observable<any> {
        console.log(user);
        const u: User = new User();
        u.name = 'Buffer';
        u.picture = 'assets/img/user/10.jpg';
        return of(u);
        //return this.http.get<User>(apiUrl);
    }

    getTestUser(): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'user?user_id=1';
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                console.log(userWebResponse.data);
                return new User(userWebResponse.data);
            }),
            catchError(this.handleError<string>('getTestUser'))
        );
    }

    login(user): Observable<any> {
        const url = this.serverURL+'login?media_id=browser';
        return this.http.post<UserWebResponse>(url, user.toJsonString(), httpOptions).pipe(
            map(response => {
                return new UserWebResponse(response);
                // var userWebResponse = new UserWebResponse(response);
                // console.log(userWebResponse.data);
                // return new User(userWebResponse.data);
            }),
            catchError(this.handleError<string>('login'))
        );
    }

    forgotpassword(user): Observable<any> {
        const url = this.serverURL+'resetpassword';
        return this.http.post<UserWebResponse>(url, user.toJsonString(), httpOptions).pipe(
            map(response => {
                return new UserWebResponse(response);
            }),
            catchError(this.handleError<string>('forgotpassword'))
        );
    }

    logout(user): Observable<any> {
        // console.log(user);
        // return of(true);
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'logout';
        return this.http.put<boolean>(url, user.toJsonString(), httpOptions).pipe(
            map(response => {
                return true;
            }),
            catchError(this.handleError<string>('logout'))
        );
    }

    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          localStorage.clear();//發生錯誤,清空localStorage
          this.router.navigate(['login']);//發生錯誤,導向404頁
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
      
          // TODO: better job of transforming error for user consumption
          console.log(`${operation} failed: ${error.message}`);
      
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }

}

// export const UserServiceProvider = new InjectionToken(
//     'UserServiceProvider',
//     { providedIn: 'root', factory: () => new UserServiceImpl(@Inject(ENV) private environment) }
// );
