import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';
export class Base {
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
    toJsonString(): string {
        let jsonConvert: JsonConvert = new JsonConvert();
        let u = jsonConvert.serializeObject(this);
        console.log(JSON.stringify(u));
        return JSON.stringify(u);
        // let json = JSON.stringify(this);
        // Object.keys(this).filter(key => key[0] === "_").forEach(key => {
        //     json = json.replace(key, key.substring(1));
        // });
        // return json;
    }
}
