import { maxNumber,minNumber,minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert, Any} from 'json2typescript';
import { OtaDownload } from 'src/app/model/ota-download';
@JsonObject('OtaUpload')
export class OtaUpload extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('user_id', Number)
    private _user_id: number;
    @JsonProperty('version', String)
    private _version: string;
    @JsonProperty('filename', String)
    private _filename: string;
    @JsonProperty('size', Number)
    private _size: number;
    @JsonProperty('enable', Number)
    private _enable: number;
    @JsonProperty('createdate', String)
    private _createdate: string;
    @JsonProperty('file', Any)
    private _file: any;
    @JsonProperty('otaDownloadList', [OtaDownload])
    private _otaDownloadList: Array<OtaDownload>;
    @JsonProperty('otaDownloadListLength', Number)
    private _otaDownloadListLength: number;
    
    @prop()
    get id() {
        return this._id;
    }
    set id(id: number) {
        this._id = id;
    }

    @prop()
    get user_id() {
        return this._user_id;
    }
    set user_id(user_id: number) {
        this._user_id = user_id;
    }

    @prop()
    get version() {
        return this._version;
    }
    set version(version: string) {
        this._version = version;
    }

    @prop()
    get filename() {
        return this._filename;
    }
    set filename(filename: string) {
        this._filename = filename;
    }

    @prop()
    get size() {
        return this._size;
    }
    set size(size: number) {
        this._size = size;
    }


    @prop()
    get enable() {
        return this._enable;
    }
    set enable(enable: number) {
        this._enable = enable;
    }

    @prop()
    get createdate() {
        return this._createdate;
    }
    set createdate(createdate: string) {
        this._createdate = createdate;
    }

    @prop()
    get file() {
        return this._file;
    }
    set file(file: any) {
        this._file = file;
    }

    @prop()
    get otaDownloadList(): Array<OtaDownload> {
        return this._otaDownloadList;
    }
    set otaDownloadList(otaDownloadList: Array<OtaDownload>) {
        this._otaDownloadList = otaDownloadList;
    }

    @prop()
    get otaDownloadListLength() {
        return this._otaDownloadListLength;
    }
    set otaDownloadListLength(otaDownloadListLength: number) {
        this._otaDownloadListLength = otaDownloadListLength;
    }
    
}
