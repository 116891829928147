import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
import d3_save_pdf from 'd3-save-pdf';
import { jsPDF } from "jspdf";

import { Injector } from '@angular/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user-service';
import { UserlistViewModel } from 'src/app/routes/userlist/userlist-view-model';
import { fromEvent, Subject, Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import {Md5} from 'ts-md5/dist/md5';
import { PatientlistViewModel } from 'src/app/routes/patientlist/patientlist-view-model';
import { PatientdetailViewModel } from 'src/app/routes/patientlist/patientdetail/patientdetail-view-model';
import { SdkViewModel } from 'src/app/routes/pages/sdk/sdk-view-model';

import { Patient } from 'src/app/model/patient';
import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';
import { Data } from 'src/app/model/data';
import { WebDevice } from 'src/app/model/web-device';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
declare var $: any;
@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
  userFormGroup: FormGroup;
  router: Router;
  userService: UserService = this.injector.get(UserService);
  patient: Patient;
  patientlistViewModel: PatientlistViewModel = this.injector.get(PatientlistViewModel);
  patientdetailViewModel: PatientdetailViewModel = this.injector.get(PatientdetailViewModel);
  sdkViewModel: SdkViewModel = this.injector.get(SdkViewModel);
  
  loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
  public update$: Subject<any> = new Subject<any>();
  public downloadImage$: Subject<any> = new Subject<any>();
  
  //allDataList:Array<Data>;
  isNoData:boolean;
  isDataReady:boolean = false;
  serverURL:string;
  usedayList: Date[];
  dataDic: {};
  private imgData: any;
  private w: number = 1000;
  private h: number = 400;
  private margin = { top: 10, right: 50, bottom: 60, left: 100 };
  private width = this.w - this.margin.left - this.margin.right;
  private height = this.h - this.margin.top - this.margin.bottom;

  subscription_char = new Subscription();
  public chart_detail: number;
  constructor(public settings: SettingsService,public injector: Injector, private rxfb: RxFormBuilder,private http:HttpClient) {
    this.serverURL = this.settings.getAppSetting('serverURL');
    this.router = this.injector.get(Router);
    try {
      let ciphertext = localStorage.getItem('sdkPatient');
      var bytes  = CryptoJS.AES.decrypt(ciphertext, 'somnicSecretKey');
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      this.sdkViewModel.selectPatient = new Patient(decryptedData);
    } catch (error) {
        console.error('Error getting data from localStorage', error);
        localStorage.clear();//發生錯誤,清空localStorage
        this.router.navigate(['404']);//發生錯誤,導向404頁
    }
    this.patient = this.sdkViewModel.selectPatient;
    this.patient.clinicalUserList =  this.loginViewModel.loginUser.clinicalUserList;
    
    let haveAll = false;
    let haveAllIndex = 0;
    for (let i = 0; i < this.patient.webDeviceList.length; i++) {
      if( this.patient.webDeviceList[i].serial == 'All'){
          haveAll = true;
          haveAllIndex = i;
      }
    }
    if(this.patient.webDeviceList.length > 0){
      if(haveAll == false){
        let allWebDevice = new WebDevice();
        allWebDevice.serial = 'All';
        this.patient.webDeviceList.push(allWebDevice);
        this.patient.webDevice = allWebDevice;
      }
      else{
        this.patient.webDevice = this.patient.webDeviceList[haveAllIndex];
      }
    }

    this.patient.chartDataList = [];
    for (let i = 0; i < this.patient.dataList.length; i++) {
      let data = new Data();
      Object.assign(data, this.patient.dataList[i]);
      this.patient.chartDataList.push(data);
    }

    this.isNoData = true;
    if(this.patient.chartDataList.length > 0){
      this.isNoData = false;
      this.patient.data = this.patient.chartDataList[0];
      
      this.patient.chartPicker = new Date(this.patient.chartDataList[0].datetime_full);
    }
    //this.allDataList = this.patient.chartDataList;

    this.usedayList = [];
    this.dataDic = {};
    this.patient.chartDataList.forEach(data => {
      var d = new Date(data.datetime_full);
      this.usedayList.push(d)

      var yyyymmdd = this.dateToYYYYmmdd(d)
      this.dataDic[yyyymmdd] = data

    });

    this.userFormGroup = this.rxfb.formGroup(this.patient);
  }


  ngAfterViewInit(): void {
    const parent = this;
    const observer = {
      next(patient) {
        parent.patient.data = patient.data;
        parent.createImage();
        parent.isDataReady = true
      }
    };
    this.sdkViewModel.input.update = this.update$;
    this.sdkViewModel.input.downloadImage = this.downloadImage$;
    
    this.subscription_char = this.sdkViewModel.output.chart.subscribe(observer);

    if (this.sdkViewModel.setup_update() === false) {
      throw new Error('example error message');
    }
    if (this.isNoData == false && this.sdkViewModel.setup_downloadImage() === false) {
      throw new Error('example error message');
    }
    this.update$.next(this.patient);
  }
  ngOnDestroy(): void {
    this.subscription_char.unsubscribe();
  }

  onDownload(){
    console.log("onDownload");
  }

  onDownloadImage(){
    this.downloadImage$.next(this.getImgData());
  }

  onDownloadFile(){
    this.isDataReady = false;

    const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
    httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        
    const url = this.serverURL+'compliance_download?user_id='+this.patient.cloud_user_id+'&date='+this.patient.data.datetime_full;
    this.http.get(url,{ headers: httpOptions.headers,responseType: 'arraybuffer'} 
      ).subscribe(response => this.downLoadFile(response, "application/zip"));

  }

  downLoadPDF(data: any, type: string) { 
    const fileName = this.patient.email+'_'+this.patient.from_date+'_'+this.patient.to_date+'.pdf'; 
    const a = document.createElement('a'); 
    document.body.appendChild(a); 
    const blob = new Blob([data], {type: type}); 
    const url = window.URL.createObjectURL(blob); 
    a.href = url; 
    a.download = fileName; 
    a.click(); 
    window.URL.revokeObjectURL(url); 
    this.isDataReady = true;
  }

  onDownloadPDFReport(){
    this.isDataReady = false;


    this.patient.from_date = this.dateToYYYYmmdd(this.patient.chartPicker)
    this.patient.to_date = this.dateToYYYYmmdd(this.patient.chartPicker)


    const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
    httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
    
    // var tzoffset = new Date().getTimezoneOffset();
    // console.log(tzoffset);
    var language = 'traditional_chinese'//'traditional_chinese'//this.patient.language;
    var unit = 'mmHg'//this.patient.unit;

    var oxi_serial = ''
    //序號為All和序號為R開頭不要找尋,直接用空字串去找
    if (this.patient.webDevice.serial != 'All' && this.patient.webDevice.serial.indexOf("R") != 0)
      oxi_serial = this.patient.webDevice.serial

    const url = this.serverURL+'report2_spo2?cloud_user_id='+this.patient.cloud_user_id+'&data_id='+this.patient.data.id+'&oxi_serial='+oxi_serial+'&patient_id='+this.patient.id+'&from_date='+this.patient.from_date+'&to_date='+this.patient.to_date+'&language='+language+'&unit='+unit;
    this.http.get(url,{ headers: httpOptions.headers,responseType: 'arraybuffer'} 
      ).subscribe(response => this.downLoadPDF(response, "application/pdf"));

  }
  downLoadFile(data: any, type: string) { 
    const fileName = this.patient.email+'_'+this.patient.data.datetime_full+'.zip'; 
    const a = document.createElement('a'); 
    document.body.appendChild(a); 
    const blob = new Blob([data], {type: type}); 
    const url = window.URL.createObjectURL(blob); 
    a.href = url; 
    a.download = fileName; 
    a.click(); 
    window.URL.revokeObjectURL(url); 
    this.isDataReady = true;
  }

  onDevice(){
    this.patient.chartDataList = [];
    for (let i = 0; i < this.patient.dataList.length; i++) {
      let data = new Data();
      Object.assign(data, this.patient.dataList[i]);
    
      if(this.patient.webDevice.serial == 'All'){
        this.patient.chartDataList.push(data);
      }
      else if(data.serial == ''){
        this.patient.chartDataList.push(data);
      }
      else if(data.serial.indexOf(','+this.patient.webDevice.serial+',') != -1){
        this.patient.chartDataList.push(data);
      }
    }
    if(this.patient.chartDataList.length > 0){

      this.usedayList = [];
      this.patient.chartDataList.forEach(data => {
          var d = new Date(data.datetime_full);
          this.usedayList.push(d)
      });
      this.patient.chartPicker = new Date(this.patient.chartDataList[0].datetime_full);
      this.userFormGroup.get('chartPicker').setValue(this.patient.chartPicker);

      this.patient.data = this.patient.chartDataList[0];
      //console.log(this.patient.webDevice);
      this.isNoData = false;
      this.isDataReady = false
      this.update$.next(this.patient);
    }
    else{
      this.isNoData = true;
    }
  }
  
  dateToYYYYmmdd(picker:Date):string{
    var yyyy = picker.getFullYear();
    var mm = picker.getMonth()+1;
    var dd = picker.getDate();
    var result = ''
    var mm_str = ''
    var dd_str = ''
    if( mm < 10 ){
      mm_str = '0'+String(mm)
    }
    else{
      mm_str = String(mm)
    }

    if( dd < 10 ){
      dd_str = '0'+String(dd)
    }
    else{
      dd_str = String(dd)
    }

    result = String(yyyy)+'-'+mm_str+'-'+dd_str
    return result
  }

  onUpdate(){
    var chart_date = this.dateToYYYYmmdd(this.patient.chartPicker)
    var data = this.dataDic[chart_date];
    this.patient.data = data;

    this.isDataReady = false
    //console.log(this.patient.webDevice);
    this.update$.next(this.patient);
  }

  onEdit(){
    this.router.navigate(['patientlist/patientdetail/editmemonote']);
    //this.update$.next(this.patient);
  }

  chartFilter = (d: Date | null): boolean => {
    const time = d.getTime();
    return this.usedayList.find(x=>x.getTime()==time)?true:false;
  }

  onCancel(){
    console.warn(this.userFormGroup.value);
    this.router.navigate(['patientlist']);
  }

  ngOnInit() {
    this.chart_detail = 0;
    if(this.loginViewModel.loginUser.chart_detail == 1){
        this.chart_detail = 1;
    }
    this.createImage();
  }

  private createImage() {
    if(this.isNoData == false){
      var source = ``;
      if(this.patient.data.inap_flag == 0 && this.patient.data.compliance_flag == 0 ){
        source += `<svg viewBox="0 0 240 160" xmlns="http://www.w3.org/2000/svg">
                  <style>
                  .small { font: 6px sans-serif; }
                  .heavy { font: bold 30px sans-serif; }
                  .Rrrrr { font: italic 40px serif; fill: red; }
                  </style>
                  <text x="20" y="35" class="small">Pressure Setting</text>
                  <text x="70" y="35" class="small">`+ this.patient.data.pressure_setting+` mmhg</text>
                  
                  <text x="20" y="65" class="small">Treatment</text>
                  <text x="65" y="65" class="small">`+ this.patient.data.treat_str+`</text>
                  <text x="140" y="65" class="small">Seal</text>
                  <text x="170" y="65" class="small">`+ this.patient.data.seal_str+`</text>
                  <text x="20" y="95" class="small">Avg Pressure</text>
                  <text x="65" y="95" class="small">`+ this.patient.data.avg_pressure+` mmhg</text>
                  <text x="140" y="95" class="small">Sealing Time</text>
                  <text x="200" y="95" class="small">`+ this.patient.data.seal_percent+` %</text>
                  <text x="20" y="125" class="small">ODI</text>
                  <text x="65" y="125" class="small">`+ this.patient.data.avg_odi+` Time/hr</text>
                  <text x="140" y="125" class="small">Avg SpO2</text>
                  <text x="200" y="125" class="small">`+ this.patient.data.avg_spo2+` %</text>
                  
                  </svg>`;
      }
      if(this.patient.data.inap_flag == 1 && this.patient.data.compliance_flag == 0 ){
        if( this.chart_detail == 1 ){
          source += `<svg viewBox="0 0 240 160" xmlns="http://www.w3.org/2000/svg">
                    <style>
                    .small { font: 6px sans-serif; }
                    .heavy { font: bold 30px sans-serif; }
                    .Rrrrr { font: italic 40px serif; fill: red; }
                    </style>
                    <text x="20" y="35" class="small">Detail</text>
                    <text x="20" y="65" class="small">Pressure Setting</text>
                    <text x="70" y="65" class="small">`+ this.patient.data.pressure_setting+` mmhg</text>
                    <text x="20" y="95" class="small">Treatment</text>
                    <text x="65" y="95" class="small">`+ this.patient.data.treat_str+`</text>
                    <text x="140" y="95" class="small">Seal</text>
                    <text x="170" y="95" class="small">`+ this.patient.data.seal_str+`</text>
                    <text x="20" y="125" class="small">Avg Pressure</text>
                    <text x="65" y="125" class="small">`+ this.patient.data.avg_pressure+` mmhg</text>
                    <text x="140" y="125" class="small">Sealing Time</text>
                    <text x="200" y="125" class="small">`+ this.patient.data.seal_percent+` %</text>
                    <text x="20" y="155" class="small">ODI</text>
                    <text x="65" y="155" class="small">`+ this.patient.data.avg_odi+` Time/hr</text>
                    <text x="140" y="155" class="small">Avg SpO2</text>
                    <text x="200" y="155" class="small">`+ this.patient.data.avg_spo2+` %</text>
                    </svg>`;
        }
        else{
          source += `<svg viewBox="0 0 240 160" xmlns="http://www.w3.org/2000/svg">
                    <style>
                    .small { font: 6px sans-serif; }
                    .heavy { font: bold 30px sans-serif; }
                    .Rrrrr { font: italic 40px serif; fill: red; }
                    </style>
                    </svg>`;
        }
      }
      if(this.patient.data.inap_flag == 0 && this.patient.data.compliance_flag == 1 ){
        source += `<svg viewBox="0 0 240 160" xmlns="http://www.w3.org/2000/svg">
                  <style>
                  .small { font: 6px sans-serif; }
                  .heavy { font: bold 30px sans-serif; }
                  .Rrrrr { font: italic 40px serif; fill: red; }
                  </style>
                  <text x="20" y="35" class="small">Summary</text>
                  <text x="20" y="65" class="small">Pressure Setting</text>
                  <text x="70" y="65" class="small">`+ this.patient.data.pressure_setting_compliance+` mmhg</text>
                  <text x="20" y="95" class="small">Treatment</text>
                  <text x="65" y="95" class="small">`+ this.patient.data.treat_compliance_str+`</text>
                  <text x="140" y="95" class="small">Seal</text>
                  <text x="170" y="95" class="small">`+ this.patient.data.seal_compliance_str+`</text>
                  <text x="20" y="125" class="small">Sealing Time</text>
                  <text x="65" y="125" class="small">`+ this.patient.data.seal_percent_compliance+` %</text>
                  </svg>`;
      }
      if(this.patient.data.inap_flag == 1 && this.patient.data.compliance_flag == 1 ){
        if( this.chart_detail == 1 ){
          source += `<svg viewBox="0 0 240 280" xmlns="http://www.w3.org/2000/svg">
                    <style>
                    .small { font: 8px sans-serif; }
                    .heavy { font: bold 30px sans-serif; }
                    .Rrrrr { font: italic 40px serif; fill: red; }
                    </style>
                    <text x="20" y="35" class="small">Detail</text>
                    <text x="20" y="65" class="small">Pressure Setting</text>
                    <text x="90" y="65" class="small">`+ this.patient.data.pressure_setting+` mmhg</text>
                    <text x="20" y="95" class="small">Treatment</text>
                    <text x="65" y="95" class="small">`+ this.patient.data.treat_str+`</text>
                    <text x="140" y="95" class="small">Seal</text>
                    <text x="170" y="95" class="small">`+ this.patient.data.seal_str+`</text>
                    <text x="20" y="125" class="small">Avg Pressure</text>
                    <text x="80" y="125" class="small">`+ this.patient.data.avg_pressure+` mmhg</text>
                    <text x="140" y="125" class="small">Sealing Time</text>
                    <text x="200" y="125" class="small">`+ this.patient.data.seal_percent+` %</text>
                    <text x="20" y="155" class="small">ODI</text>
                    <text x="65" y="155" class="small">`+ this.patient.data.avg_odi+` Time/hr</text>
                    <text x="140" y="155" class="small">Avg SpO2</text>
                    <text x="200" y="155" class="small">`+ this.patient.data.avg_spo2+` %</text>
                    
                    `;
            source += `
                    <text x="20" y="185" class="small">Summary</text>
                    <text x="20" y="215" class="small">Pressure Setting</text>
                    <text x="90" y="215" class="small">`+ this.patient.data.pressure_setting_compliance+` mmhg</text>
                    <text x="20" y="245" class="small">Treatment</text>
                    <text x="65" y="245" class="small">`+ this.patient.data.treat_compliance_str+`</text>
                    <text x="140" y="245" class="small">Seal</text>
                    <text x="170" y="245" class="small">`+ this.patient.data.seal_compliance_str+`</text>
                    <text x="20" y="275" class="small">Sealing Time</text>
                    <text x="90" y="275" class="small">`+ this.patient.data.seal_percent_compliance+` %</text>
                    
                    </svg>`;
        }
        else{
          source += `<svg viewBox="0 0 240 280" xmlns="http://www.w3.org/2000/svg">
                    <style>
                    .small { font: 8px sans-serif; }
                    .heavy { font: bold 30px sans-serif; }
                    .Rrrrr { font: italic 40px serif; fill: red; }
                    </style>
                    `;
            source += `
                    <text x="20" y="185" class="small">Summary</text>
                    <text x="20" y="215" class="small">Pressure Setting</text>
                    <text x="90" y="215" class="small">`+ this.patient.data.pressure_setting_compliance+` mmhg</text>
                    <text x="20" y="245" class="small">Treatment</text>
                    <text x="65" y="245" class="small">`+ this.patient.data.treat_compliance_str+`</text>
                    <text x="140" y="245" class="small">Seal</text>
                    <text x="170" y="245" class="small">`+ this.patient.data.seal_compliance_str+`</text>
                    <text x="20" y="275" class="small">Sealing Time</text>
                    <text x="90" y="275" class="small">`+ this.patient.data.seal_percent_compliance+` %</text>
                    
                    </svg>`;
        }
      }
      
      var blob = new Blob([source], {type: "image/svg+xml;charset=utf-8"});
      var url = window.URL.createObjectURL(blob);
      var image = new Image();
      image.onload = () => {
       
      let canvas = document.createElement('canvas');
       
      canvas.width = this.width;
      canvas.height = this.height;
      let context = canvas.getContext('2d');
      // draw image in canvas starting left-0 , top - 0
      context.fillStyle = "white";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, this.width, this.height );
       
      this.imgData = canvas.toDataURL("image/png");
      /*var doc = new jsPDF('p', 'pt', [1024,4096]);
      doc.addImage(this.imgData, 'PNG', 100,100, this.width, this.height);
      doc.save('chart.pdf');*/
      // downloadImage(canvas); need to implement
      };
       
      image.src = url;
    }
  }

  public getImgData(): any {
    return {"wordData":this.imgData,
            "chartListLength":this.patient.data.chartListLength,
            "chart_detail":this.chart_detail
          };//return this.imgData;
  }

}
