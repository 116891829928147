import { Injectable, InjectionToken } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { User } from 'src/app/model/user';
import { Location } from 'src/app/model/location';
@Injectable()
export abstract class LocationService {
    abstract getLocation(): any;
    abstract getLocationList(): Observable<any>;
    abstract getUserAPI(user): Observable<any>;
    abstract getTestUser(): Observable<any>;
    abstract addLocation(location): Observable<any>;
    abstract editLocation(location): Observable<any>;
    abstract login(user): Observable<any>;
    abstract forgotpassword(user): Observable<any>;
    abstract logout(user): Observable<any>;
}

  
