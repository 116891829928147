import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import { SealPercentDetail } from 'src/app/model/seal-percent-detail';
import { PressureDetail } from 'src/app/model/pressure-detail';
import { RingSleepStageDetail } from 'src/app/model/ring-sleep-stage-detail';
import { Spo2Detail } from 'src/app/model/spo2-detail';
import { HeartrateDetail } from 'src/app/model/heartrate-detail';
import { OddurationDetail } from 'src/app/model/odduration-detail';

import { FitbitHeartDetail } from 'src/app/model/fitbit-heart-detail';
import { FitbitSleepDetail } from 'src/app/model/fitbit-sleep-detail';
import { ODdurationCountDetail } from 'src/app/model/odduration-count-detail';
import { ChartListLength } from 'src/app/model/chart-list-length';

import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('Data')
export class Data extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('user_id', Number)
    private _user_id: number;
    @JsonProperty('serial', String)
    private _serial: string;
    @JsonProperty('media_id', String)
    private _media_id: string;
    @JsonProperty('datetime_type', String)
    private _datetime_type: string;
    @JsonProperty('datetime', String)
    private _datetime: string;
    @JsonProperty('datetime_time', String)
    private _datetime_time: string;
    @JsonProperty('datetime_full', String)
    private _datetime_full: string;
    @JsonProperty('avg_odi', Number)
    private _avg_odi: number;
    @JsonProperty('avg_sealing', Number)
    private _avg_sealing: number;
    @JsonProperty('avg_pressure', Number)
    private _avg_pressure: number;
    @JsonProperty('avg_spo2', Number)
    private _avg_spo2: number;
    @JsonProperty('min_spo2', Number)
    private _min_spo2: number;
    @JsonProperty('treatime_total', String)
    private _treatime_total: string;




    @JsonProperty('inap_flag', Number)
    private _inap_flag: number;
    @JsonProperty('compliance_flag', Number)
    private _compliance_flag: number;
    @JsonProperty('treat_compliance_str', String)
    private _treat_compliance_str: string;
    @JsonProperty('seal_compliance_str', String)
    private _seal_compliance_str: string;
    @JsonProperty('avg_pressure_compliance', Number)
    private _avg_pressure_compliance: number;
    @JsonProperty('seal_percent_compliance', Number)
    private _seal_percent_compliance: number;


    @JsonProperty('chart_start', Number)
    private _chart_start: number;
    @JsonProperty('chart_end', Number)
    private _chart_end: number;

    @JsonProperty('treat', Number)
    private _treat: number;
    @JsonProperty('seal', Number)
    private _seal: number;
    @JsonProperty('treat_compliance', Number)
    private _treat_compliance: number;
    @JsonProperty('seal_compliance', Number)
    private _seal_compliance: number;

    @JsonProperty('treat_str', String)
    private _treat_str: string;
    @JsonProperty('seal_str', String)
    private _seal_str: string;
    @JsonProperty('odi', Number)
    private _odi: number;
    @JsonProperty('sleepnote', String)
    private _sleepnote: string;
    @JsonProperty('sleepnoteList', [Number])
    private _sleepnoteList: Array<number>;
    @JsonProperty('rest_rating', String)
    private _rest_rating: string;
    @JsonProperty('rest_ratingList', [Number])
    private _rest_ratingList: Array<number>;
    @JsonProperty('remedies', String)
    private _remedies: string;
    @JsonProperty('remediesList', [Number])
    private _remediesList: Array<number>;
    @JsonProperty('remedies_checkbox1', Boolean)
    private _remedies_checkbox1: boolean;
    @JsonProperty('remedies_checkbox2', Boolean)
    private _remedies_checkbox2: boolean;
    @JsonProperty('remedies_checkbox3', Boolean)
    private _remedies_checkbox3: boolean;
    @JsonProperty('remedies_checkbox4', Boolean)
    private _remedies_checkbox4: boolean;
    @JsonProperty('remedies_checkbox5', Boolean)
    private _remedies_checkbox5: boolean;
    @JsonProperty('remedies_checkbox6', Boolean)
    private _remedies_checkbox6: boolean;
    @JsonProperty('remedies_checkbox7', Boolean)
    private _remedies_checkbox7: boolean;
    @JsonProperty('remedies_checkbox8', Boolean)
    private _remedies_checkbox8: boolean;
    @JsonProperty('remedies_checkbox9', Boolean)
    private _remedies_checkbox9: boolean;
    @JsonProperty('remedies_checkbox10', Boolean)
    private _remedies_checkbox10: boolean;
    @JsonProperty('remedies_checkbox11', Boolean)
    private _remedies_checkbox11: boolean;
    @JsonProperty('remedies_checkbox12', Boolean)
    private _remedies_checkbox12: boolean;
    @JsonProperty('remedies_checkbox13', Boolean)
    private _remedies_checkbox13: boolean;
    @JsonProperty('remedies_checkbox14', Boolean)
    private _remedies_checkbox14: boolean;
    @JsonProperty('remedies_checkbox15', Boolean)
    private _remedies_checkbox15: boolean;
    @JsonProperty('seal_percent', Number)
    private _seal_percent: number;
    @JsonProperty('memonote', String)
    private _memonote: string;
    @JsonProperty('pressure_setting', String)
    private _pressure_setting: string;
    @JsonProperty('pressure_setting_compliance', String)
    private _pressure_setting_compliance: string;
    @JsonProperty('pressure_setting_compliance_cmH2O', Number)//20240202美國
    private _pressure_setting_compliance_cmH2O: number;//20240202美國
    @JsonProperty('pressure_setting_max', Number)
    private _pressure_setting_max: number;
    @JsonProperty('pressure_setting_max_compliance', Number)
    private _pressure_setting_max_compliance: number;
    @JsonProperty('pressure_setting_min', Number)
    private _pressure_setting_min: number;
    @JsonProperty('pressure_setting_min_compliance', Number)
    private _pressure_setting_min_compliance: number;
    @JsonProperty('sealPercentDetailList', [SealPercentDetail])
    private _sealPercentDetailList: Array<SealPercentDetail>;
    @JsonProperty('sealPercentDetail', SealPercentDetail)
    private _sealPercentDetail: SealPercentDetail;
    @JsonProperty('sealPercentDetailComplianceList', [SealPercentDetail])
    private _sealPercentDetailComplianceList: Array<SealPercentDetail>;

    @JsonProperty('pressure', [PressureDetail])
    private _pressure: Array<PressureDetail>;
    @JsonProperty('ring_sleep_stage', [RingSleepStageDetail])
    private _ring_sleep_stage: Array<RingSleepStageDetail>;
    @JsonProperty('spo2', [Spo2Detail])
    private _spo2: Array<Spo2Detail>;
    @JsonProperty('heartrate', [HeartrateDetail])
    private _heartrate: Array<HeartrateDetail>;
    @JsonProperty('ODduration', [OddurationDetail])
    private _ODduration: Array<OddurationDetail>;
    @JsonProperty('ODdurationCount', [ODdurationCountDetail])
    private _ODdurationCount: Array<ODdurationCountDetail>;
    @JsonProperty('maxcounts', Number)
    private _maxcounts: number;
    @JsonProperty('fitbit_heart', [FitbitHeartDetail])
    private _fitbit_heart: Array<FitbitHeartDetail>;
    @JsonProperty('fitbit_sleep', [FitbitSleepDetail])
    private _fitbit_sleep: Array<FitbitSleepDetail>;
    @JsonProperty('chartListLength', ChartListLength)
    private _chartListLength: ChartListLength;
    @prop()
    get id() {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }
    
    @prop()
    get user_id(): number {
        return this._user_id;
    }

    set user_id(user_id: number) {
        this._user_id = user_id;
    }

    @prop()
    get serial(): string {
        return this._serial;
    }

    set serial(serial: string) {
        this._serial = serial;
    }
    @prop()
    get media_id(): string {
        return this._media_id;
    }

    set media_id(media_id: string) {
        this._media_id = media_id;
    }
    @prop()
    get datetime_type(): string {
        return this._datetime_type;
    }

    set datetime_type(datetime_type: string) {
        this._datetime_type = datetime_type;
    }
    @prop()
    get datetime(): string {
        return this._datetime;
    }

    set datetime(datetime: string) {
        this._datetime = datetime;
    }
    @prop()
    get datetime_time(): string {
        return this._datetime_time;
    }

    set datetime_time(datetime_time: string) {
        this._datetime_time = datetime_time;
    }
    @prop()
    get datetime_full(): string {
        return this._datetime_full;
    }

    set datetime_full(datetime_full: string) {
        this._datetime_full = datetime_full;
    }
    @prop()
    get avg_odi(): number {
        return this._avg_odi;
    }

    set avg_odi(avg_odi: number) {
        this._avg_odi = avg_odi;
    }
    @prop()
    get avg_sealing(): number {
        return this._avg_sealing;
    }

    set avg_sealing(avg_sealing: number) {
        this._avg_sealing = avg_sealing;
    }
    @prop()
    get avg_pressure(): number {
        return this._avg_pressure;
    }

    set avg_pressure(avg_pressure: number) {
        this._avg_pressure = avg_pressure;
    }
    @prop()
    get avg_spo2(): number {
        return this._avg_spo2;
    }
    set avg_spo2(avg_spo2: number) {
        this._avg_spo2 = avg_spo2;
    }
    @prop()
    get min_spo2(): number {
        return this._min_spo2;
    }
    set min_spo2(min_spo2: number) {
        this._min_spo2 = min_spo2;
    }
    @prop()
    get treatime_total(): string {
        return this._treatime_total;
    }

    set treatime_total(treatime_total: string) {
        this._treatime_total = treatime_total;
    }

    @prop()
    get inap_flag(): number {
        return this._inap_flag;
    }
    set inap_flag(inap_flag: number) {
        this._inap_flag = inap_flag;
    }

    @prop()
    get compliance_flag(): number {
        return this._compliance_flag;
    }
    set compliance_flag(compliance_flag: number) {
        this._compliance_flag = compliance_flag;
    }

    @prop()
    get treat_compliance_str(): string {
        return this._treat_compliance_str;
    }
    set treat_compliance_str(treat_compliance_str: string) {
        this._treat_compliance_str = treat_compliance_str;
    }

    @prop()
    get seal_compliance_str(): string {
        return this._seal_compliance_str;
    }
    set seal_compliance_str(seal_compliance_str: string) {
        this._seal_compliance_str = seal_compliance_str;
    }

    @prop()
    get avg_pressure_compliance(): number {
        return this._avg_pressure_compliance;
    }
    set avg_pressure_compliance(avg_pressure_compliance: number) {
        this._avg_pressure_compliance = avg_pressure_compliance;
    }

    @prop()
    get seal_percent_compliance(): number {
        return this._seal_percent_compliance;
    }
    set seal_percent_compliance(seal_percent_compliance: number) {
        this._seal_percent_compliance = seal_percent_compliance;
    }

    @prop()
    get chart_start(): number {
        return this._chart_start;
    }
    set chart_start(chart_start: number) {
        this._chart_start = chart_start;
    }

    @prop()
    get chart_end(): number {
        return this._chart_end;
    }
    set chart_end(chart_end: number) {
        this._chart_end = chart_end;
    }

    @prop()
    get treat(): number {
        return this._treat;
    }
    set treat(treat: number) {
        this._treat = treat;
    }
    @prop()
    get seal(): number {
        return this._seal;
    }
    set seal(seal: number) {
        this._seal = seal;
    }
    @prop()
    get treat_compliance(): number {
        return this._treat_compliance;
    }
    set treat_compliance(treat_compliance: number) {
        this._treat_compliance = treat_compliance;
    }
    @prop()
    get seal_compliance(): number {
        return this._seal_compliance;
    }
    set seal_compliance(seal_compliance: number) {
        this._seal_compliance = seal_compliance;
    }

    @prop()
    get treat_str(): string {
        return this._treat_str;
    }

    set treat_str(treat_str: string) {
        this._treat_str = treat_str;
    }
    @prop()
    get seal_str(): string {
        return this._seal_str;
    }

    set seal_str(seal_str: string) {
        this._seal_str = seal_str;
    }
    @prop()
    get odi(): number {
        return this._odi;
    }

    set odi(odi: number) {
        this._odi = odi;
    }

    @prop()
    get sleepnote(): string {
        return this._sleepnote;
    }
    set sleepnote(sleepnote: string) {
        this._sleepnote = sleepnote;
    }
    @prop()
    get sleepnoteList(): Array<number> {
        return this._sleepnoteList;
    }
    set sleepnoteList(sleepnoteList: Array<number>) {
        this._sleepnoteList = sleepnoteList;
    }

    @prop()
    get rest_rating(): string {
        return this._rest_rating;
    }
    set rest_rating(rest_rating: string) {
        this._rest_rating = rest_rating;
    }
    @prop()
    get rest_ratingList(): Array<number> {
        return this._rest_ratingList;
    }
    set rest_ratingList(rest_ratingList: Array<number>) {
        this._rest_ratingList = rest_ratingList;
    }

    @prop()
    get remedies(): string {
        return this._remedies;
    }
    set remedies(remedies: string) {
        this._remedies = remedies;
    }
    @prop()
    get remediesList(): Array<number> {
        return this._remediesList;
    }
    set remediesList(remediesList: Array<number>) {
        this._remediesList = remediesList;
    }

    @prop()
    get remedies_checkbox1(): boolean {
        return this._remedies_checkbox1;
    }
    set remedies_checkbox1(remedies_checkbox1: boolean) {
        this._remedies_checkbox1 = remedies_checkbox1;
    }
    @prop()
    get remedies_checkbox2(): boolean {
        return this._remedies_checkbox2;
    }
    set remedies_checkbox2(remedies_checkbox2: boolean) {
        this._remedies_checkbox2 = remedies_checkbox2;
    }
    @prop()
    get remedies_checkbox3(): boolean {
        return this._remedies_checkbox3;
    }
    set remedies_checkbox3(remedies_checkbox3: boolean) {
        this._remedies_checkbox3 = remedies_checkbox3;
    }
    @prop()
    get remedies_checkbox4(): boolean {
        return this._remedies_checkbox4;
    }
    set remedies_checkbox4(remedies_checkbox4: boolean) {
        this._remedies_checkbox4 = remedies_checkbox4;
    }
    @prop()
    get remedies_checkbox5(): boolean {
        return this._remedies_checkbox5;
    }
    set remedies_checkbox5(remedies_checkbox5: boolean) {
        this._remedies_checkbox5 = remedies_checkbox5;
    }
    @prop()
    get remedies_checkbox6(): boolean {
        return this._remedies_checkbox6;
    }
    set remedies_checkbox6(remedies_checkbox6: boolean) {
        this._remedies_checkbox6 = remedies_checkbox6;
    }
    @prop()
    get remedies_checkbox7(): boolean {
        return this._remedies_checkbox7;
    }
    set remedies_checkbox7(remedies_checkbox7: boolean) {
        this._remedies_checkbox7 = remedies_checkbox7;
    }
    @prop()
    get remedies_checkbox8(): boolean {
        return this._remedies_checkbox8;
    }
    set remedies_checkbox8(remedies_checkbox8: boolean) {
        this._remedies_checkbox8 = remedies_checkbox8;
    }
    @prop()
    get remedies_checkbox9(): boolean {
        return this._remedies_checkbox9;
    }
    set remedies_checkbox9(remedies_checkbox9: boolean) {
        this._remedies_checkbox9 = remedies_checkbox9;
    }
    @prop()
    get remedies_checkbox10(): boolean {
        return this._remedies_checkbox10;
    }
    set remedies_checkbox10(remedies_checkbox10: boolean) {
        this._remedies_checkbox10 = remedies_checkbox10;
    }
    @prop()
    get remedies_checkbox11(): boolean {
        return this._remedies_checkbox11;
    }
    set remedies_checkbox11(remedies_checkbox11: boolean) {
        this._remedies_checkbox11 = remedies_checkbox11;
    }
    @prop()
    get remedies_checkbox12(): boolean {
        return this._remedies_checkbox12;
    }
    set remedies_checkbox12(remedies_checkbox12: boolean) {
        this._remedies_checkbox12 = remedies_checkbox12;
    }
    @prop()
    get remedies_checkbox13(): boolean {
        return this._remedies_checkbox13;
    }
    set remedies_checkbox13(remedies_checkbox13: boolean) {
        this._remedies_checkbox13 = remedies_checkbox13;
    }
    @prop()
    get remedies_checkbox14(): boolean {
        return this._remedies_checkbox14;
    }
    set remedies_checkbox14(remedies_checkbox14: boolean) {
        this._remedies_checkbox14 = remedies_checkbox14;
    }
    @prop()
    get remedies_checkbox15(): boolean {
        return this._remedies_checkbox15;
    }
    set remedies_checkbox15(remedies_checkbox15: boolean) {
        this._remedies_checkbox15 = remedies_checkbox15;
    }
    @prop()
    get seal_percent(): number {
        return this._seal_percent;
    }
    set seal_percent(seal_percent: number) {
        this._seal_percent = seal_percent;
    }

    @prop()
    get memonote(): string {
        return this._memonote;
    }

    set memonote(memonote: string) {
        this._memonote = memonote;
    }

    @prop()
    get pressure_setting(): string {
        return this._pressure_setting;
    }

    set pressure_setting(pressure_setting: string) {
        this._pressure_setting = pressure_setting;
    }

    @prop()
    get pressure_setting_compliance(): string {
        return this._pressure_setting_compliance;
    }
    set pressure_setting_compliance(pressure_setting_compliance: string) {
        this._pressure_setting_compliance = pressure_setting_compliance;
    }

    //20240202美國 
    @prop()
    get pressure_setting_compliance_cmH2O(): number {
        return this._pressure_setting_compliance_cmH2O;
    }
    set pressure_setting_compliance_cmH2O(pressure_setting_compliance_cmH2O: number) {
        this._pressure_setting_compliance_cmH2O = pressure_setting_compliance_cmH2O;
    }

    @prop()
    get pressure_setting_max(): number {
        return this._pressure_setting_max;
    }
    set pressure_setting_max(pressure_setting_max: number) {
        this._pressure_setting_max = pressure_setting_max;
    }

    @prop()
    get pressure_setting_max_compliance(): number {
        return this._pressure_setting_max_compliance;
    }
    set pressure_setting_max_compliance(pressure_setting_max_compliance: number) {
        this._pressure_setting_max_compliance = pressure_setting_max_compliance;
    }

    @prop()
    get pressure_setting_min(): number {
        return this._pressure_setting_min;
    }
    set pressure_setting_min(pressure_setting_min: number) {
        this._pressure_setting_min = pressure_setting_min;
    }

    @prop()
    get pressure_setting_min_compliance(): number {
        return this._pressure_setting_min_compliance;
    }
    set pressure_setting_min_compliance(pressure_setting_min_compliance: number) {
        this._pressure_setting_min_compliance = pressure_setting_min_compliance;
    }


    @prop()
    get pressure(): Array<PressureDetail> {
        return this._pressure;
    }
    set pressure(pressure: Array<PressureDetail>) {
        this._pressure = pressure;
    }

    @prop()
    get ring_sleep_stage(): Array<RingSleepStageDetail> {
        return this._ring_sleep_stage;
    }
    set ring_sleep_stage(ring_sleep_stage: Array<RingSleepStageDetail>) {
        this._ring_sleep_stage = ring_sleep_stage;
    }

    @prop()
    get spo2(): Array<Spo2Detail> {
        return this._spo2;
    }

    set spo2(spo2: Array<Spo2Detail>) {
        this._spo2 = spo2;
    }

    @prop()
    get heartrate(): Array<HeartrateDetail> {
        return this._heartrate;
    }

    set heartrate(heartrate: Array<HeartrateDetail>) {
        this._heartrate = heartrate;
    }

    @prop()
    get ODduration(): Array<OddurationDetail> {
        return this._ODduration;
    }
    set ODduration(ODduration: Array<OddurationDetail>) {
        this._ODduration = ODduration;
    }

    @prop()
    get ODdurationCount(): Array<ODdurationCountDetail> {
        return this._ODdurationCount;
    }
    set ODdurationCount(ODdurationCount: Array<ODdurationCountDetail>) {
        this._ODdurationCount = ODdurationCount;
    }

    @prop()
    get maxcounts(): number {
        return this._maxcounts;
    }
    set maxcounts(maxcounts: number) {
        this._maxcounts = maxcounts;
    }

    @prop()
    get fitbit_heart(): Array<FitbitHeartDetail> {
        return this._fitbit_heart;
    }

    set fitbit_heart(fitbit_heart: Array<FitbitHeartDetail>) {
        this._fitbit_heart = fitbit_heart;
    }

    @prop()
    get fitbit_sleep(): Array<FitbitSleepDetail> {
        return this._fitbit_sleep;
    }

    set fitbit_sleep(fitbit_sleep: Array<FitbitSleepDetail>) {
        this._fitbit_sleep = fitbit_sleep;
    }

    @prop()
    get sealPercentDetailList(): Array<SealPercentDetail> {
        return this._sealPercentDetailList;
    }
    set sealPercentDetailList(sealPercentDetailList: Array<SealPercentDetail>) {
        this._sealPercentDetailList = sealPercentDetailList;
    }

    @prop()
    get sealPercentDetail(): SealPercentDetail {
        return this._sealPercentDetail;
    }

    set sealPercentDetail(sealPercentDetail: SealPercentDetail) {
        this._sealPercentDetail = sealPercentDetail;
    }

    @prop()
    get sealPercentDetailComplianceList(): Array<SealPercentDetail> {
        return this._sealPercentDetailComplianceList;
    }
    set sealPercentDetailComplianceList(sealPercentDetailComplianceList: Array<SealPercentDetail>) {
        this._sealPercentDetailComplianceList = sealPercentDetailComplianceList;
    }

    @prop()
    get chartListLength(): ChartListLength {
        return this._chartListLength;
    }
    set chartListLength(chartListLength: ChartListLength) {
        this._chartListLength = chartListLength;
    }

}