import { Component, OnInit } from '@angular/core';
import { Injector } from '@angular/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user-service';
import { UserlistViewModel } from 'src/app/routes/userlist/userlist-view-model';
import { fromEvent, Subject, Observable } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import {Md5} from 'ts-md5/dist/md5';
import { PatientlistViewModel } from 'src/app/routes/patientlist/patientlist-view-model';
import { PatientdetailViewModel } from 'src/app/routes/patientlist/patientdetail/patientdetail-view-model';
import { SdkViewModel } from 'src/app/routes/pages/sdk/sdk-view-model';

import { Patient } from 'src/app/model/patient';
import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';
import { Data } from 'src/app/model/data';
import { WebDevice } from 'src/app/model/web-device';
import { SettingsService } from 'src/app/core/settings/settings.service';

declare var $: any;
@Component({
  selector: 'app-trend',
  templateUrl: './trend.component.html',
  styleUrls: ['./trend.component.scss']
})
export class TrendComponent implements OnInit {
  userFormGroup: FormGroup;
  router: Router;
  userService: UserService = this.injector.get(UserService);
  patient: Patient;
  patientlistViewModel: PatientlistViewModel = this.injector.get(PatientlistViewModel);
  patientdetailViewModel: PatientdetailViewModel = this.injector.get(PatientdetailViewModel);
  sdkViewModel: SdkViewModel = this.injector.get(SdkViewModel);
  
  loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
  public chooseDate$: Subject<any> = new Subject<any>();
  public chooseType$: Subject<any> = new Subject<any>();
  
  isNoData:boolean;
  serverURL:string;
  constructor(public settings: SettingsService,public injector: Injector, private rxfb: RxFormBuilder) {
    this.serverURL = this.settings.getAppSetting('serverURL');
    this.router = this.injector.get(Router);
    this.patient = this.sdkViewModel.selectPatient;
    this.patient.clinicalUserList =  this.loginViewModel.loginUser.clinicalUserList;
    
    this.isNoData = true;
    if(this.patient.trendDataList.length > 0){
      this.isNoData = false;
      this.patient.data = this.patient.trendDataList[0];
    }
    this.patient.datetime_type = 'day'

    this.userFormGroup = this.rxfb.formGroup(this.patient);
  }


  ngAfterViewInit(): void {
    const parent = this;
    const observerDate = {
      next(data) {
        parent.patient.data = data;
      }
    };
    const observerType = {
      next(trendDataList) {
        parent.patient.trendDataList = trendDataList;
        if( trendDataList.length > 0 ){
          parent.patient.data = trendDataList[0];
        }
        parent.userFormGroup.get('data').setValue(parent.patient.data);
      }
    };
    this.sdkViewModel.input.chooseDate = this.chooseDate$;
    this.sdkViewModel.input.chooseType = this.chooseType$;
    this.sdkViewModel.output.dateTrend.subscribe(observerDate);
    this.sdkViewModel.output.typeTrend.subscribe(observerType);

    if (this.sdkViewModel.setup_choose() === false) {
      throw new Error('example error message');
    }
    this.chooseType$.next(this.patient);
  }

  onDownload(){
    console.log("onDownload");
  }
  
  onUpdate(){
    this.chooseDate$.next(this.patient);
  }

  onChooseType(){
    console.log(this.patient.datetime_type);
    this.chooseType$.next(this.patient);
  }

  onCancel(){
    console.warn(this.userFormGroup.value);
    this.router.navigate(['patientlist']);
  }

  ngOnInit() {
  }

}

