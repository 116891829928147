
import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('FitbitSleepDetail')

export class FitbitSleepDetail extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('dateTime', String)
    private _dateTime: string;
    @JsonProperty('level', String)
    private _level: string;
    @JsonProperty('seconds', Number)
    private _seconds: number;

    @JsonProperty('datetime_time', String)
    private _datetime_time: string;
    @JsonProperty('value', Number)
    private _value: number;
    
    @prop()
    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }
    

    @prop()
    get dateTime(): string {
        return this._dateTime;
    }

    set dateTime(dateTime: string) {
        this._dateTime = dateTime;
    }

    @prop()
    get level(): string {
        return this._level;
    }

    set level(level: string) {
        this._level = level;
    }

    @prop()
    get seconds(): number {
        return this._seconds;
    }

    set seconds(seconds: number) {
        this._seconds = seconds;
    }

    @prop()
    get datetime_time(): string {
        return this._datetime_time;
    }
    set datetime_time(datetime_time: string) {
        this._datetime_time = datetime_time;
    }

    @prop()
    get value(): number {
        return this._value;
    }

    set value(value: number) {
        this._value = value;
    }
    

}
