import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import { SealPercentDetail } from 'src/app/model/seal-percent-detail';
import { PressureDetail } from 'src/app/model/pressure-detail';
import { Spo2Detail } from 'src/app/model/spo2-detail';
import { HeartrateDetail } from 'src/app/model/heartrate-detail';
import { OddurationDetail } from 'src/app/model/odduration-detail';

import { FitbitHeartDetail } from 'src/app/model/fitbit-heart-detail';
import { FitbitSleepDetail } from 'src/app/model/fitbit-sleep-detail';
import { ODdurationCountDetail } from 'src/app/model/odduration-count-detail';


import { PieDetail } from 'src/app/model/pie-detail';
import { Data } from 'src/app/model/data';
import { DeviceSetting } from 'src/app/model/device-setting';


import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('Report')
export class Report extends Base{
    @JsonProperty('pie_usagetime', [PieDetail])
    private _pie_usagetime: Array<PieDetail>;
    @JsonProperty('pie_usagetime_compliance', [PieDetail])
    private _pie_usagetime_compliance: Array<PieDetail>;
    @JsonProperty('pie_odi', [PieDetail])
    private _pie_odi: Array<PieDetail>;
    @JsonProperty('pie_seal_percent', [PieDetail])
    private _pie_seal_percent: Array<PieDetail>;
    @JsonProperty('pie_seal_percent_compliance', [PieDetail])
    private _pie_seal_percent_compliance: Array<PieDetail>;
    
    @JsonProperty('seal_percent_mean', Number)
    private _seal_percent_mean: number;
    @JsonProperty('seal_percent_median', Number)
    private _seal_percent_median: number;
    @JsonProperty('seal_percent_95th', Number)
    private _seal_percent_95th: number;
    @JsonProperty('seal_percent_max', Number)
    private _seal_percent_max: number;
    @JsonProperty('seal_percent_min', Number)
    private _seal_percent_min: number;

    @JsonProperty('seal_percent_compliance_mean', Number)
    private _seal_percent_compliance_mean: number;
    @JsonProperty('seal_percent_compliance_median', Number)
    private _seal_percent_compliance_median: number;
    @JsonProperty('seal_percent_compliance_95th', Number)
    private _seal_percent_compliance_95th: number;
    @JsonProperty('seal_percent_compliance_max', Number)
    private _seal_percent_compliance_max: number;
    @JsonProperty('seal_percent_compliance_min', Number)
    private _seal_percent_compliance_min: number;

    @JsonProperty('odi_mean', Number)
    private _odi_mean: number;
    @JsonProperty('odi_median', Number)
    private _odi_median: number;
    @JsonProperty('odi_95th', Number)
    private _odi_95th: number;
    @JsonProperty('odi_max', Number)
    private _odi_max: number;
    @JsonProperty('odi_min', Number)
    private _odi_min: number;

    @JsonProperty('usage_greater_equal', Number)
    private _usage_greater_equal: number;
    @JsonProperty('usage_less', Number)
    private _usage_less: number;
    @JsonProperty('usage_not_used', Number)
    private _usage_not_used: number;
    @JsonProperty('usage_total', Number)
    private _usage_total: number;
    @JsonProperty('usage_total_hours_used', String)
    private _usage_total_hours_used: string;
    @JsonProperty('usage_average_daily', String)
    private _usage_average_daily: string;


    @JsonProperty('usage_greater_equal_compliance', Number)
    private _usage_greater_equal_compliance: number;
    @JsonProperty('usage_less_compliance', Number)
    private _usage_less_compliance: number;
    @JsonProperty('usage_not_used_compliance', Number)
    private _usage_not_used_compliance: number;
    @JsonProperty('usage_total_compliance', Number)
    private _usage_total_compliance: number;
    @JsonProperty('usage_total_hours_used_compliance', String)
    private _usage_total_hours_used_compliance: string;
    @JsonProperty('usage_average_daily_compliance', String)
    private _usage_average_daily_compliance: string;

    @JsonProperty('deviceSetting', DeviceSetting)
    private _deviceSetting: DeviceSetting;
    
    @JsonProperty('date_range', String)
    private _date_range: string;

    @JsonProperty('device_used', [String])
    private _device_used: Array<String>;


    @JsonProperty('dataList', [Data])
    private _dataList: Array<Data>;

    @prop()
    get pie_usagetime(): Array<PieDetail> {
        return this._pie_usagetime;
    }
    set pie_usagetime(pie_usagetime: Array<PieDetail>) {
        this._pie_usagetime = pie_usagetime;
    }

    @prop()
    get pie_usagetime_compliance(): Array<PieDetail> {
        return this._pie_usagetime_compliance;
    }
    set pie_usagetime_compliance(pie_usagetime_compliance: Array<PieDetail>) {
        this._pie_usagetime_compliance = pie_usagetime_compliance;
    }

    @prop()
    get pie_odi(): Array<PieDetail> {
        return this._pie_odi;
    }
    set pie_odi(pie_odi: Array<PieDetail>) {
        this._pie_odi = pie_odi;
    }

    @prop()
    get pie_seal_percent(): Array<PieDetail> {
        return this._pie_seal_percent;
    }
    set pie_seal_percent(pie_seal_percent: Array<PieDetail>) {
        this._pie_seal_percent = pie_seal_percent;
    }

    @prop()
    get pie_seal_percent_compliance(): Array<PieDetail> {
        return this._pie_seal_percent_compliance;
    }
    set pie_seal_percent_compliance(pie_seal_percent_compliance: Array<PieDetail>) {
        this._pie_seal_percent_compliance = pie_seal_percent_compliance;
    }
    
    @prop()
    get seal_percent_mean(): number {
        return this._seal_percent_mean;
    }
    set seal_percent_mean(seal_percent_mean: number) {
        this._seal_percent_mean = seal_percent_mean;
    }
    @prop()
    get seal_percent_median(): number {
        return this._seal_percent_median;
    }
    set seal_percent_median(seal_percent_median: number) {
        this._seal_percent_median = seal_percent_median;
    }
    @prop()
    get seal_percent_95th(): number {
        return this._seal_percent_95th;
    }
    set seal_percent_95th(seal_percent_95th: number) {
        this._seal_percent_95th = seal_percent_95th;
    }
    @prop()
    get seal_percent_max(): number {
        return this._seal_percent_max;
    }
    set seal_percent_max(seal_percent_max: number) {
        this._seal_percent_max = seal_percent_max;
    }
    @prop()
    get seal_percent_min(): number {
        return this._seal_percent_min;
    }
    set seal_percent_min(seal_percent_min: number) {
        this._seal_percent_min = seal_percent_min;
    }

    @prop()
    get seal_percent_compliance_mean(): number {
        return this._seal_percent_compliance_mean;
    }
    set seal_percent_compliance_mean(seal_percent_compliance_mean: number) {
        this._seal_percent_compliance_mean = seal_percent_compliance_mean;
    }
    @prop()
    get seal_percent_compliance_median(): number {
        return this._seal_percent_compliance_median;
    }
    set seal_percent_compliance_median(seal_percent_compliance_median: number) {
        this._seal_percent_compliance_median = seal_percent_compliance_median;
    }
    @prop()
    get seal_percent_compliance_95th(): number {
        return this._seal_percent_compliance_95th;
    }
    set seal_percent_compliance_95th(seal_percent_compliance_95th: number) {
        this._seal_percent_compliance_95th = seal_percent_compliance_95th;
    }
    @prop()
    get seal_percent_compliance_max(): number {
        return this._seal_percent_compliance_max;
    }
    set seal_percent_compliance_max(seal_percent_compliance_max: number) {
        this._seal_percent_compliance_max = seal_percent_compliance_max;
    }
    @prop()
    get seal_percent_compliance_min(): number {
        return this._seal_percent_compliance_min;
    }
    set seal_percent_compliance_min(seal_percent_compliance_min: number) {
        this._seal_percent_compliance_min = seal_percent_compliance_min;
    }


    @prop()
    get odi_mean(): number {
        return this._odi_mean;
    }
    set odi_mean(odi_mean: number) {
        this._odi_mean = odi_mean;
    }
    @prop()
    get odi_median(): number {
        return this._odi_median;
    }
    set odi_median(odi_median: number) {
        this._odi_median = odi_median;
    }
    @prop()
    get odi_95th(): number {
        return this._odi_95th;
    }
    set odi_95th(odi_95th: number) {
        this._odi_95th = odi_95th;
    }
    @prop()
    get odi_max(): number {
        return this._odi_max;
    }
    set odi_max(odi_max: number) {
        this._odi_max = odi_max;
    }
    @prop()
    get odi_min(): number {
        return this._odi_min;
    }
    set odi_min(odi_min: number) {
        this._odi_min = odi_min;
    }



    @prop()
    get usage_greater_equal(): number {
        return this._usage_greater_equal;
    }
    set usage_greater_equal(usage_greater_equal: number) {
        this._usage_greater_equal = usage_greater_equal;
    }
    @prop()
    get usage_less(): number {
        return this._usage_less;
    }
    set usage_less(usage_less: number) {
        this._usage_less = usage_less;
    }
    @prop()
    get usage_not_used(): number {
        return this._usage_not_used;
    }
    set usage_not_used(usage_not_used: number) {
        this._usage_not_used = usage_not_used;
    }
    @prop()
    get usage_total(): number {
        return this._usage_total;
    }
    set usage_total(usage_total: number) {
        this._usage_total = usage_total;
    }
    @prop()
    get usage_total_hours_used(): string {
        return this._usage_total_hours_used;
    }
    set usage_total_hours_used(usage_total_hours_used: string) {
        this._usage_total_hours_used = usage_total_hours_used;
    }
    @prop()
    get usage_average_daily(): string {
        return this._usage_average_daily;
    }
    set usage_average_daily(usage_average_daily: string) {
        this._usage_average_daily = usage_average_daily;
    }
        


    @prop()
    get usage_greater_equal_compliance(): number {
        return this._usage_greater_equal_compliance;
    }
    set usage_greater_equal_compliance(usage_greater_equal_compliance: number) {
        this._usage_greater_equal_compliance = usage_greater_equal_compliance;
    }
    @prop()
    get usage_less_compliance(): number {
        return this._usage_less_compliance;
    }
    set usage_less_compliance(usage_less_compliance: number) {
        this._usage_less_compliance = usage_less_compliance;
    }
    @prop()
    get usage_not_used_compliance(): number {
        return this._usage_not_used_compliance;
    }
    set usage_not_used_compliance(usage_not_used_compliance: number) {
        this._usage_not_used_compliance = usage_not_used_compliance;
    }
    @prop()
    get usage_total_compliance(): number {
        return this._usage_total_compliance;
    }
    set usage_total_compliance(usage_total_compliance: number) {
        this._usage_total_compliance = usage_total_compliance;
    }
    @prop()
    get usage_total_hours_used_compliance(): string {
        return this._usage_total_hours_used_compliance;
    }
    set usage_total_hours_used_compliance(usage_total_hours_used_compliance: string) {
        this._usage_total_hours_used_compliance = usage_total_hours_used_compliance;
    }
    @prop()
    get usage_average_daily_compliance(): string {
        return this._usage_average_daily_compliance;
    }
    set usage_average_daily_compliance(usage_average_daily_compliance: string) {
        this._usage_average_daily_compliance = usage_average_daily_compliance;
    }
    
    @prop()
    get deviceSetting(): DeviceSetting {
        return this._deviceSetting;
    }
    set deviceSetting(deviceSetting: DeviceSetting) {
        this._deviceSetting = deviceSetting;
    }

    @prop()
    get date_range(): string {
        return this._date_range;
    }
    set date_range(date_range: string) {
        this._date_range = date_range;
    }

    @prop()
    get device_used(): Array<String> {
        return this._device_used;
    }
    set device_used(device_used: Array<String>) {
        this._device_used = device_used;
    }

    @prop()
    get dataList(): Array<Data> {
        return this._dataList;
    }
    set dataList(dataList: Array<Data>) {
        this._dataList = dataList;
    }        
    
}
