import { Injectable, InjectionToken } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { User } from 'src/app/model/user';
import { Patient } from 'src/app/model/patient';
import { UserWebResponse } from 'src/app/model/user-web-response';
import { DeviceSetting } from 'src/app/model/device-setting';
import { Data } from 'src/app/model/data';

@Injectable()
export abstract class PatientService {
    abstract getPatient(): any;
    abstract getPatientById(patient_id): Observable<any>;
    abstract getReport(patient): Observable<any>;
    abstract getClouddata(patient): Observable<any>;
    abstract getTrenddata(patient): Observable<any>;
    abstract getTongue(patient): Observable<any>;
    abstract getEcg(cloud_user_id,return_ecg_detail): Observable<any>;
    abstract getDeviceSetting(patient): Observable<any>;
    abstract getSDKPatientURL(query_url): Observable<any>;
    abstract getPatientListCount(): Observable<any>;
    abstract getPatientList(): Observable<any>;
    
    abstract getUserAPI(user): Observable<any>;
    abstract getTestUser(): Observable<any>;
    abstract addPatient(patient): Observable<any>;
    
    abstract getCloudUser(patient): Observable<any>;
    abstract getCloudUserBySerial(patient): Observable<any>;
    abstract getUser(patient): Observable<any>;
    abstract getChart(patient): Observable<any>;
    abstract editPatient(patient): Observable<any>;
    abstract editDeviceSetting(devicesetting): Observable<any>;
    abstract editMemonote(data): Observable<any>;
    abstract login(user): Observable<any>;
    abstract forgotpassword(user): Observable<any>;
    abstract logout(user): Observable<any>;
}