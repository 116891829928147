import { digit,minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import { LocationPhone } from 'src/app/model/location-phone';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('Location')
export class Location extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('organization_id', Number)
    private _organization_id: number;
    @JsonProperty('organization_name', String)
    private _organization_name: string;
    @JsonProperty('name', String)
    private _name: string;
    @JsonProperty('country', String)
    private _country: string;
    @JsonProperty('address1', String)
    private _address1: string;
    @JsonProperty('address2', String)
    private _address2: string;
    @JsonProperty('city', String)
    private _city: string;
    @JsonProperty('state', String)
    private _state: string;
    @JsonProperty('zip_code', String)
    private _zip_code: string;
    @JsonProperty('type', String)
    private _type: string;
    @JsonProperty('enable', Number)
    private _enable: number;
    @JsonProperty('locationPhoneList', [LocationPhone])
    private _locationPhoneList: Array<LocationPhone>;
    @JsonProperty('locationPhone', LocationPhone)
    private _locationPhone: LocationPhone;
    @JsonProperty('locationPhoneName', String)
    private _locationPhoneName: string;
    @JsonProperty('locationPhoneNumber', String)
    private _locationPhoneNumber: string;

    @prop()
    get id() {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }
    
    @prop()
    get organization_id(): number {
        return this._organization_id;
    }

    set organization_id(organization_id: number) {
        this._organization_id = organization_id;
    }

    @prop()
    get organization_name(): string {
        return this._organization_name;
    }

    set organization_name(organization_name: string) {
        this._organization_name = organization_name;
    }

    @prop()
    get name(): string {
        return this._name;
    }

    set name(name: string) {
        this._name = name;
    }

    @prop()
    get country(): string {
        return this._country;
    }

    set country(country: string) {
        this._country = country;
    }

    @prop()
    @digit()
    get address1(): string {
        return this._address1;
    }

    set address1(address1: string) {
        this._address1 = address1;
    }

    @prop()
    @digit()
    get address2(): string {
        return this._address2;
    }

    set address2(address2: string) {
        this._address2 = address2;
    }

    @prop()
    @digit()
    get city(): string {
        return this._city;
    }

    set city(city: string) {
        this._city = city;
    }

    @prop()
    @digit()
    get state(): string {
        return this._state;
    }

    set state(state: string) {
        this._state = state;
    }

    @prop()
    get zip_code(): string {
        return this._zip_code;
    }

    set zip_code(zip_code: string) {
        this._zip_code = zip_code;
    }

    @prop()
    get type(): string {
        return this._type;
    }

    set type(type: string) {
        this._type = type;
    }

    @prop()
    get enable(): number {
        return this._enable;
    }

    set enable(enable: number) {
        this._enable = enable;
    }

    @prop()
    get locationPhoneList(): Array<LocationPhone> {
        return this._locationPhoneList;
    }

    set locationPhoneList(locationPhoneList: Array<LocationPhone>) {
        this._locationPhoneList = locationPhoneList;
    }

    @prop()
    get locationPhone(): LocationPhone {
        return this._locationPhone;
    }

    set locationPhone(locationPhone: LocationPhone) {
        this._locationPhone = locationPhone;
    }

    @prop()
    get locationPhoneName(): string {
        return this._locationPhoneName;
    }

    set locationPhoneName(locationPhoneName: string) {
        this._locationPhoneName = locationPhoneName;
    }
    
    @prop()
    get locationPhoneNumber(): string {
        return this._locationPhoneNumber;
    }

    set locationPhoneNumber(locationPhoneNumber: string) {
        this._locationPhoneNumber = locationPhoneNumber;
    }


}
