import { maxNumber,minNumber,minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('GlobalDeviceMultiPatient')
export class GlobalDeviceMultiPatient extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('patient_id', Number)
    private _patient_id: number;
    @JsonProperty('cloud_user_id', Number)
    private _cloud_user_id: number;
    @JsonProperty('email', String)
    private _email: string;
    @JsonProperty('serial', String)
    private _serial: string;

    
    @prop()
    get id() {
        return this._id;
    }
    set id(id: number) {
        this._id = id;
    }

    @prop()
    get patient_id() {
        return this._patient_id;
    }
    set patient_id(patient_id: number) {
        this._patient_id = patient_id;
    }

    @prop()
    get cloud_user_id() {
        return this._cloud_user_id;
    }
    set cloud_user_id(cloud_user_id: number) {
        this._cloud_user_id = cloud_user_id;
    }

    @prop()
    get email() {
        return this._email;
    }
    set email(email: string) {
        this._email = email;
    }

    @prop()
    get serial() {
        return this._serial;
    }
    set serial(serial: string) {
        this._serial = serial;
    }
}
