import { Component, OnInit, Injector } from '@angular/core';

import { UserblockService } from './userblock.service';


import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user-service';
import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';
import * as CryptoJS from 'crypto-js';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    //user: any;

    userService: UserService = this.injector.get(UserService);
    user: User;
    loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
  
    constructor(public userblockService: UserblockService, public injector: Injector) {
        this.user =  this.loginViewModel.loginUser;
        if( this.user == undefined){
            try {
                let ciphertext = localStorage.getItem('loginUser');
                var bytes  = CryptoJS.AES.decrypt(ciphertext, 'somnicSecretKey');
                var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                this.user = new User(decryptedData);
            } catch (error) {
                console.error('Error getting data from localStorage', error);
            }
        }
        //this.user.picture = 'assets/img/user/01.jpg';
        // this.user = {
        //     picture: 'assets/img/user/01.jpg'
        // };
        this.userService.getPhoto().subscribe((data) => {
            console.log(data);
            if(data.size > 0){
                const blob = new Blob([data], { type: data.type });
                const url= URL.createObjectURL(blob);
                this.user.picture = url;
            }
            //this.user.picture = result;
        });
    }

    ngOnInit() {
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

}
