import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';

import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('ClinicalUser')
export class ClinicalUser extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('username', String)
    private _username: string;

    @prop()
    get id() {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }
    
    @prop()
    get username(): string {
        return this._username;
    }

    set username(username: string) {
        this._username = username;
    }

}

