import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('LocationPhone')
export class LocationPhone extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('location_id', String)
    private _location_id: string;
    @JsonProperty('name', String)
    private _name: string;
    @JsonProperty('number', String)
    private _number: string;

    @prop()
    get id() {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }
    
    @prop()
    get location_id(): string {
        return this._location_id;
    }

    set location_id(location_id: string) {
        this._location_id = location_id;
    }

    @prop()
    get name(): string {
        return this._name;
    }

    set name(name: string) {
        this._name = name;
    }

    @prop()
    get number(): string {
        return this._number;
    }

    set number(number: string) {
        this._number = number;
    }


}