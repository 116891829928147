
import { Injectable, InjectionToken } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { User } from 'src/app/model/user';
import { UserWebResponse } from 'src/app/model/user-web-response';
import { Patient } from 'src/app/model/patient';
import { DeviceSetting } from 'src/app/model/device-setting';
import { Data } from 'src/app/model/data';
@Injectable()
export abstract class GlobalDeviceDao {
    abstract getGlobalDeviceList(): Observable<any>;
    abstract getOtaList(): Observable<any>;
    abstract getApkList(): Observable<any>;
    abstract getOtaById(ota_upload_id): Observable<any>;
    abstract addOta(otaUpload): Observable<any>;
    abstract addApk(apkUpload): Observable<any>;
    abstract getImportPatientList(): Observable<any>;
    abstract getAllUserPatientList(): Observable<any>;
    abstract getVaUserPatientList(): Observable<any>;
    abstract importGlobalDevice(file): Observable<any>;
    abstract importImportPatient(file): Observable<any>;
    abstract getCloudDeviceHistoryList(): Observable<any>;
    abstract getDisablePressureSerialList(): Observable<any>;
    abstract addGlobalDevice(device): Observable<any>;
    abstract deleteGlobalDevice(devicelist): Observable<any>;
    abstract addCloudDeviceHistory(device): Observable<any>;
    abstract addDisablePressure(device): Observable<any>;
    abstract editCloudDeviceHistory(device): Observable<any>;
}
