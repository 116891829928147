import { Injectable, InjectionToken, Inject, Injector} from '@angular/core';
import { UserDao } from 'src/app/dao/user-dao';
import { User } from 'src/app/model/user';
import { UserWebResponse } from 'src/app/model/user-web-response';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { TableData } from 'src/app/routes/userlist/ng2-table-data';
import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';
import { SettingsService } from 'src/app/core/settings/settings.service';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const apiUrl = 'https://jsonplaceholder.typicode.com/posts/42';

@Injectable({
    providedIn: 'root'
})
export class UserDaoImpl implements UserDao {
    loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
    serverURL:string;
    router: Router;
    constructor(public settings: SettingsService,private injector: Injector,private http:HttpClient) {
        this.serverURL = this.settings.getAppSetting('serverURL');
        this.router = this.injector.get(Router);
    }
    getUser(): any {
        const u: User = new User();
        // u.name = '';
        // u.firstname = '';
        // u.lastname = 'Doe';
        // u.email = 'John@somnics.com';
        // u.picture = 'assets/img/user/10.jpg';
        // u.newpassword = 'Link';
        // u.role_id = 1;
        // u.access_all_location = 'All';
        // u.password = '111111';
        // u.confirmPassword = '111111';
        // u.title = 'Mr.';
        return u;
    }
    // getUser(): User {
    //     const u: User = new User();
    //     u.name = 'John Doe';
    //     u.firstname = 'John';
    //     u.lastname = 'Doe';
    //     u.email = 'John@somnics.com';
    //     u.picture = 'assets/img/user/10.jpg';
    //     u.newpassword = 'Link';
    //     u.role_id = 1;
    //     u.access_all_location = 'All';
    //     u.password = '111111';
    //     u.confirmPassword = '111111';
    //     u.title = 'Mr.';
    //     return u;
    // }

    getUserList(): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let url = this.serverURL+'userlist';
        if(this.loginViewModel.loginUser.role_id == 4){
            url = this.serverURL+'manageruserlist';
        }
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.data;
            }),
            catchError(this.handleError<string>('getUserList'))
        );
    }

    
    addUser(user): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'user';
        
        return this.http.post<UserWebResponse>(url, user.toJsonString(), httpOptions).pipe(
            map(response => {
                return new UserWebResponse(response);
                // var userWebResponse = new UserWebResponse(response);
                // return userWebResponse.success;
            }),
            catchError(this.handleError<string>('addUser'))
        );
    }

    editUser(user): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'user';
        return this.http.put<boolean>(url, user.toJsonString(), httpOptions).pipe(
            map(response => {
                return new UserWebResponse(response);
                // var userWebResponse = new UserWebResponse(response);
                // return userWebResponse.success;
            }),
            catchError(this.handleError<string>('editUser'))
        );
    }

    editProfile(user): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'profile';
        return this.http.put<boolean>(url, user.toJsonString(), httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.success;
            }),
            catchError(this.handleError<string>('editProfile'))
        );
    }

    editPassword(user): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'password';
        return this.http.put<boolean>(url, user.toJsonString(), httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.success;
            }),
            catchError(this.handleError<string>('editPassword'))
        );
    }


    getUserAPI(user): Observable<any> {
        console.log(user);
        const u: User = new User();
        u.name = 'Buffer';
        u.picture = 'assets/img/user/10.jpg';
        return of(u);
        //return this.http.get<User>(apiUrl);
    }

    getPhoto(): Observable<any> {
        if( this.loginViewModel.loginUser == undefined){
            try {
                let ciphertext = localStorage.getItem('loginUser');
                var bytes  = CryptoJS.AES.decrypt(ciphertext, 'somnicSecretKey');
                var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                this.loginViewModel.loginUser = new User(decryptedData);
            } catch (error) {
                console.error('Error getting data from localStorage', error);
                localStorage.clear();//發生錯誤,清空localStorage
                this.router.navigate(['login']);//發生錯誤,導向404頁
            }
        }
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        
        //const url = this.serverURL+'photo';
        //同一瀏覽器,不同使用者登出登入,瀏覽器從快取中取資料,造成兩人的大頭照都相同
        //在URL加上參數,讓每次url是不同的,讓瀏覽器不會從快取中取資料
        var arg = new Date().getTime();
        const url = this.serverURL+'photo?no_cache='+arg;

        return this.http.get(url, { headers: httpOptions.headers,responseType: 'blob' })
        .pipe(
          map(data => {
            console.log(data);
            return data;
            // const blob = new Blob([data], { type: data.type });
            // const url= URL.createObjectURL(blob);
            // return url;
          }));
    }

    editPhoto(file): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        const httpOptions = {
            headers: new HttpHeaders({ 'Authorization': bearer_token })
        };
        const url = this.serverURL+'photo';
        let formData = new FormData();
        formData.append("file", file);
        return this.http.put<boolean>(url, formData, httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.success;
            }),
            catchError(this.handleError<string>('editPhoto'))
        );
    }

    getTestUser(): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'user?user_id=1';
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                console.log(userWebResponse.data);
                return new User(userWebResponse.data);
            }),
            catchError(this.handleError<string>('getTestUser'))
        );
    }

    login(user): Observable<any> {
        const url = this.serverURL+'login?media_id=browser';
        return this.http.post<UserWebResponse>(url, user.toJsonString(), httpOptions).pipe(
            map(response => {
                return new UserWebResponse(response);
                // var userWebResponse = new UserWebResponse(response);
                // console.log(userWebResponse.data);
                // return new User(userWebResponse.data);
            }),
            catchError(this.handleError<string>('login'))
        );
    }

    getLoginUser(user_id): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'loginuserr?user_id='+user_id;
        return this.http.get<UserWebResponse>(url,httpOptions).pipe(
            map(response => {
                return new UserWebResponse(response);
            }),
            catchError(this.handleError<string>('getLoginUser'))
        );
    }

    getMapList(filtering,map_access_token): Observable<any> {
        const bearer_token = 'Bearer '+ map_access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'maplist?filtering='+filtering;
        return this.http.get<UserWebResponse>(url,httpOptions).pipe(
            map(response => {
                return new UserWebResponse(response);
            }),
            catchError(this.handleError<string>('getMapList'))
        );
    }

    forgotpassword(user): Observable<any> {
        const url = this.serverURL+'resetpassword';
        return this.http.post<UserWebResponse>(url, user.toJsonString(), httpOptions).pipe(
            map(response => {
                return new UserWebResponse(response);
            }),
            catchError(this.handleError<string>('forgotpassword'))
        );
    }

    logout(user): Observable<any> {
        // console.log(user);
        // return of(true);
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'logout';
        return this.http.put<boolean>(url, user.toJsonString(), httpOptions).pipe(
            map(response => {
                return true;
            }),
            catchError(this.handleError<string>('logout'))
        );
    }

    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          localStorage.clear();//發生錯誤,清空localStorage
          this.router.navigate(['login']);//發生錯誤,導向404頁
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
      
          // TODO: better job of transforming error for user consumption
          console.log(`${operation} failed: ${error.message}`);
      
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }

}

// export const UserServiceProvider = new InjectionToken(
//     'UserServiceProvider',
//     { providedIn: 'root', factory: () => new UserServiceImpl(@Inject(ENV) private environment) }
// );
