import { Injectable, InjectionToken, Inject, Injector} from '@angular/core';
import { PatientDao } from 'src/app/dao/patient-dao';
import { UserDao } from 'src/app/dao/user-dao';
import { User } from 'src/app/model/user';
import { UserWebResponse } from 'src/app/model/user-web-response';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { TableData } from 'src/app/routes/userlist/ng2-table-data';
import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';
import { Patient } from 'src/app/model/patient';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { DeviceSetting } from 'src/app/model/device-setting';
import { Data } from 'src/app/model/data';
import { Tongue } from 'src/app/model/tongue';
import { Report } from 'src/app/model/report';
import { Router } from '@angular/router';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const apiUrl = 'https://jsonplaceholder.typicode.com/posts/42';

@Injectable({
    providedIn: 'root'
})
export class PatientDaoImpl implements PatientDao {
    loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
    serverURL:string;
    router: Router;
    constructor(public settings: SettingsService,private injector: Injector,private http:HttpClient) {
        this.serverURL = this.settings.getAppSetting('serverURL');
        this.router = this.injector.get(Router);
    }

    getPatient(): any {
        const p: Patient = new Patient();
        // p.id = 0;
        // p.user_id = 0;
        // p.username = 'Rola Lee';
        // p.firstname = 'Rola';
        // p.lastname = 'Lee';
        // p.title = 'Mr.';
        // p.birthday = '2000-03-02';
        // p.location_id = 0;
        // p.create_user_id = 1;
        // p.organization_id = 1;
        // p.use_last_per = 70;
        // p.last_upload = 'last_upload';
        // p.medical_number = 'S0002';
        // p.email = 'email@email';
        // p.cloud_user_id = 0;
        // p.enable = true;
        // p.createdate = '2020-03-02';
        return p;
    }

    getPatientById(patient_id): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'patient?patient_id='+patient_id;
        return this.http.get<Patient>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                console.log(userWebResponse.data);
                return new Patient(userWebResponse.data);
            }),
            catchError(this.handleError<string>('getPatientById'))
        );
    }

    getReport(patient): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let cloud_user_id = patient.cloud_user_id;
        
        var from_date = patient.from_date;
        var to_date = patient.to_date;

        const url = this.serverURL+'report?cloud_user_id='+cloud_user_id+'&from_date='+from_date+'&to_date='+to_date;
        return this.http.get<Patient>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return new Report(userWebResponse.data);
            }),
            catchError(this.handleError<string>('getReport'))
        );
    }

    getClouddata(patient): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let cloud_user_id = patient.cloud_user_id;
        let data_id = patient.data.id;
        
        //console.log(patient.webDevice);
        let serial = ''
        if(patient.webDevice.serial.indexOf('R') == 0){//iNAP R系列
            serial = patient.webDevice.serial;
        }
        const url = this.serverURL+'clouddata?cloud_user_id='+cloud_user_id+'&data_id='+data_id+'&serial='+serial;
        return this.http.get<Patient>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return new Data(userWebResponse.data);
            }),
            catchError(this.handleError<string>('getClouddata'))
        );
    }

    getTrenddata(patient): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let cloud_user_id = patient.cloud_user_id;
        let datetime_type = patient.datetime_type.toLowerCase();
        const url = this.serverURL+'record/'+datetime_type+'?cloud_user_id='+cloud_user_id+'&order_asc=0';
        return this.http.get<Patient>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.data;
            }),
            catchError(this.handleError<string>('getTrenddata'))
        );
    }

    getTongue(patient): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let cloud_user_id = patient.cloud_user_id;
        let tongue_date = patient.tongueDate;
        const url = this.serverURL+'tongue/'+tongue_date+'?cloud_user_id='+cloud_user_id;
        return this.http.get<Tongue>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return new Tongue(userWebResponse.data);
            }),
            catchError(this.handleError<string>('getTongue'))
        );
    }

    getEcg(patient,return_ecg_detail): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let cloud_user_id = patient.cloud_user_id;

        //query_date 格式從2023/06/21改成20230621
        //split() 將字串轉成陣列 
        var str_arr = patient.ecgDate.split("/")
        //join() 將陣列合併字串
        var arr_str = str_arr.join('')
        let query_date = arr_str;
        
        const url = this.serverURL+'ecg?cloud_user_id='+cloud_user_id+'&query_date='+query_date+'&return_ecg_detail='+return_ecg_detail;
        return this.http.get<any>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.data;
            }),
            catchError(this.handleError<string>('getEcg'))
        );
    }

    getDeviceSetting(patient): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let cloud_user_id = patient.cloud_user_id;
        const url = this.serverURL+'devicesettingfromcloud?cloud_user_id='+cloud_user_id;
        return this.http.get<DeviceSetting>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                console.log(userWebResponse.data);
                return new DeviceSetting(userWebResponse.data);
            }),
            catchError(this.handleError<string>('getDeviceSetting'))
        );
    }

    getSDKPatientURL(query_url): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'sdk/patient/'+query_url;
        return this.http.get<DeviceSetting>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return new DeviceSetting(userWebResponse);
            }),
            catchError(this.handleError<string>('getSDKPatientURL'))
        );
    }
    
    getPatientListCount(): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let url = this.serverURL+'patientlistcount';
        if(this.loginViewModel.loginUser.role_id == 4){
            url = this.serverURL+'managerpatientlist';
        }
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.data;
            }),
            catchError(this.handleError<string>('getPatientListCount'))
        );
    }

    getPatientList(): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        //console.log(offset,limit);
        let url = this.serverURL+'patientlist';
        if(this.loginViewModel.loginUser.role_id == 4){
            url = this.serverURL+'managerpatientlist';
        }
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.data;
            }),
            catchError(this.handleError<string>('getPatientList'))
        );
    }

    
    
    addPatient(patient): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'patient';
        return this.http.post<boolean>(url, patient.toJsonString(), httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.success;
            }),
            catchError(this.handleError<string>('addPatient'))
        );
    }

    

    getCloudUser(patient): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let email = patient.email
        let isAdd = patient.isAdd
        const url = this.serverURL+'patientfromcloud?email='+email+'&isAdd='+isAdd;
        return this.http.get<UserWebResponse>(url,httpOptions).pipe(
            map(response => {
                return new UserWebResponse(response);
                // var userWebResponse = new UserWebResponse(response);
                // console.log(userWebResponse.data);
                // return new Patient(userWebResponse.data);
            }),
            catchError(this.handleError<string>('getCloudUser'))
        );
    }
    getCloudUserBySerial(patient): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let serial = patient.serial
        const url = this.serverURL+'patientfromcloudserial?serial='+serial;
        return this.http.get<UserWebResponse>(url,httpOptions).pipe(
            map(response => {
                return new UserWebResponse(response);
            }),
            catchError(this.handleError<string>('getCloudUserBySerial'))
        );
    }

    getUser(patient): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let user_id = patient.user_id
        const url = this.serverURL+'user?user_id='+user_id;
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                console.log(userWebResponse.data);
                return new User(userWebResponse.data);
            }),
            catchError(this.handleError<string>('getUser'))
        );
    }

    getChart(patient): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let cloud_user_id = patient.cloud_user_id
        const url = this.serverURL+'record/day?cloud_user_id='+cloud_user_id;
        
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                console.log(userWebResponse.data);
                //return new User(userWebResponse.data);
                return userWebResponse.data;
            }),
            catchError(this.handleError<string>('getChart'))
        );
    }

    editPatient(patient): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'patient';
        return this.http.put<boolean>(url, patient.toJsonString(), httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.success;
            }),
            catchError(this.handleError<string>('editPatient'))
        );
    }

    editDeviceSetting(devicesetting): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'devicesettingfromcloud';
        return this.http.put<boolean>(url, devicesetting.toJsonString(), httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.success;
            }),
            catchError(this.handleError<string>('editDeviceSetting'))
        );
    }

    editMemonote(data): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'memonote';
        return this.http.put<boolean>(url, data.toJsonString(), httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.success;
            }),
            catchError(this.handleError<string>('editMemonote'))
        );
    }


    getUserAPI(user): Observable<any> {
        console.log(user);
        const u: User = new User();
        u.name = 'Buffer';
        u.picture = 'assets/img/user/10.jpg';
        return of(u);
        //return this.http.get<User>(apiUrl);
    }

    getTestUser(): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'user?user_id=1';
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                console.log(userWebResponse.data);
                return new User(userWebResponse.data);
            }),
            catchError(this.handleError<string>('getTestUser'))
        );
    }

    login(user): Observable<any> {
        const url = this.serverURL+'login?media_id=browser';
        return this.http.post<UserWebResponse>(url, user.toJsonString(), httpOptions).pipe(
            map(response => {
                return new UserWebResponse(response);
                // var userWebResponse = new UserWebResponse(response);
                // console.log(userWebResponse.data);
                // return new User(userWebResponse.data);
            }),
            catchError(this.handleError<string>('login'))
        );
    }

    forgotpassword(user): Observable<any> {
        const url = this.serverURL+'resetpassword';
        return this.http.post<UserWebResponse>(url, user.toJsonString(), httpOptions).pipe(
            map(response => {
                return new UserWebResponse(response);
            }),
            catchError(this.handleError<string>('forgotpassword'))
        );
    }

    logout(user): Observable<any> {
        // console.log(user);
        // return of(true);
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'logout';
        return this.http.put<boolean>(url, user.toJsonString(), httpOptions).pipe(
            map(response => {
                return true;
            }),
            catchError(this.handleError<string>('logout'))
        );
    }

    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          localStorage.clear();//發生錯誤,清空localStorage
          this.router.navigate(['login']);//發生錯誤,導向404頁
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
      
          // TODO: better job of transforming error for user consumption
          console.log(`${operation} failed: ${error.message}`);
      
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }

}

// export const UserServiceProvider = new InjectionToken(
//     'UserServiceProvider',
//     { providedIn: 'root', factory: () => new UserServiceImpl(@Inject(ENV) private environment) }
// );
