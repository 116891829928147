import { maxNumber,minNumber,minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';
import { RemoteTherapy } from 'src/app/model/remote-therapy';
import { WebDevice } from 'src/app/model/web-device';


@JsonObject('DeviceSetting')
export class DeviceSetting extends Base{
    @JsonProperty('cloud_user_id', Number)
    private _cloud_user_id: number;
    @JsonProperty('edit_result', String)
    private _edit_result: string;
    @JsonProperty('therapy', Number)
    private _therapy: number;
    @JsonProperty('intensity_mode', Number)
    private _intensity_mode: number;
    @JsonProperty('relief', Number)
    private _relief: number;
    @JsonProperty('erase', Number)
    private _erase: number;
    @JsonProperty('paring_all', Number)
    private _paring_all: number;
    @JsonProperty('timesync', Number)
    private _timesync: number;
    @JsonProperty('compliance_without_clear_data', Number)
    private _compliance_without_clear_data: number;
    
    @JsonProperty('min', Number)
    private _min: number;
    @JsonProperty('max', Number)
    private _max: number;
    //美國遠端調壓range 20231129
    @JsonProperty('current_cmH2O', Number)
    private _current_cmH2O: number;
    @JsonProperty('min_cmH2O', Number)
    private _min_cmH2O: number;
    @JsonProperty('max_cmH2O', Number)
    private _max_cmH2O: number;
    @JsonProperty('min_limit', Number)
    private _min_limit: number;
    @JsonProperty('max_limit', Number)
    private _max_limit: number;
    @JsonProperty('min_max_range', Number)
    private _min_max_range: number;

    @JsonProperty('tongue', Number)
    private _tongue: number;

    @JsonProperty('max_pressure', Number)
    private _max_pressure: number;
    //是否可調壓0的權限 20230322
    @JsonProperty('min_pressure', Number) 
    private _min_pressure: number;

    //遠端調壓
    @JsonProperty('user_id', Number)
    private _user_id: number;
    @JsonProperty('patient_id', Number)
    private _patient_id: number;
    @JsonProperty('serial', String)
    private _serial: string;
    @JsonProperty('serial_inapmute', String)
    private _serial_inapmute: string;
    @JsonProperty('current', Number)
    private _current: number;
    @JsonProperty('intensity', Number)
    private _intensity: number;
    @JsonProperty('web_timezone', String)
    private _web_timezone: string;
    @JsonProperty('web_time_original', Number)
    private _web_time_original: number;
    @JsonProperty('email', String)
    private _email: string;

    @JsonProperty('remoteTherapyList', [RemoteTherapy])
    private _remoteTherapyList: Array<RemoteTherapy>;
    
    @JsonProperty('webDeviceList', [WebDevice])
    private _webDeviceList: Array<WebDevice>;

    @JsonProperty('inapmuteDeviceList', [WebDevice])
    private _inapmuteDeviceList: Array<WebDevice>;

    @JsonProperty('do_remote_therapy', Number)
    private _do_remote_therapy: number;

    @prop()
    get cloud_user_id() {
        return this._cloud_user_id;
    }

    set cloud_user_id(cloud_user_id: number) {
        this._cloud_user_id = cloud_user_id;
    }

    @prop()
    get edit_result(): string {
        return this._edit_result;
    }
    set edit_result(edit_result: string) {
        this._edit_result = edit_result;
    }

    @prop()
    get therapy() {
        return this._therapy;
    }

    set therapy(therapy: number) {
        this._therapy = therapy;
    }
    @prop()
    get intensity_mode() {
        return this._intensity_mode;
    }
    set intensity_mode(intensity_mode: number) {
        this._intensity_mode = intensity_mode;
    }
    @prop()
    get relief() {
        return this._relief;
    }
    set relief(relief: number) {
        this._relief = relief;
    }
    @prop()
    get erase() {
        return this._erase;
    }

    set erase(erase: number) {
        this._erase = erase;
    }
    @prop()
    get paring_all() {
        return this._paring_all;
    }

    set paring_all(paring_all: number) {
        this._paring_all = paring_all;
    }
    @prop()
    get timesync() {
        return this._timesync;
    }

    set timesync(timesync: number) {
        this._timesync = timesync;
    }
    @prop()
    get compliance_without_clear_data() {
        return this._compliance_without_clear_data;
    }

    set compliance_without_clear_data(compliance_without_clear_data: number) {
        this._compliance_without_clear_data = compliance_without_clear_data;
    }

    
    @prop()
    get min() {
        return this._min;
    }
    set min(min: number) {
        this._min = min;
    }

    @prop()
    get max() {
        return this._max;
    }
    set max(max: number) {
        this._max = max;
    }
    //美國遠端調壓range 20231129
    @prop()
    get current_cmH2O() {
        return this._current_cmH2O;
    }
    set current_cmH2O(current_cmH2O: number) {
        this._current_cmH2O = current_cmH2O;
    }
    @prop()
    get min_cmH2O() {
        return this._min_cmH2O;
    }
    set min_cmH2O(min_cmH2O: number) {
        this._min_cmH2O = min_cmH2O;
    }
    @prop()
    get max_cmH2O() {
        return this._max_cmH2O;
    }
    set max_cmH2O(max_cmH2O: number) {
        this._max_cmH2O = max_cmH2O;
    }
    @prop()
    get min_limit() {
        return this._min_limit;
    }
    set min_limit(min_limit: number) {
        this._min_limit = min_limit;
    }
    @prop()
    get max_limit() {
        return this._max_limit;
    }
    set max_limit(max_limit: number) {
        this._max_limit = max_limit;
    }
    @prop()
    get min_max_range() {
        return this._min_max_range;
    }
    set min_max_range(min_max_range: number) {
        this._min_max_range = min_max_range;
    }

    @prop()
    get tongue() {
        return this._tongue;
    }
    set tongue(tongue: number) {
        this._tongue = tongue;
    }

    @prop()
    get max_pressure() {
        return this._max_pressure;
    }
    set max_pressure(max_pressure: number) {
        this._max_pressure = max_pressure;
    }

    //是否可調壓0的權限 20230322
    @prop()
    get min_pressure() {
        return this._min_pressure;
    }
    set min_pressure(min_pressure: number) {
        this._min_pressure = min_pressure;
    }

    //遠端調壓
    @prop()
    get user_id() {
        return this._user_id;
    }
    set user_id(user_id: number) {
        this._user_id = user_id;
    }

    @prop()
    get patient_id() {
        return this._patient_id;
    }
    set patient_id(patient_id: number) {
        this._patient_id = patient_id;
    }

    @prop()
    get serial(): string {
        return this._serial;
    }
    set serial(serial: string) {
        this._serial = serial;
    }

    @prop()
    get serial_inapmute(): string {
        return this._serial_inapmute;
    }
    set serial_inapmute(serial_inapmute: string) {
        this._serial_inapmute = serial_inapmute;
    }

    @prop()
    get current() {
        return this._current;
    }
    set current(current: number) {
        this._current = current;
    }

    @prop()
    get intensity() {
        return this._intensity;
    }
    set intensity(intensity: number) {
        this._intensity = intensity;
    }

    @prop()
    get web_timezone(): string {
        return this._web_timezone;
    }
    set web_timezone(web_timezone: string) {
        this._web_timezone = web_timezone;
    }

    @prop()
    get web_time_original() {
        return this._web_time_original;
    }
    set web_time_original(web_time_original: number) {
        this._web_time_original = web_time_original;
    }

    @prop()
    get email(): string {
        return this._email;
    }
    set email(email: string) {
        this._email = email;
    }

    @prop()
    get remoteTherapyList(): Array<RemoteTherapy> {
        return this._remoteTherapyList;
    }
    set remoteTherapyList(remoteTherapyList: Array<RemoteTherapy>) {
        this._remoteTherapyList = remoteTherapyList;
    }

    @prop()
    get webDeviceList(): Array<WebDevice> {
        return this._webDeviceList;
    }
    set webDeviceList(webDeviceList: Array<WebDevice>) {
        this._webDeviceList = webDeviceList;
    }

    @prop()
    get inapmuteDeviceList(): Array<WebDevice> {
        return this._inapmuteDeviceList;
    }
    set inapmuteDeviceList(inapmuteDeviceList: Array<WebDevice>) {
        this._inapmuteDeviceList = inapmuteDeviceList;
    }

    @prop()
    get do_remote_therapy(): number {
        return this._do_remote_therapy;
    }
    set do_remote_therapy(do_remote_therapy: number) {
        this._do_remote_therapy = do_remote_therapy;
    }

}
