
import { Injectable, InjectionToken, Inject, Injector} from '@angular/core';
import { OrganizationDao } from 'src/app/dao/organization-dao';
import { User } from 'src/app/model/user';
import { UserWebResponse } from 'src/app/model/user-web-response';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { TableData } from 'src/app/routes/userlist/ng2-table-data';
import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';
import { Location } from 'src/app/model/location';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { Organization } from 'src/app/model/organization';
import { Router } from '@angular/router';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const apiUrl = 'https://jsonplaceholder.typicode.com/posts/42';

@Injectable({
    providedIn: 'root'
})
export class OrganizationDaoImpl implements OrganizationDao {
    loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
    serverURL:string;
    router: Router;
    constructor(public settings: SettingsService,private injector: Injector,private http:HttpClient) {
        this.serverURL = this.settings.getAppSetting('serverURL');
        this.router = this.injector.get(Router);
    }

    getOrganization(): any {
        const o: Organization = new Organization();      
        return o;
    }

    getOrganizationList(): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        let url = this.serverURL+'organizationlist';
        return this.http.get<Organization>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.data;
            }),
            catchError(this.handleError<string>('getOrganizationList'))
        );
    }

    
    addOrganization(organization): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'organization';
        return this.http.post<boolean>(url, organization.toJsonString(), httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.success;
            }),
            catchError(this.handleError<string>('addOrganization'))
        );
    }

    editOrganization(organization): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'organization';
        return this.http.put<boolean>(url, organization.toJsonString(), httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                return userWebResponse.success;
            }),
            catchError(this.handleError<string>('editOrganization'))
        );
    }


    getUserAPI(user): Observable<any> {
        console.log(user);
        const u: User = new User();
        u.name = 'Buffer';
        u.picture = 'assets/img/user/10.jpg';
        return of(u);
        //return this.http.get<User>(apiUrl);
    }

    getTestUser(): Observable<any> {
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'user?user_id=1';
        return this.http.get<User>(url,httpOptions).pipe(
            map(response => {
                var userWebResponse = new UserWebResponse(response);
                console.log(userWebResponse.data);
                return new User(userWebResponse.data);
            }),
            catchError(this.handleError<string>('getTestUser'))
        );
    }

    login(user): Observable<any> {
        const url = this.serverURL+'login?media_id=browser';
        return this.http.post<UserWebResponse>(url, user.toJsonString(), httpOptions).pipe(
            map(response => {
                return new UserWebResponse(response);
                // var userWebResponse = new UserWebResponse(response);
                // console.log(userWebResponse.data);
                // return new User(userWebResponse.data);
            }),
            catchError(this.handleError<string>('login'))
        );
    }

    forgotpassword(user): Observable<any> {
        const url = this.serverURL+'resetpassword';
        return this.http.post<UserWebResponse>(url, user.toJsonString(), httpOptions).pipe(
            map(response => {
                return new UserWebResponse(response);
            }),
            catchError(this.handleError<string>('forgotpassword'))
        );
    }

    logout(user): Observable<any> {
        // console.log(user);
        // return of(true);
        const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
        httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        const url = this.serverURL+'logout';
        return this.http.put<boolean>(url, user.toJsonString(), httpOptions).pipe(
            map(response => {
                return true;
            }),
            catchError(this.handleError<string>('logout'))
        );
    }

    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          localStorage.clear();//發生錯誤,清空localStorage
          this.router.navigate(['login']);//發生錯誤,導向404頁
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
      
          // TODO: better job of transforming error for user consumption
          console.log(`${operation} failed: ${error.message}`);
      
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }

}

// export const UserServiceProvider = new InjectionToken(
//     'UserServiceProvider',
//     { providedIn: 'root', factory: () => new UserServiceImpl(@Inject(ENV) private environment) }
// );
