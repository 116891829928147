import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
@Injectable()
export class TranslatorService {

    private defaultLanguage: string = 'en';

    private availablelangs = [
        { code: 'en', text: 'English' },
        { code: 'cn', text: '简体中文' }
        //因為翻譯因素先關閉繁體中文
        //{ code: 'zh', text: '繁體中文' }
    ];

    constructor(public translate: TranslateService) {

        if (!translate.getDefaultLang())
            translate.setDefaultLang(this.defaultLanguage);

        this.useLanguage();

    }

    useLanguage(lang: string = null) {
        this.translate.use(lang || this.translate.getDefaultLang());

        //<- 多國語言重新整裡 20230810
        if(lang != null){
            try {
                var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(lang), 'somnicSecretKey').toString();
                  localStorage.setItem('lang', ciphertext);
            } catch (error) {
                console.error('Error saving to localStorage', error);
            }
        }
        //->
    }

    getAvailableLanguages() {
        return this.availablelangs;
    }

}
