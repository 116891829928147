import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('RingSleepStageDetail')
export class RingSleepStageDetail extends Base{
    @JsonProperty('datetime_time', String)
    private _datetime_time: string;
    @JsonProperty('date_time', Number)
    private _date_time: number;
    @JsonProperty('stage', Number)
    private _stage: number;
    
    

    @prop()
    get datetime_time(): string {
        return this._datetime_time;
    }
    set datetime_time(datetime_time: string) {
        this._datetime_time = datetime_time;
    }

    @prop()
    get date_time(): number {
        return this._date_time;
    }
    set date_time(date_time: number) {
        this._date_time = date_time;
    }

    @prop()
    get stage(): number {
        return this._stage;
    }

    set stage(stage: number) {
        this._stage = stage;
    }
    

}
