import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RecoverComponent } from './recover/recover.component';
import { LockComponent } from './lock/lock.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';
import { SdkComponent } from './sdk/sdk.component';
import { ChartComponent } from './sdk/chart/chart.component';
import { ComplianceChartComponent } from './sdk/chart/compliance-chart/compliance-chart.component';
import { TrendComponent } from './sdk/trend/trend.component';
import { ReportComponent } from './sdk/report/report.component';
import { FitbitHeartComponent } from './sdk/chart/fitbit-heart/fitbit-heart.component';
import { FitbitSleepComponent } from './sdk/chart/fitbit-sleep/fitbit-sleep.component';
import { HeartrateChartComponent } from './sdk/chart/heartrate-chart/heartrate-chart.component';
import { OdiChartComponent } from './sdk/chart/odi-chart/odi-chart.component';
import { OdiDurationComponent } from './sdk/chart/odi-duration/odi-duration.component';
import { OdiDurationCountComponent } from './sdk/chart/odi-duration-count/odi-duration-count.component';
import { PressureChartComponent } from './sdk/chart/pressure-chart/pressure-chart.component';
import { PumpingtTimeComponent } from './sdk/chart/pumpingt-time/pumpingt-time.component';
import { RingSleepStageComponent } from './sdk/chart/ring-sleep-stage/ring-sleep-stage.component';
import { Spo2ChartComponent } from './sdk/chart/spo2-chart/spo2-chart.component';
import { TreatmentChartComponent } from './sdk/chart/treatment-chart/treatment-chart.component';
import { ComplianceTrendComponent } from './sdk/trend/compliance-trend/compliance-trend.component';
import { OdiTrendComponent } from './sdk/trend/odi-trend/odi-trend.component';
import { TreatmentTrendComponent } from './sdk/trend/treatment-trend/treatment-trend.component';
import { OdiReportComponent } from './sdk/report/odi-report/odi-report.component';
import { TreatmentComplianceReportComponent } from './sdk/report/treatment-compliance-report/treatment-compliance-report.component';
import { TreatmentReportComponent } from './sdk/report/treatment-report/treatment-report.component';
import { UsageCompliancePieComponent } from './sdk/report/usage-compliance-pie/usage-compliance-pie.component';
import { UsageComplianceReportComponent } from './sdk/report/usage-compliance-report/usage-compliance-report.component';
import { UsagePieComponent } from './sdk/report/usage-pie/usage-pie.component';
import { UsageReportComponent } from './sdk/report/usage-report/usage-report.component';

/* Use this routes definition in case you want to make them lazy-loaded */
/*const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },
];*/
const routes: Routes = [
    { path: 'patient/:url', component: SdkComponent,
        children: 
        [{ path: 'chart', component: ChartComponent },
        { path: 'trend', component: TrendComponent },
        { path: 'report', component: ReportComponent }]
    }
  ];
@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(routes)
    ],
    declarations: [
        LoginComponent,
        RegisterComponent,
        RecoverComponent,
        LockComponent,
        MaintenanceComponent,
        Error404Component,
        Error500Component,
        SdkComponent,
        ChartComponent,
        ComplianceChartComponent,
        TrendComponent,
        ReportComponent,
        FitbitHeartComponent,
        FitbitSleepComponent,
        HeartrateChartComponent,
        OdiChartComponent,
        OdiDurationComponent,
        OdiDurationCountComponent,
        PressureChartComponent,
        PumpingtTimeComponent,
        RingSleepStageComponent,
        Spo2ChartComponent,
        TreatmentChartComponent,
        ComplianceTrendComponent,
        OdiTrendComponent,
        TreatmentTrendComponent,
        OdiReportComponent,
        TreatmentComplianceReportComponent,
        TreatmentReportComponent,
        UsageCompliancePieComponent,
        UsageComplianceReportComponent,
        UsagePieComponent,
        UsageReportComponent
    ],
    exports: [
        RouterModule,
        LoginComponent,
        RegisterComponent,
        RecoverComponent,
        LockComponent,
        MaintenanceComponent,
        Error404Component,
        Error500Component
    ]
})
export class PagesModule { }
