import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { UserService } from 'src/app/service/user-service';
import { UserServiceImpl } from 'src/app/service/impl/user-service-impl';
import { environment } from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { UserDao } from 'src/app/dao/user-dao';
import { UserDaoImpl } from 'src/app/dao/impl/user-dao-impl';

import { PatientService } from 'src/app/service/patient-service';
import { PatientServiceImpl } from 'src/app/service/impl/patient-service-impl';
import { PatientDao } from 'src/app/dao/patient-dao';
import { PatientDaoImpl } from 'src/app/dao/impl/patient-dao-impl';

import { GlobalDeviceService } from 'src/app/service/global-device-service';
import { GlobalDeviceServiceImpl } from 'src/app/service/impl/global-device-service-impl';
import { GlobalDeviceDao } from 'src/app/dao/global-device-dao';
import { GlobalDeviceDaoImpl } from 'src/app/dao/impl/global-device-dao-impl';



import { LocationService } from 'src/app/service/location-service';
import { LocationServiceImpl } from 'src/app/service/impl/location-service-impl';
import { LocationDao } from 'src/app/dao/location-dao';
import { LocationDaoImpl } from 'src/app/dao/impl/location-dao-impl';

import { OrganizationService } from 'src/app/service/organization-service';
import { OrganizationServiceImpl } from 'src/app/service/impl/organization-service-impl';
import { OrganizationDao } from 'src/app/dao/organization-dao';
import { OrganizationDaoImpl } from 'src/app/dao/impl/organization-dao-impl';


import { IMqttMessage, MqttService } from "ngx-mqtt";
import { IMqttServiceOptions, MqttModule } from "ngx-mqtt";
import { environment as env } from '../environments/environment';
const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
    connectOnCreate: false,
    hostname: env.mqtt.server,
    port: env.mqtt.port,
    protocol: (env.mqtt.protocol === "wss") ? "wss" : "ws",
    path: '/mqtt'
};

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
        HttpClientModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        ReactiveFormsModule, 
        RxReactiveFormsModule,
        CoreModule,
        LayoutModule,
        SharedModule.forRoot(),
        RoutesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        LoggerModule.forRoot({
            // level: !environment.production ? NgxLoggerLevel.LOG : NgxLoggerLevel.OFF,
            // serverLogLevel
            level: NgxLoggerLevel.TRACE,
            serverLogLevel: NgxLoggerLevel.OFF,
            disableConsoleLogging: false
        })
    ],
    providers: [
        MqttService,//20220822
        { provide: UserService, useClass: UserServiceImpl },
        { provide: UserDao, useClass: UserDaoImpl },
        { provide: PatientService, useClass: PatientServiceImpl },
        { provide: PatientDao, useClass: PatientDaoImpl },
        { provide: GlobalDeviceService, useClass: GlobalDeviceServiceImpl },
        { provide: GlobalDeviceDao, useClass: GlobalDeviceDaoImpl },
        { provide: LocationService, useClass: LocationServiceImpl },
        { provide: LocationDao, useClass: LocationDaoImpl },
        { provide: OrganizationService, useClass: OrganizationServiceImpl },
        { provide: OrganizationDao, useClass: OrganizationDaoImpl }
      ],
    bootstrap: [AppComponent]
})
export class AppModule { }
