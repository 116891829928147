import { maxNumber,minNumber,minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert, Any} from 'json2typescript';

@JsonObject('OtaDownload')
export class OtaDownload extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('cloud_user_id', Number)
    private _cloud_user_id: number;
    @JsonProperty('serial', String)
    private _serial: string;
    @JsonProperty('ota_upload_id', Number)
    private _ota_upload_id: number;
    @JsonProperty('version', String)
    private _version: string;
    @JsonProperty('filename', String)
    private _filename: string;
    @JsonProperty('size', Number)
    private _size: number;
    @JsonProperty('latitude', Number)
    private _latitude: number;
    @JsonProperty('longitude', Number)
    private _longitude: number;
    @JsonProperty('ip', String)
    private _ip: string;
    @JsonProperty('createdate', String)
    private _createdate: string;
    
    @prop()
    get id() {
        return this._id;
    }
    set id(id: number) {
        this._id = id;
    }

    @prop()
    get cloud_user_id() {
        return this._cloud_user_id;
    }
    set cloud_user_id(cloud_user_id: number) {
        this._cloud_user_id = cloud_user_id;
    }

    @prop()
    get serial() {
        return this._serial;
    }
    set serial(serial: string) {
        this._serial = serial;
    }

    @prop()
    get ota_upload_id() {
        return this._ota_upload_id;
    }
    set ota_upload_id(ota_upload_id: number) {
        this._ota_upload_id = ota_upload_id;
    }

    @prop()
    get version() {
        return this._version;
    }
    set version(version: string) {
        this._version = version;
    }


    @prop()
    get filename() {
        return this._filename;
    }
    set filename(filename: string) {
        this._filename = filename;
    }

    @prop()
    get size() {
        return this._size;
    }
    set size(size: number) {
        this._size = size;
    }

    @prop()
    get latitude() {
        return this._latitude;
    }
    set latitude(latitude: number) {
        this._latitude = latitude;
    }

    @prop()
    get longitude() {
        return this._longitude;
    }
    set longitude(longitude: number) {
        this._longitude = longitude;
    }

    @prop()
    get ip() {
        return this._ip;
    }
    set ip(ip: string) {
        this._ip = ip;
    }

    @prop()
    get createdate() {
        return this._createdate;
    }
    set createdate(createdate: string) {
        this._createdate = createdate;
    }
    
}
