import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('PatientDetail')
export class PatientDetail extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('patient_id', Number)
    private _patient_id: number;
    @JsonProperty('name', String)
    private _name: string;
    @JsonProperty('description', String)
    private _description: string;

    @prop()
    get id() {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }
    
    @prop()
    get patient_id(): number {
        return this._patient_id;
    }

    set patient_id(patient_id: number) {
        this._patient_id = patient_id;
    }

    @prop()
    get name(): string {
        return this._name;
    }

    set name(name: string) {
        this._name = name;
    }

    @prop()
    get description(): string {
        return this._description;
    }

    set description(description: string) {
        this._description = description;
    }


}