import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';


import { Router } from '@angular/router';
import { Injector } from '@angular/core';
import { fromEvent, Subject, Observable } from 'rxjs';
import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user-service';



@Component({
    selector: 'app-recover',
    templateUrl: './recover.component.html',
    styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

    valForm: FormGroup;
    router: Router;

    userService: UserService = this.injector.get(UserService);
    user: User;
    loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
    public forgotpassword$: Subject<any> = new Subject<any>();
    constructor(public settings: SettingsService, fb: FormBuilder, private rxfb: RxFormBuilder, public injector: Injector) {
        // this.valForm = fb.group({
        //     'email': [null, Validators.compose([Validators.required, CustomValidators.email])]
        // });
        this.router = this.injector.get(Router);
        this.user = this.userService.getUser();
        this.valForm = this.rxfb.formGroup(this.user);
    }

    submitForm($ev, value: any) {
        // $ev.preventDefault();
        // for (let c in this.valForm.controls) {
        //     this.valForm.controls[c].markAsTouched();
        // }
        if (this.valForm.valid) {
            console.log('Valid!');
            console.log(value);
            this.forgotpassword$.next(this.user);
        }
    }

    ngAfterViewInit(): void {
        const parent = this;
        const observer = {
          next(result) {
            console.log('=======>' + result);
          }
        };
        this.loginViewModel.input.forgotpassword = this.forgotpassword$;
        this.loginViewModel.output.userSubject.subscribe(observer);
    
        if (this.loginViewModel.setup() === false) {
          throw new Error('example error message');
        }
        
    }

    ngOnInit() {
    }

}
