import { compare,minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import { Token } from 'src/app/model/token';
import { Location } from 'src/app/model/location';
import { UserIdentification } from 'src/app/model/user-identification';
import { ContactDetail } from 'src/app/model/contact-detail';
import { ClinicalUser } from 'src/app/model/clinical-user';

import { WebDevice } from 'src/app/model/web-device';
import { ContactDetailPatient } from 'src/app/model/contact-detail-patient';
import { PatientAddress } from 'src/app/model/patient-address';
import { PatientDetail } from 'src/app/model/patient-detail';
import { Organization } from 'src/app/model/organization';

import { Patient } from 'src/app/model/patient';


import { LocationPhone } from 'src/app/model/location-phone';
import { Permission } from 'src/app/model/permission';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('User')
export class User extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('token', Token)
    private _token: Token;
    @JsonProperty('permissionList', [Permission])
    private _permissionList: Array<Permission>;
    @JsonProperty('permission', Permission)
    private _permission: Permission;
    @JsonProperty('name', String)
    private _name: string;
    @JsonProperty('username', String)
    private _username: string;
    @JsonProperty('username_edit', String)
    private _username_edit: string;
    @JsonProperty('firstname', String)
    private _firstname: string;
    @JsonProperty('lastname', String)
    private _lastname: string;
    @JsonProperty('role_name', String)
    private _role_name: string;
    @JsonProperty('status', String)
    private _status: string;
    @JsonProperty('role_id', Number)
    private _role_id: number;
    @JsonProperty('title', String)
    private _title: string;
    @JsonProperty('newpassword', String)
    private _newpassword: string;
    @JsonProperty('manuallypassword', String)
    private _manuallypassword: string;
    @JsonProperty('manuallypassword_md5', String)
    private _manuallypassword_md5: string;
    @JsonProperty('clinicalUserList', [ClinicalUser])
    private _clinicalUserList: Array<ClinicalUser>;
    @JsonProperty('access_all_location', String)
    private _access_all_location: string;

    @JsonProperty('availablelocation', [Location])
    private _availablelocation: Array<Location>;
    @JsonProperty('selectlocation', [Location])
    private _selectlocation: Array<Location>;
    @JsonProperty('unselectlocation', [Location])
    private _unselectlocation: Array<Location>;
    @JsonProperty('selectOne', [Location])
    private _selectOne: Array<Location>;
    @JsonProperty('unselectOne', [Location])
    private _unselectOne: Array<Location>;


    @JsonProperty('userIdentificationList', [UserIdentification])
    private _userIdentificationList: Array<UserIdentification>;
    @JsonProperty('userIdentification', UserIdentification)
    private _userIdentification: UserIdentification;
    @JsonProperty('userIdentificationName', String)
    private _userIdentificationName: string;
    @JsonProperty('userIdentificationDescription', String)
    private _userIdentificationDescription: string;


    @JsonProperty('contactDetailList', [ContactDetail])
    private _contactDetailList: Array<ContactDetail>;
    @JsonProperty('contactDetail', ContactDetail)
    private _contactDetail: ContactDetail;
    @JsonProperty('contactDetailName', String)
    private _contactDetailName: string;
    @JsonProperty('contactDetailDescription', String)
    private _contactDetailDescription: string;

    @JsonProperty('locationPhoneList', [LocationPhone])
    private _locationPhoneList: Array<LocationPhone>;
    @JsonProperty('locationPhone', LocationPhone)
    private _locationPhone: LocationPhone;
    @JsonProperty('locationPhoneName', String)
    private _locationPhoneName: string;
    @JsonProperty('locationPhoneNumber', String)
    private _locationPhoneNumber: string;

    @JsonProperty('webDeviceList', [WebDevice])
    private _webDeviceList: Array<WebDevice>;
    @JsonProperty('webDevice', WebDevice)
    private _webDevice: WebDevice;
    @JsonProperty('webDeviceType', String)
    private _webDeviceType: string;
    @JsonProperty('webDeviceSerial', String)
    private _webDeviceSerial: string;

    @JsonProperty('contactDetailPatientList', [ContactDetailPatient])
    private _contactDetailPatientList: Array<ContactDetailPatient>;
    @JsonProperty('contactDetailPatient', ContactDetailPatient)
    private _contactDetailPatient: ContactDetailPatient;
    @JsonProperty('contactDetailPatientName', String)
    private _contactDetailPatientName: string;
    @JsonProperty('contactDetailPatientDescription', String)
    private _contactDetailPatientDescription: string;

    @JsonProperty('patientList', [Patient])
    private _patientList: Array<Patient>;
    @JsonProperty('patientcount', Number)
    private _patientcount: number;

    @JsonProperty('patientAddressList', [PatientAddress])
    private _patientAddressList: Array<PatientAddress>;
    @JsonProperty('patientAddress', PatientAddress)
    private _patientAddress: PatientAddress;
    @JsonProperty('patientAddressName', String)
    private _patientAddressName: string;
    @JsonProperty('patientAddressDescription', String)
    private _patientAddressDescription: string;

    @JsonProperty('patientDetailList', [PatientDetail])
    private _patientDetailList: Array<PatientDetail>;
    @JsonProperty('patientDetail', PatientDetail)
    private _patientDetail: PatientDetail;
    @JsonProperty('patientDetailName', String)
    private _patientDetailName: string;
    @JsonProperty('patientDetailDescription', String)
    private _patientDetailDescription: string;

    @JsonProperty('organization', Organization)
    private _organization: Organization;
    @JsonProperty('organization_id', Number)
    private _organization_id: number;

    @JsonProperty('organization_name', String)
    private _organization_name: string;

    @JsonProperty('create_user_id', Number)
    private _create_user_id: number;

    @JsonProperty('assign_user_id', Number)
    private _assign_user_id: number;

    @JsonProperty('identification', String)
    private _identification: string;
    @JsonProperty('contactdetails', String)
    private _contactdetails: string;
    @JsonProperty('tel', String)
    private _tel: string;
    @JsonProperty('address', String)
    private _address: string;
    @JsonProperty('email', String)
    private _email: string;
    @JsonProperty('password', String)
    private _password: string;
    @JsonProperty('confirmPassword', String)
    private _confirmPassword: string;
    @JsonProperty('picture', String)
    private _picture: string;
    @JsonProperty('max_pressure', Number)
    private _max_pressure: number;
    @JsonProperty('min_pressure', Number) //是否可調壓0的權限 20230321
    private _min_pressure: number;
    @JsonProperty('tongue', Number)
    private _tongue: number;
    @JsonProperty('enable', Number)
    private _enable: number;
    @JsonProperty('device_setting', Number)
    private _device_setting: number;
    @JsonProperty('remote_therapy', Number)
    private _remote_therapy: number;
    @JsonProperty('serial_add', Number)
    private _serial_add: number;
    @JsonProperty('import_patient', Number)
    private _import_patient: number;
    @JsonProperty('alluserpatientlist', Number)
    private _alluserpatientlist: number;
    @JsonProperty('vauserpatientlist', Number)//20240219 VA 美國退伍軍人購機方案
    private _vauserpatientlist: number;
    @JsonProperty('add_cloud_device_history', Number)
    private _add_cloud_device_history: number;
    @JsonProperty('chart_detail', Number)
    private _chart_detail: number;
    @JsonProperty('edit_user_email', Number)
    private _edit_user_email: number;
    @JsonProperty('ota', Number)
    private _ota: number;
    @JsonProperty('apk', Number)
    private _apk: number;
    @JsonProperty('mqtt', Number)
    private _mqtt: number;
    //20240307 美國不想看到chart tab ,設定0關閉
    @JsonProperty('chart_tab', Number)
    private _chart_tab: number;
    //20240314 新增disable pressure的序號介面
    @JsonProperty('disable_pressure_serial_ui', Number)
    private _disable_pressure_serial_ui: number;
    //20240820
    @JsonProperty('download_us_zoho', Number)
    private _download_us_zoho: number;
    @JsonProperty('finereport', Number)
    private _finereport: number;


    @prop()
    get id() {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }
    @prop()
    get token(): Token {
        return this._token;
    }

    set token(token: Token) {
        this._token = token;
    }

    @prop()
    get permissionList(): Array<Permission> {
        return this._permissionList;
    }

    set permissionList(permissionList: Array<Permission>) {
        this._permissionList = permissionList;
    }

    @prop()
    get permission(): Permission {
        return this._permission;
    }

    set permission(permission: Permission) {
        this._permission = permission;
    }

    @prop()
    get name(): string {
        return this._name;
    }

    set name(name: string) {
        this._name = name;
    }

    @prop()
    get username(): string {
        return this._username;
    }

    set username(username: string) {
        this._username = username;
    }

    @prop()
    get username_edit(): string {
        return this._username_edit;
    }

    set username_edit(username_edit: string) {
        this._username_edit = username_edit;
    }

    @prop()
    get firstname(): string {
        return this._firstname;
    }

    set firstname(firstname: string) {
        this._firstname = firstname;
    }

    @prop()
    get lastname(): string {
        return this._lastname;
    }

    set lastname(lastname: string) {
        this._lastname = lastname;
    }

    @prop()
    get role_name(): string {
        return this._role_name;
    }

    set role_name(role_name: string) {
        this._role_name = role_name;
    }

    @prop()
    get status(): string {
        return this._status;
    }

    set status(status: string) {
        this._status = status;
    }

    @prop()
    get role_id(): number {
        return this._role_id;
    }

    set role_id(role_id: number) {
        this._role_id = role_id;
    }

    @prop()
    get title(): string {
        return this._title;
    }

    set title(title: string) {
        this._title = title;
    }

    @prop()
    get newpassword(): string {
        return this._newpassword;
    }

    set newpassword(newpassword: string) {
        this._newpassword = newpassword;
    }

    @prop()
    @password({validation:{minLength: 6} })
    get manuallypassword(): string {
        return this._manuallypassword;
    }

    set manuallypassword(manuallypassword: string) {
        this._manuallypassword = manuallypassword;
    }

    @prop()
    get manuallypassword_md5(): string {
        return this._manuallypassword_md5;
    }

    set manuallypassword_md5(manuallypassword_md5: string) {
        this._manuallypassword_md5 = manuallypassword_md5;
    }


    @prop()
    get clinicalUserList(): Array<ClinicalUser> {
        return this._clinicalUserList;
    }
    set clinicalUserList(clinicalUserList: Array<ClinicalUser>) {
        this._clinicalUserList = clinicalUserList;
    }

    @prop()
    get access_all_location(): string {
        return this._access_all_location;
    }

    set access_all_location(access_all_location: string) {
        this._access_all_location = access_all_location;
    }

    @prop()
    get availablelocation(): Array<Location> {
        return this._availablelocation;
    }

    set availablelocation(availablelocation: Array<Location>) {
        this._availablelocation = availablelocation;
    }

    @prop()
    get selectlocation(): Array<Location> {
        return this._selectlocation;
    }

    set selectlocation(selectlocation: Array<Location>) {
        this._selectlocation = selectlocation;
    }

    @prop()
    get unselectlocation(): Array<Location> {
        return this._unselectlocation;
    }

    set unselectlocation(unselectlocation: Array<Location>) {
        this._unselectlocation = unselectlocation;
    }

    @prop()
    get selectOne(): Array<Location> {
        return this._selectOne;
    }

    set selectOne(selectOne: Array<Location>) {
        this._selectOne = selectOne;
    }

    @prop()
    get unselectOne(): Array<Location> {
        return this._unselectOne;
    }

    set unselectOne(unselectOne: Array<Location>) {
        this._unselectOne = unselectOne;
    }

    @prop()
    get userIdentificationList(): Array<UserIdentification> {
        return this._userIdentificationList;
    }

    set userIdentificationList(userIdentificationList: Array<UserIdentification>) {
        this._userIdentificationList = userIdentificationList;
    }

    @prop()
    get userIdentification(): UserIdentification {
        return this._userIdentification;
    }

    set userIdentification(userIdentification: UserIdentification) {
        this._userIdentification = userIdentification;
    }

    @prop()
    get userIdentificationName(): string {
        return this._userIdentificationName;
    }

    set userIdentificationName(userIdentificationName: string) {
        this._userIdentificationName = userIdentificationName;
    }
    
    @prop()
    get userIdentificationDescription(): string {
        return this._userIdentificationDescription;
    }

    set userIdentificationDescription(userIdentificationDescription: string) {
        this._userIdentificationDescription = userIdentificationDescription;
    }

    @prop()
    get contactDetailList(): Array<ContactDetail> {
        return this._contactDetailList;
    }

    set contactDetailList(contactDetailList: Array<ContactDetail>) {
        this._contactDetailList = contactDetailList;
    }

    @prop()
    get contactDetail(): ContactDetail {
        return this._contactDetail;
    }

    set contactDetail(contactDetail: ContactDetail) {
        this._contactDetail = contactDetail;
    }

    @prop()
    get contactDetailName(): string {
        return this._contactDetailName;
    }

    set contactDetailName(contactDetailName: string) {
        this._contactDetailName = contactDetailName;
    }
    
    @prop()
    get contactDetailDescription(): string {
        return this._contactDetailDescription;
    }

    set contactDetailDescription(contactDetailDescription: string) {
        this._contactDetailDescription = contactDetailDescription;
    }

    @prop()
    get webDeviceList(): Array<WebDevice> {
        return this._webDeviceList;
    }

    set webDeviceList(webDeviceList: Array<WebDevice>) {
        this._webDeviceList = webDeviceList;
    }

    @prop()
    get webDevice(): WebDevice {
        return this._webDevice;
    }

    set webDevice(webDevice: WebDevice) {
        this._webDevice = webDevice;
    }

    @prop()
    get webDeviceType(): string {
        return this._webDeviceType;
    }

    set webDeviceType(webDeviceType: string) {
        this._webDeviceType = webDeviceType;
    }
    
    @prop()
    get webDeviceSerial(): string {
        return this._webDeviceSerial;
    }

    set webDeviceSerial(webDeviceSerial: string) {
        this._webDeviceSerial = webDeviceSerial;
    }


    @prop()
    get contactDetailPatientList(): Array<ContactDetailPatient> {
        return this._contactDetailPatientList;
    }
    set contactDetailPatientList(contactDetailPatientList: Array<ContactDetailPatient>) {
        this._contactDetailPatientList = contactDetailPatientList;
    }

    @prop()
    get patientList(): Array<Patient> {
        return this._patientList;
    }
    set patientList(patientList: Array<Patient>) {
        this._patientList = patientList;
    }
    @prop()
    get patientcount(): number {
        return this._patientcount;
    }
    set patientcount(patientcount: number) {
        this._patientcount = patientcount;
    }

    @prop()
    get contactDetailPatient(): ContactDetailPatient {
        return this._contactDetailPatient;
    }

    set contactDetailPatient(contactDetailPatient: ContactDetailPatient) {
        this._contactDetailPatient = contactDetailPatient;
    }

    @prop()
    get contactDetailPatientName(): string {
        return this._contactDetailPatientName;
    }

    set contactDetailPatientName(contactDetailPatientName: string) {
        this._contactDetailPatientName = contactDetailPatientName;
    }
    
    @prop()
    get contactDetailPatientDescription(): string {
        return this._contactDetailPatientDescription;
    }

    set contactDetailPatientDescription(contactDetailPatientDescription: string) {
        this._contactDetailPatientDescription = contactDetailPatientDescription;
    }

    @prop()
    get patientAddressList(): Array<PatientAddress> {
        return this._patientAddressList;
    }

    set patientAddressList(patientAddressList: Array<PatientAddress>) {
        this._patientAddressList = patientAddressList;
    }

    @prop()
    get patientAddress(): PatientAddress {
        return this._patientAddress;
    }

    set patientAddress(patientAddress: PatientAddress) {
        this._patientAddress = patientAddress;
    }

    @prop()
    get patientAddressName(): string {
        return this._patientAddressName;
    }

    set patientAddressName(patientAddressName: string) {
        this._patientAddressName = patientAddressName;
    }
    
    @prop()
    get patientAddressDescription(): string {
        return this._patientAddressDescription;
    }

    set patientAddressDescription(patientAddressDescription: string) {
        this._patientAddressDescription = patientAddressDescription;
    }

    @prop()
    get patientDetailList(): Array<PatientDetail> {
        return this._patientDetailList;
    }

    set patientDetailList(patientDetailList: Array<PatientDetail>) {
        this._patientDetailList = patientDetailList;
    }

    @prop()
    get patientDetail(): PatientDetail {
        return this._patientDetail;
    }

    set patientDetail(patientDetail: PatientDetail) {
        this._patientDetail = patientDetail;
    }

    @prop()
    get patientDetailName(): string {
        return this._patientDetailName;
    }

    set patientDetailName(patientDetailName: string) {
        this._patientDetailName = patientDetailName;
    }
    
    @prop()
    get patientDetailDescription(): string {
        return this._patientDetailDescription;
    }

    set patientDetailDescription(patientDetailDescription: string) {
        this._patientDetailDescription = patientDetailDescription;
    }

    @prop()
    get locationPhoneList(): Array<LocationPhone> {
        return this._locationPhoneList;
    }

    set locationPhoneList(locationPhoneList: Array<LocationPhone>) {
        this._locationPhoneList = locationPhoneList;
    }

    @prop()
    get locationPhone(): LocationPhone {
        return this._locationPhone;
    }

    set locationPhone(locationPhone: LocationPhone) {
        this._locationPhone = locationPhone;
    }

    @prop()
    get locationPhoneName(): string {
        return this._locationPhoneName;
    }

    set locationPhoneName(locationPhoneName: string) {
        this._locationPhoneName = locationPhoneName;
    }
    
    @prop()
    get locationPhoneNumber(): string {
        return this._locationPhoneNumber;
    }

    set locationPhoneNumber(locationPhoneNumber: string) {
        this._locationPhoneNumber = locationPhoneNumber;
    }

    @prop()
    get organization(): Organization {
        return this._organization;
    }

    set organization(organization: Organization) {
        this._organization = organization;
    }

    @prop()
    get organization_id(): number {
        return this._organization_id;
    }

    set organization_id(organization_id: number) {
        this._organization_id = organization_id;
    }

    @prop()
    get organization_name(): string {
        return this._organization_name;
    }

    set organization_name(organization_name: string) {
        this._organization_name = organization_name;
    }

    @prop()
    get create_user_id(): number {
        return this._create_user_id;
    }
    set create_user_id(create_user_id: number) {
        this._create_user_id = create_user_id;
    }

    @prop()
    get assign_user_id(): number {
        return this._assign_user_id;
    }
    set assign_user_id(assign_user_id: number) {
        this._assign_user_id = assign_user_id;
    }

    @prop()
    get identification(): string {
        return this._identification;
    }

    set identification(identification: string) {
        this._identification = identification;
    }

    @prop()
    get contactdetails(): string {
        return this._contactdetails;
    }

    set contactdetails(contactdetails: string) {
        this._contactdetails = contactdetails;
    }

    @prop()
    get picture(): string {
        return this._picture;
    }

    set picture(picture: string) {
        this._picture = picture;
    }

    @prop()
    get max_pressure() {
        return this._max_pressure;
    }
    set max_pressure(max_pressure: number) {
        this._max_pressure = max_pressure;
    }

    //是否可調壓0的權限 20230321
    @prop()
    get min_pressure() {
        return this._min_pressure;
    }
    set min_pressure(min_pressure: number) {
        this._min_pressure = min_pressure;
    }

    @prop()
    get tongue() {
        return this._tongue;
    }
    set tongue(tongue: number) {
        this._tongue = tongue;
    }

    @prop()
    get enable() {
        return this._enable;
    }
    set enable(enable: number) {
        this._enable = enable;
    }

    @prop()
    get device_setting() {
        return this._device_setting;
    }
    set device_setting(device_setting: number) {
        this._device_setting = device_setting;
    }

    @prop()
    get remote_therapy() {
        return this._remote_therapy;
    }
    set remote_therapy(remote_therapy: number) {
        this._remote_therapy = remote_therapy;
    }

    @prop()
    get serial_add() {
        return this._serial_add;
    }
    set serial_add(serial_add: number) {
        this._serial_add = serial_add;
    }

    @prop()
    get import_patient() {
        return this._import_patient;
    }
    set import_patient(import_patient: number) {
        this._import_patient = import_patient;
    }

    @prop()
    get alluserpatientlist() {
        return this._alluserpatientlist;
    }
    set alluserpatientlist(alluserpatientlist: number) {
        this._alluserpatientlist = alluserpatientlist;
    }

    @prop()
    get vauserpatientlist() {
        return this._vauserpatientlist;
    }
    set vauserpatientlist(vauserpatientlist: number) {
        this._vauserpatientlist = vauserpatientlist;
    }

    @prop()
    get add_cloud_device_history() {
        return this._add_cloud_device_history;
    }
    set add_cloud_device_history(add_cloud_device_history: number) {
        this._add_cloud_device_history = add_cloud_device_history;
    }

    @prop()
    get ota() {
        return this._ota;
    }
    set ota(ota: number) {
        this._ota = ota;
    }

    @prop()
    get apk() {
        return this._apk;
    }
    set apk(apk: number) {
        this._apk = apk;
    }

    @prop()
    get mqtt() {
        return this._mqtt;
    }
    set mqtt(mqtt: number) {
        this._mqtt = mqtt;
    }

    //20240307 美國不想看到chart tab ,設定0關閉
    @prop()
    get chart_tab() {
        return this._chart_tab;
    }
    set chart_tab(chart_tab: number) {
        this._chart_tab = chart_tab;
    }

     //20240314 新增disable pressure的序號介面
    @prop()
    get disable_pressure_serial_ui() {
        return this._disable_pressure_serial_ui;
    }
    set disable_pressure_serial_ui(disable_pressure_serial_ui: number) {
        this._disable_pressure_serial_ui = disable_pressure_serial_ui;
    }

    //20240820
    @prop()
    get download_us_zoho() {
        return this._download_us_zoho;
    }
    set download_us_zoho(download_us_zoho: number) {
        this._download_us_zoho = download_us_zoho;
    }
    
    @prop()
    get finereport() {
        return this._finereport;
    }
    set finereport(finereport: number) {
        this._finereport = finereport;
    }

    @prop()
    get chart_detail() {
        return this._chart_detail;
    }
    set chart_detail(chart_detail: number) {
        this._chart_detail = chart_detail;
    }

    @prop()
    get edit_user_email() {
        return this._edit_user_email;
    }
    set edit_user_email(edit_user_email: number) {
        this._edit_user_email = edit_user_email;
    }

    set tel(tel: string) {
        this._tel = tel;
    }

    @prop()
    get tel(): string {
        return this._tel;
    }

    set address(address: string) {
        this._address = address;
    }

    @prop()
    get address(): string {
        return this._address;
    }

    @prop()
    @email()
    get email(): string {
        return this._email;
    }
    set email(email: string) {
        this._email = email;
    }

    @prop()
    @password({validation:{minLength: 6} })
    get password(): string {
        return this._password;
    }
    set password(password: string) {
        this._password = password;
    }

    @prop()
    @compare({fieldName:'password'})
    get confirmPassword(): string {
        return this._confirmPassword;
    }
    set confirmPassword(confirmPassword: string) {
        this._confirmPassword = confirmPassword;
    }


}
