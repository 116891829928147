import { email } from '@rxweb/reactive-form-validators';
import { DeviceSetting } from 'src/app/model/device-setting';
import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
import d3_save_pdf from 'd3-save-pdf';
import { jsPDF } from "jspdf";

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injector } from '@angular/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user-service';
import { UserlistViewModel } from 'src/app/routes/userlist/userlist-view-model';
import { fromEvent, Subject, Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import {Md5} from 'ts-md5/dist/md5';
import { PatientlistViewModel } from 'src/app/routes/patientlist/patientlist-view-model';
import { PatientdetailViewModel } from 'src/app/routes/patientlist/patientdetail/patientdetail-view-model';
import { SdkViewModel } from 'src/app/routes/pages/sdk/sdk-view-model';

import { Patient } from 'src/app/model/patient';
import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';
import { Data } from 'src/app/model/data';
import { WebDevice } from 'src/app/model/web-device';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { Report } from 'src/app/model/report';
import { stringify } from '@angular/compiler/src/util';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

declare var $: any;
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  userFormGroup: FormGroup;
  router: Router;
  userService: UserService = this.injector.get(UserService);
  patient: Patient;
  patientlistViewModel: PatientlistViewModel = this.injector.get(PatientlistViewModel);
  patientdetailViewModel: PatientdetailViewModel = this.injector.get(PatientdetailViewModel);
  sdkViewModel: SdkViewModel = this.injector.get(SdkViewModel);
  
  loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
  public report$: Subject<any> = new Subject<any>();
  public downloadReportImage$: Subject<any> = new Subject<any>();
  
  
  isNoData:boolean;
  isDataReady:boolean = false;
  finereport_msg:string = '';
  serverURL:string;
  usedayList: Date[];
  private imgSomnicsLogoData: any;
  private imgPatientData: any;
  private imgSomnicsData: any;
  private imgOverviewData: any;
  private imgPatientDetailData: any;
  private imgTherapyData: any;
  private imgReportData: any;
  private w: number = 1000;
  private h: number = 400;
  private margin = { top: 10, right: 50, bottom: 60, left: 100 };
  private width = this.w - this.margin.left - this.margin.right;
  private height = this.h - this.margin.top - this.margin.bottom;
  today_str:string;
  subscription_char = new Subscription();
  public chart_detail: number;
  constructor(public settings: SettingsService,public injector: Injector, private rxfb: RxFormBuilder,private http:HttpClient) {
    this.serverURL = this.settings.getAppSetting('serverURL');
    this.router = this.injector.get(Router);
    this.patient = this.sdkViewModel.selectPatient;
    this.patient.clinicalUserList =  this.loginViewModel.loginUser.clinicalUserList;

    this.isNoData = true;
    if(this.patient.reportDataList.length > 0){
      this.isNoData = false;
      this.patient.data = this.patient.reportDataList[0];

      this.patient.fromPicker = new Date(this.patient.reportDataList[0].datetime_full);
      this.patient.toPicker = new Date(this.patient.reportDataList[0].datetime_full);
      this.patient.from_date = this.dateToYYYYmmdd(this.patient.fromPicker)
      this.patient.to_date = this.dateToYYYYmmdd(this.patient.toPicker)
  
    }
  
    this.usedayList = [];
    this.patient.reportDataList.forEach(item => {
      var d = new Date(item.datetime_full);
      this.usedayList.push(d)
    });


    this.patient.report = new Report();
    this.patient.report.deviceSetting = new DeviceSetting();

    /*let Today = new Date();
    var MM = (Today.getMonth() + 1) >= 10 ? (Today.getMonth() + 1) : ("0" + (Today.getMonth() + 1));
    var dd = Today.getDate() < 10 ? ("0"+Today.getDate()) : Today.getDate();
    var hh = Today.getHours() < 10 ? ("0"+Today.getHours()) : Today.getHours();
    var mm = Today.getMinutes() < 10 ? ("0"+Today.getMinutes()) : Today.getMinutes();
    this.today_str = Today.getFullYear()+'/'+MM+'/'+dd+' '+hh+':'+mm;
    */
    this.today_str = '';
    this.patient.language = 'english'
    this.patient.unit = 'cmH2O'
    this.userFormGroup = this.rxfb.formGroup(this.patient);
  }


  ngAfterViewInit(): void {
    const parent = this;
    const observer = {
      next(patient) {
        parent.patient.report = patient.report;

        let Today = new Date();
        var MM = (Today.getMonth() + 1) >= 10 ? (Today.getMonth() + 1) : ("0" + (Today.getMonth() + 1));
        var dd = Today.getDate() < 10 ? ("0"+Today.getDate()) : Today.getDate();
        var hh = Today.getHours() < 10 ? ("0"+Today.getHours()) : Today.getHours();
        var mm = Today.getMinutes() < 10 ? ("0"+Today.getMinutes()) : Today.getMinutes();
        parent.today_str = Today.getFullYear()+'/'+MM+'/'+dd+' '+hh+':'+mm;


        parent.patient.data = patient.data;
        parent.createSomnicsLogoImage();
        parent.createPatientImage();
        parent.createSomnicsImage();
        parent.createOverviewImage();
        parent.createPatientDetailImage();
        parent.createTherapyImage();
        parent.createReportImage();
        parent.isDataReady = true
      }
    };
    this.sdkViewModel.input.report = this.report$;
    this.sdkViewModel.input.downloadReportImage = this.downloadReportImage$;
    
    this.subscription_char = this.sdkViewModel.output.chart.subscribe(observer);

    if (this.sdkViewModel.setup_report() === false) {
      throw new Error('example error message');
    }
    if (this.isNoData == false && this.sdkViewModel.setup_downloadReportImage() === false) {
      throw new Error('example error message');
    }
    this.report$.next(this.patient);
  }
  ngOnDestroy(): void {
    this.subscription_char.unsubscribe();
  }

  onDownload(){
    console.log("onDownload");
  }

  onDownloadImage(){
    this.downloadReportImage$.next(this.getImgData());
  }
  
  onDownloadFile(){
    this.isDataReady = false;

    const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
    httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        
    const url = this.serverURL+'report_excel_download?user_id='+this.patient.cloud_user_id+'&from_date='+this.patient.from_date+'&to_date='+this.patient.to_date;
    this.http.get(url,{ headers: httpOptions.headers,responseType: 'arraybuffer'} 
      ).subscribe(response => this.downLoadFile(response, "application/zip"));

  }

  onDownloadFilePeriod(){
    this.isDataReady = false;

    const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
    httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
        
    const url = this.serverURL+'report_excel_download_period?patient_id='+this.patient.id+'&from_date='+this.patient.from_date+'&to_date='+this.patient.to_date;
    this.http.get(url,{ headers: httpOptions.headers,responseType: 'arraybuffer'} 
      ).subscribe(response => this.downLoadFile(response, "application/zip"));

  }

  onDownloadPDFReport(){
    this.isDataReady = false;

    this.patient.from_date = this.dateToYYYYmmdd(this.patient.fromPicker)
    this.patient.to_date = this.dateToYYYYmmdd(this.patient.toPicker)

    const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
    httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
    
    // var tzoffset = new Date().getTimezoneOffset();
    // console.log(tzoffset);
    var language = this.patient.language;
    var unit = this.patient.unit;

    const url = this.serverURL+'report2?cloud_user_id='+this.patient.cloud_user_id+'&patient_id='+this.patient.id+'&from_date='+this.patient.from_date+'&to_date='+this.patient.to_date+'&language='+language+'&unit='+unit;
    this.http.get(url,{ headers: httpOptions.headers,responseType: 'arraybuffer'} 
      ).subscribe(response => this.downLoadPDF(response, "application/pdf"));
  }

  timer(){
    console.log('start timer');
    setTimeout(() => {
      console.log('end timer');
      this.isDataReady = true;
      this.onGenerateFinereport();
    }, 10*1000);
  }
  //產生報告20221020
  onGenerateFinereport(){
    this.isDataReady = false;
    this.finereport_msg = '';

    this.patient.from_date = this.dateToYYYYmmdd(this.patient.fromPicker)
    this.patient.to_date = this.dateToYYYYmmdd(this.patient.toPicker)

    const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
    httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
    
    var language = this.patient.language;
    var unit = this.patient.unit;

    const url = this.serverURL+'generate_finereport?cloud_user_id='+this.patient.cloud_user_id+'&patient_id='+this.patient.id+'&from_date='+this.patient.from_date+'&to_date='+this.patient.to_date+'&language='+language+'&unit='+unit;
    this.http.get(url,{ headers: httpOptions.headers,responseType: 'text'} 
    ).subscribe((response) =>  {
      let res = JSON.parse(response);
      if (res.message == 'queue'){
        this.timer();
      }
      else if (res.message == 'wrong'){
        this.finereport_msg = 'Can not generate finereport.Please try again.';
        this.isDataReady = true;
      }
      else if (res.message == 'wait'){
        this.timer();
      }
      else if (res.message == 'done'){ //報告已產生,可下載
        this.finereport_msg = '';
        this.onDownloadFinereport();
      }
    });
  }
  //下載報告20221020
  onDownloadFinereport(){
    this.patient.from_date = this.dateToYYYYmmdd(this.patient.fromPicker)
    this.patient.to_date = this.dateToYYYYmmdd(this.patient.toPicker)

    const bearer_token = 'Bearer '+ this.loginViewModel.loginUser.token.access_token;
    httpOptions.headers = httpOptions.headers.set('Authorization', bearer_token);
    
    var language = this.patient.language;
    var unit = this.patient.unit;

    //為了每次呼叫都到後台取目前最新檔案,所以加上目前時間參數,確保url不會受到cache影響,下載cache內的檔案(之前下載過檔案)
    var now = new Date().getTime()
    console.log(now);
    const url = this.serverURL+'download_finereport?cloud_user_id='+this.patient.cloud_user_id+'&patient_id='+this.patient.id+'&from_date='+this.patient.from_date+'&to_date='+this.patient.to_date+'&language='+language+'&unit='+unit+'&now='+now;
    this.http.get(url,{ headers: httpOptions.headers,responseType: 'arraybuffer'} 
      ).subscribe(response => this.downLoadFinereport(response, "application/pdf"));

  }

  downLoadFile(data: any, type: string) { 
    const fileName = this.patient.email+'_'+this.patient.from_date+'_'+this.patient.to_date+'.zip'; 
    const a = document.createElement('a'); 
    document.body.appendChild(a); 
    const blob = new Blob([data], {type: type}); 
    const url = window.URL.createObjectURL(blob); 
    a.href = url; 
    a.download = fileName; 
    a.click(); 
    window.URL.revokeObjectURL(url); 
    this.isDataReady = true;
  }

  downLoadPDF(data: any, type: string) { 
    const fileName = this.patient.email+'_'+this.patient.from_date+'_'+this.patient.to_date+'.pdf'; 
    const a = document.createElement('a'); 
    document.body.appendChild(a); 
    const blob = new Blob([data], {type: type}); 
    const url = window.URL.createObjectURL(blob); 
    a.href = url; 
    a.download = fileName; 
    a.click(); 
    window.URL.revokeObjectURL(url); 
    this.isDataReady = true;
  }

  downLoadFinereport(data: any, type: string) { 
    const fileName = this.patient.email+'_'+this.patient.from_date+'_'+this.patient.to_date+'.pdf'; 
    const a = document.createElement('a'); 
    document.body.appendChild(a); 
    const blob = new Blob([data], {type: type}); 
    const url = window.URL.createObjectURL(blob); 
    a.href = url; 
    a.download = fileName; 
    a.click(); 
    window.URL.revokeObjectURL(url);
    this.isDataReady = true;
  }

  /*onUpdate(){
    this.isDataReady = false
    this.update$.next(this.patient);
  }*/

  fromFilter = (d: Date | null): boolean => {
    const time = d.getTime();
    return this.usedayList.find(x=>x.getTime()==time)?true:false;
  }

  toFilter = (d: Date | null): boolean => {
    const time = d.getTime();
    return this.usedayList.find(x=>x.getTime()==time)?true:false;
  }

  dateToYYYYmmdd(picker:Date):string{
    var yyyy = picker.getFullYear();
    var mm = picker.getMonth()+1;
    var dd = picker.getDate();
    var result = ''
    var mm_str = ''
    var dd_str = ''
    if( mm < 10 ){
      mm_str = '0'+String(mm)
    }
    else{
      mm_str = String(mm)
    }

    if( dd < 10 ){
      dd_str = '0'+String(dd)
    }
    else{
      dd_str = String(dd)
    }

    result = String(yyyy)+'-'+mm_str+'-'+dd_str
    return result
  }

  onSearch(){
    this.finereport_msg = '';

    this.patient.from_date = this.dateToYYYYmmdd(this.patient.fromPicker)
    this.patient.to_date = this.dateToYYYYmmdd(this.patient.toPicker)

    this.isDataReady = false
    this.report$.next(this.patient);
  }
  

  onCancel(){
    console.warn(this.userFormGroup.value);
    this.router.navigate(['patientlist']);
  }

  ngOnInit() {
    this.chart_detail = 0;
    if(this.loginViewModel.loginUser.chart_detail == 1){
        this.chart_detail = 1;
    }

    this.createSomnicsLogoImage();
    this.createPatientImage();
    this.createSomnicsImage();
    this.createOverviewImage();
    this.createPatientDetailImage();
    this.createTherapyImage();
    this.createReportImage();
  }

  private createSomnicsLogoImage() {
    if(this.isNoData == false){
      var source = ``;
      if(true ){
        source += `<svg viewBox="0 0 240 160" xmlns="http://www.w3.org/2000/svg">
                  <style>
                  .small { font: 6px sans-serif; }
                  .heavy { font: bold 30px sans-serif; }
                  .Rrrrr { font: italic 40px serif; fill: red; }
                  </style>
                  <text x="20" y="35" class="small">Report prepared by : iNAP Cloud Service on `+ this.today_str+` </text>
                  </svg>`;
      }
      
      
      var blob = new Blob([source], {type: "image/svg+xml;charset=utf-8"});
      var url = "assets/img/somnics_logo.png";//window.URL.createObjectURL(blob);
      var image = new Image();
      image.onload = () => {
       
      let canvas = document.createElement('canvas');
       
      canvas.width = this.width;
      canvas.height = this.height;
      let context = canvas.getContext('2d');
      // draw image in canvas starting left-0 , top - 0
      context.fillStyle = "white";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, this.width, this.height );
       
      this.imgSomnicsLogoData = canvas.toDataURL("image/png");
      /*var doc = new jsPDF('p', 'pt', [1024,4096]);
      doc.addImage(this.imgData, 'PNG', 100,100, this.width, this.height);
      doc.save('chart.pdf');*/
      // downloadImage(canvas); need to implement
      };
       
      image.src = url;
    }
  }
  private createPatientImage() {
    if(this.isNoData == false){
      var source = ``;
      if(true ){
        source += `<svg viewBox="0 0 240 160" xmlns="http://www.w3.org/2000/svg">
                  <style>
                  .small { font: 6px sans-serif; }
                  .heavy { font: bold 30px sans-serif; }
                  .Rrrrr { font: italic 40px serif; fill: red; }
                  </style>
                  <text x="20" y="20" class="small">Name :`+ this.patient.username+` </text>
                  <text x="20" y="35" class="small">Patient ID :`+ this.patient.medical_number+` </text>
                  <text x="20" y="50" class="small">Date of Birth :`+ this.patient.birthday+` </text>
                  <text x="20" y="65" class="small">Report prepared by : iNAP Cloud Service on `+ this.today_str+` </text>
                  </svg>`;
      }
      
      
      var blob = new Blob([source], {type: "image/svg+xml;charset=utf-8"});
      var url = window.URL.createObjectURL(blob);
      var image = new Image();
      image.onload = () => {
       
      let canvas = document.createElement('canvas');
       
      canvas.width = this.width;
      canvas.height = this.height;
      let context = canvas.getContext('2d');
      // draw image in canvas starting left-0 , top - 0
      context.fillStyle = "white";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, this.width, this.height );
       
      this.imgPatientData = canvas.toDataURL("image/png");
      /*var doc = new jsPDF('p', 'pt', [1024,4096]);
      doc.addImage(this.imgData, 'PNG', 100,100, this.width, this.height);
      doc.save('chart.pdf');*/
      // downloadImage(canvas); need to implement
      };
       
      image.src = url;
    }
  }

  private createSomnicsImage() {
    if(this.isNoData == false){
      var source = ``;
      if(true ){
        source += `<svg viewBox="0 0 240 160" xmlns="http://www.w3.org/2000/svg">
                  <style>
                  .small { font: 6px sans-serif; }
                  .heavy { font: bold 30px sans-serif; }
                  .Rrrrr { font: italic 40px serif; fill: red; }
                  </style>
                  <text x="20" y="35" class="small">Report prepared by : iNAP Cloud Service on `+ this.today_str+` </text>
                  </svg>`;
      }
      
      
      var blob = new Blob([source], {type: "image/svg+xml;charset=utf-8"});
      var url = "assets/img/somnics.png";//window.URL.createObjectURL(blob);
      var image = new Image();
      image.onload = () => {
       
      let canvas = document.createElement('canvas');
       
      canvas.width = this.width;
      canvas.height = this.height;
      let context = canvas.getContext('2d');
      // draw image in canvas starting left-0 , top - 0
      context.fillStyle = "white";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, this.width, this.height );
       
      this.imgSomnicsData = canvas.toDataURL("image/png");
      /*var doc = new jsPDF('p', 'pt', [1024,4096]);
      doc.addImage(this.imgData, 'PNG', 100,100, this.width, this.height);
      doc.save('chart.pdf');*/
      // downloadImage(canvas); need to implement
      };
       
      image.src = url;
    }
  }

  private createOverviewImage() {
    if(this.isNoData == false){
      var source = ``;
      
      source += `<svg viewBox="0 0 240 160" xmlns="http://www.w3.org/2000/svg">
                  <style>
                  .small { font: 6px sans-serif; }
                  .heavy { font: bold 30px sans-serif; }
                  .Rrrrr { font: italic 40px serif; fill: red; }
                  </style>
                  <text x="0" y="10" class="small">------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</text>
                  <text x="10" y="20" class="small">OVERVIEW</text>
                  <text x="60" y="20" class="small">`+ this.patient.report.date_range+`</text>
                  `;
      

      for(var i=0;i<this.patient.report.device_used.length;i++){
        var item = this.patient.report.device_used[i];
        console.log(item);

        source += `
                  <text x="150" y="`+10*(i+2)+`" class="small">`+item+`</text>
                  `;
      }

      source += `</svg>`;
      
      
      var blob = new Blob([source], {type: "image/svg+xml;charset=utf-8"});
      var url = window.URL.createObjectURL(blob);
      var image = new Image();
      image.onload = () => {
       
      let canvas = document.createElement('canvas');
       
      canvas.width = this.width;
      canvas.height = this.height;
      let context = canvas.getContext('2d');
      // draw image in canvas starting left-0 , top - 0
      context.fillStyle = "white";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, this.width, this.height );
       
      this.imgOverviewData = canvas.toDataURL("image/png");
      /*var doc = new jsPDF('p', 'pt', [1024,4096]);
      doc.addImage(this.imgData, 'PNG', 100,100, this.width, this.height);
      doc.save('chart.pdf');*/
      // downloadImage(canvas); need to implement
      };
       
      image.src = url;
    }
  }

  private createPatientDetailImage() {
    if(this.isNoData == false){
      var source = ``;
      if(true ){
        source += `<svg viewBox="0 0 240 160" xmlns="http://www.w3.org/2000/svg">
                  <style>
                  .small { font: 6px sans-serif; }
                  .heavy { font: bold 30px sans-serif; }
                  .Rrrrr { font: italic 40px serif; fill: red; }
                  </style>
                  <text x="0" y="10" class="small">------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</text>
                  <text x="10" y="20" class="small">PATIENT </text>
                  <text x="60" y="30" class="small">Name:`+ this.patient.username+` </text>
                  <text x="150" y="30" class="small">Phone: </text>
                  <text x="60" y="40" class="small">Date of Birth:`+ this.patient.birthday+` </text>
                  `;
        var email = '';
        if(this.patient.email != 'email'){
          email = this.patient.email;
        }
        
        source += `<text x="150" y="40" class="small">E-mail:`+ email+` </text>
                  `;
        var sex = 'Male';
        if(this.patient.title != 'Mr.'){
          sex = 'Female';
        }
        source += `<text x="60" y="50" class="small">Sex:`+ sex+` </text>
                  `;
        var address = '';
        if(this.patient.patientAddressList.length > 0){
          address = this.patient.patientAddressList[0].description;
        }
        source += `<text x="150" y="50" class="small">Address:`+ address+` </text>
                  <text x="60" y="60" class="small">Patient ID:`+ this.patient.medical_number+` </text>
                  </svg>`;
      }
      
      
      var blob = new Blob([source], {type: "image/svg+xml;charset=utf-8"});
      var url = window.URL.createObjectURL(blob);
      var image = new Image();
      image.onload = () => {
       
      let canvas = document.createElement('canvas');
       
      canvas.width = this.width;
      canvas.height = this.height;
      let context = canvas.getContext('2d');
      // draw image in canvas starting left-0 , top - 0
      context.fillStyle = "white";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, this.width, this.height );
       
      this.imgPatientDetailData = canvas.toDataURL("image/png");
      /*var doc = new jsPDF('p', 'pt', [1024,4096]);
      doc.addImage(this.imgData, 'PNG', 100,100, this.width, this.height);
      doc.save('chart.pdf');*/
      // downloadImage(canvas); need to implement
      };
       
      image.src = url;
    }
  }

  private createTherapyImage() {
    if(this.isNoData == false){
      var source = ``;
      
      source += `<svg viewBox="0 0 240 160" xmlns="http://www.w3.org/2000/svg">
                  <style>
                  .small { font: 6px sans-serif; }
                  .heavy { font: bold 30px sans-serif; }
                  .Rrrrr { font: italic 40px serif; fill: red; }
                  </style>
                  <text x="0" y="10" class="small">------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</text>
                  <text x="10" y="20" class="small">THERAPY SETTINGS</text>
                  <text x="50" y="30" class="small">Negative Pressure (mmHg):</text>
                  <text x="130" y="30" class="small">`+ this.patient.report.deviceSetting.current+`</text>
                  <text x="150" y="30" class="small">Strength:</text>
                  <text x="0" y="40" class="small">------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</text>
                  </svg>`;
      
      var blob = new Blob([source], {type: "image/svg+xml;charset=utf-8"});
      var url = window.URL.createObjectURL(blob);
      var image = new Image();
      image.onload = () => {
       
      let canvas = document.createElement('canvas');
       
      canvas.width = this.width;
      canvas.height = this.height;
      let context = canvas.getContext('2d');
      // draw image in canvas starting left-0 , top - 0
      context.fillStyle = "white";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, this.width, this.height );
       
      this.imgTherapyData = canvas.toDataURL("image/png");
      /*var doc = new jsPDF('p', 'pt', [1024,4096]);
      doc.addImage(this.imgData, 'PNG', 100,100, this.width, this.height);
      doc.save('chart.pdf');*/
      // downloadImage(canvas); need to implement
      };
       
      image.src = url;
    }
  }

  private createReportImage() {
    if(this.isNoData == false){
      var source = ``;
      if(this.chart_detail == 1){
        source += `<svg viewBox="0 0 240 160" xmlns="http://www.w3.org/2000/svg">
                  <style>
                  .small { font: 6px sans-serif; }
                  .heavy { font: bold 30px sans-serif; }
                  .Rrrrr { font: italic 40px serif; fill: red; }
                  </style>
                  <text x="0" y="2" class="small">------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</text>
                  <text x="20" y="10" class="small">Compliance Detail (%)</text>
                  <text x="40" y="20" class="small">Mean:</text>
                  <text x="60" y="20" class="small">`+ this.patient.report.seal_percent_mean+`</text>
                  <text x="80" y="20" class="small">Median:</text>
                  <text x="105" y="20" class="small">`+ this.patient.report.seal_percent_median+`</text>
                  <text x="125" y="20" class="small">95th:</text>
                  <text x="145" y="20" class="small">`+ this.patient.report.seal_percent_95th+`</text>
                  <text x="165" y="20" class="small">Max:</text>
                  <text x="185" y="20" class="small">`+ this.patient.report.seal_percent_max+`</text>
                  <text x="205" y="20" class="small">Min:</text>
                  <text x="225" y="20" class="small">`+ this.patient.report.seal_percent_min+`</text>

                  <text x="20" y="35" class="small">Compliance Summary (%)</text>
                  <text x="40" y="45" class="small">Mean:</text>
                  <text x="60" y="45" class="small">`+ this.patient.report.seal_percent_compliance_mean+`</text>
                  <text x="80" y="45" class="small">Median:</text>
                  <text x="105" y="45" class="small">`+ this.patient.report.seal_percent_compliance_median+`</text>
                  <text x="125" y="45" class="small">95th:</text>
                  <text x="145" y="45" class="small">`+ this.patient.report.seal_percent_compliance_95th+`</text>
                  <text x="165" y="45" class="small">Max:</text>
                  <text x="185" y="45" class="small">`+ this.patient.report.seal_percent_compliance_max+`</text>
                  <text x="205" y="45" class="small">Min:</text>
                  <text x="225" y="45" class="small">`+ this.patient.report.seal_percent_compliance_min+`</text>

                  <text x="20" y="60" class="small">ODI (/h)</text>
                  <text x="40" y="70" class="small">Mean:</text>
                  <text x="60" y="70" class="small">`+ this.patient.report.odi_mean+`</text>
                  <text x="80" y="70" class="small">Median:</text>
                  <text x="105" y="70" class="small">`+ this.patient.report.odi_median+`</text>
                  <text x="125" y="70" class="small">95th:</text>
                  <text x="145" y="70" class="small">`+ this.patient.report.odi_95th+`</text>
                  <text x="165" y="70" class="small">Max:</text>
                  <text x="185" y="70" class="small">`+ this.patient.report.odi_max+`</text>
                  <text x="205" y="70" class="small">Min:</text>
                  <text x="225" y="70" class="small">`+ this.patient.report.odi_min+`</text>

                  <text x="20" y="85" class="small">Total Usage Detail (day)</text>
                  <text x="40" y="95" class="small">&gt;=4h:</text>
                  <text x="60" y="95" class="small">`+ this.patient.report.usage_greater_equal+`</text>
                  <text x="80" y="95" class="small">&lt;4h:</text>
                  <text x="95" y="95" class="small">`+ this.patient.report.usage_less+`</text>
                  <text x="125" y="95" class="small">Not Used:</text>
                  <text x="155" y="95" class="small">`+ this.patient.report.usage_not_used+`</text>
                  <text x="205" y="95" class="small">Total:</text>
                  <text x="225" y="95" class="small">`+ this.patient.report.usage_total+`</text>
                  <text x="40" y="105" class="small">Total Hours Used:</text>
                  <text x="95" y="105" class="small">`+ this.patient.report.usage_total_hours_used+`</text>
                  <text x="125" y="105" class="small">Average Daily Usage:</text>
                  <text x="190" y="105" class="small">`+ this.patient.report.usage_average_daily+`</text>


                  <text x="20" y="120" class="small">Total Usage Summary (day)</text>
                  <text x="40" y="130" class="small">&gt;=4h:</text>
                  <text x="60" y="130" class="small">`+ this.patient.report.usage_greater_equal_compliance+`</text>
                  <text x="80" y="130" class="small">&lt;4h:</text>
                  <text x="95" y="130" class="small">`+ this.patient.report.usage_less_compliance+`</text>
                  <text x="125" y="130" class="small">Not Used:</text>
                  <text x="155" y="130" class="small">`+ this.patient.report.usage_not_used_compliance+`</text>
                  <text x="205" y="130" class="small">Total:</text>
                  <text x="225" y="130" class="small">`+ this.patient.report.usage_total_compliance+`</text>
                  <text x="40" y="140" class="small">Total Hours Used:</text>
                  <text x="95" y="140" class="small">`+ this.patient.report.usage_total_hours_used_compliance+`</text>
                  <text x="125" y="140" class="small">Average Daily Usage:</text>
                  <text x="190" y="140" class="small">`+ this.patient.report.usage_average_daily_compliance+`</text>
                  <text x="0" y="150" class="small">------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</text>
                  
                  </svg>`;
      }
      else{
        source += `<svg viewBox="0 0 240 160" xmlns="http://www.w3.org/2000/svg">
                  <style>
                  .small { font: 6px sans-serif; }
                  .heavy { font: bold 30px sans-serif; }
                  .Rrrrr { font: italic 40px serif; fill: red; }
                  </style>
                  <text x="0" y="2" class="small">------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</text>
                  
                  <text x="20" y="35" class="small">Compliance Summary (%)</text>
                  <text x="40" y="45" class="small">Mean:</text>
                  <text x="60" y="45" class="small">`+ this.patient.report.seal_percent_compliance_mean+`</text>
                  <text x="80" y="45" class="small">Median:</text>
                  <text x="105" y="45" class="small">`+ this.patient.report.seal_percent_compliance_median+`</text>
                  <text x="125" y="45" class="small">95th:</text>
                  <text x="145" y="45" class="small">`+ this.patient.report.seal_percent_compliance_95th+`</text>
                  <text x="165" y="45" class="small">Max:</text>
                  <text x="185" y="45" class="small">`+ this.patient.report.seal_percent_compliance_max+`</text>
                  <text x="205" y="45" class="small">Min:</text>
                  <text x="225" y="45" class="small">`+ this.patient.report.seal_percent_compliance_min+`</text>

                  <text x="20" y="60" class="small">ODI (/h)</text>
                  <text x="40" y="70" class="small">Mean:</text>
                  <text x="60" y="70" class="small">`+ this.patient.report.odi_mean+`</text>
                  <text x="80" y="70" class="small">Median:</text>
                  <text x="105" y="70" class="small">`+ this.patient.report.odi_median+`</text>
                  <text x="125" y="70" class="small">95th:</text>
                  <text x="145" y="70" class="small">`+ this.patient.report.odi_95th+`</text>
                  <text x="165" y="70" class="small">Max:</text>
                  <text x="185" y="70" class="small">`+ this.patient.report.odi_max+`</text>
                  <text x="205" y="70" class="small">Min:</text>
                  <text x="225" y="70" class="small">`+ this.patient.report.odi_min+`</text>

                  <text x="20" y="120" class="small">Total Usage Summary (day)</text>
                  <text x="40" y="130" class="small">&gt;=4h:</text>
                  <text x="60" y="130" class="small">`+ this.patient.report.usage_greater_equal_compliance+`</text>
                  <text x="80" y="130" class="small">&lt;4h:</text>
                  <text x="95" y="130" class="small">`+ this.patient.report.usage_less_compliance+`</text>
                  <text x="125" y="130" class="small">Not Used:</text>
                  <text x="155" y="130" class="small">`+ this.patient.report.usage_not_used_compliance+`</text>
                  <text x="205" y="130" class="small">Total:</text>
                  <text x="225" y="130" class="small">`+ this.patient.report.usage_total_compliance+`</text>
                  <text x="40" y="140" class="small">Total Hours Used:</text>
                  <text x="95" y="140" class="small">`+ this.patient.report.usage_total_hours_used_compliance+`</text>
                  <text x="125" y="140" class="small">Average Daily Usage:</text>
                  <text x="190" y="140" class="small">`+ this.patient.report.usage_average_daily_compliance+`</text>
                  <text x="0" y="150" class="small">------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</text>
                  
                  </svg>`;
      }
      
      var blob = new Blob([source], {type: "image/svg+xml;charset=utf-8"});
      var url = window.URL.createObjectURL(blob);
      var image = new Image();
      image.onload = () => {
       
      let canvas = document.createElement('canvas');
       
      canvas.width = this.width;
      canvas.height = this.height;
      let context = canvas.getContext('2d');
      // draw image in canvas starting left-0 , top - 0
      context.fillStyle = "white";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, this.width, this.height );
       
      this.imgReportData = canvas.toDataURL("image/png");
      /*var doc = new jsPDF('p', 'pt', [1024,4096]);
      doc.addImage(this.imgData, 'PNG', 100,100, this.width, this.height);
      doc.save('chart.pdf');*/
      // downloadImage(canvas); need to implement
      };
       
      image.src = url;
    }
  }

  public getImgData(): any {
    var pdfilename = "";
    pdfilename = this.patient.email+'_'+this.patient.report.date_range+'.pdf';
    return {"somnicsLogo":this.imgSomnicsLogoData,
            "patient":this.imgPatientData,
            "somnics":this.imgSomnicsData,
            "overview":this.imgOverviewData,
            "patientDetail":this.imgPatientDetailData,
            "therapy":this.imgTherapyData,
            "report":this.imgReportData,
            "pdfilename":pdfilename,
            "chart_detail":this.chart_detail
          };//return this.imgData;
  }

}

