import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('GroupedBar')
export class GroupedBar extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('Group', String)
    private _Group: string;
    @JsonProperty('count', Number)
    private _count: number;
    @JsonProperty('datetime_time', Number)
    private _datetime_time: number;

    @prop()
    get id(): number {
        return this._id;
    }
    set id(id: number) {
        this._id = id;
    }
    @prop()
    get Group(): string {
        return this._Group;
    }
    set Group(Group: string) {
        this._Group = Group;
    }
    @prop()
    get count(): number {
        return this._count;
    }
    set count(count: number) {
        this._count = count;
    }

    @prop()
    get datetime_time(): number {
        return this._datetime_time;
    }
    set datetime_time(datetime_time: number) {
        this._datetime_time = datetime_time;
    }
}
