import {Component, Input, ElementRef, ViewEncapsulation, OnChanges, OnInit, SimpleChanges, SimpleChange} from '@angular/core';
import * as d3 from 'd3';


import { Injector } from '@angular/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user-service';
import { UserlistViewModel } from 'src/app/routes/userlist/userlist-view-model';
import { fromEvent, Subject, Observable } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import {Md5} from 'ts-md5/dist/md5';
import { PatientlistViewModel } from 'src/app/routes/patientlist/patientlist-view-model';
import { PatientdetailViewModel } from 'src/app/routes/patientlist/patientdetail/patientdetail-view-model';
import { SdkViewModel } from 'src/app/routes/pages/sdk/sdk-view-model';

import { Patient } from 'src/app/model/patient';
import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';



declare var $: any;

interface StackedChart{
	datetime_time: string,
	pressure_percent: number,
	treat: number,
	seal: number
}
@Component({
  selector: 'app-odi-trend',
  templateUrl: './odi-trend.component.html',
  styleUrls: ['./odi-trend.component.scss']
})
export class OdiTrendComponent implements OnInit{
  private data: StackedChart[];

  private w: number = 1400;//1000;
  private h: number = 400;
  private margin = { top: 10, right: 50, bottom: 60, left: 100 };
  private width = this.w - this.margin.left - this.margin.right;
  private height = this.h - this.margin.top - this.margin.bottom;

  private x: any;
  private y: any;
  private svg: any;
  private g: any;
  private stack: any;
  private chart: any;
  private layersBarArea: any;
  private layersBar: any;
  private xAxis: any;
  private yAxis: any;
  private legend: any;
  private legendItems: any;
  private tooltip: any;
  private xTitle: string;
  private yTitle: string;
  private stackedSeries: any;
  private keys: ['odi'];

  private colors = ['#EB6100'];

  userFormGroup: FormGroup;
  router: Router;
  userService: UserService = this.injector.get(UserService);
  patient: Patient;
  patientlistViewModel: PatientlistViewModel = this.injector.get(PatientlistViewModel);
  patientdetailViewModel: PatientdetailViewModel = this.injector.get(PatientdetailViewModel);
  sdkViewModel: SdkViewModel = this.injector.get(SdkViewModel);
  
  loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
  public update$: Subject<any> = new Subject<any>();
  private demodata:any;
  constructor(public injector: Injector, private rxfb: RxFormBuilder,private container: ElementRef) {
    this.router = this.injector.get(Router);
    this.patient = this.sdkViewModel.selectPatient;
    this.patient.clinicalUserList =  this.loginViewModel.loginUser.clinicalUserList;
    /*if(this.patient.dataList.length > 0){
      this.demodata = JSON.parse(JSON.stringify(this.patient.dataList));
    }*/
    this.demodata = JSON.parse(JSON.stringify([]));
    this.userFormGroup = this.rxfb.formGroup(this.patient);
  }

  ngAfterViewInit(): void {
    const parent = this;
    const observer = {
      next(trendDataList) {
        parent.patient.trendDataList = trendDataList;
        parent.demodata = JSON.parse(JSON.stringify(parent.patient.trendDataList));
        parent.demodata = parent.demodata.sort(function (a, b) {
          return a.datetime_timestamp_long > b.datetime_timestamp_long ? 1 : -1;
        });
        parent.showChart();
      }
    };
    this.sdkViewModel.input.update = this.update$;
    this.sdkViewModel.output.typeTrend.subscribe(observer);

    if (this.sdkViewModel.setup_update() === false) {
      throw new Error('example error message');
    }
  }
  private showChart(){
    this.stack = d3.stack().keys(['avg_odi']);
    this.removeExistingChartFromParent();
    this.initData();
    this.initTitle();
    this.initScales();
    this.initSvg();
    this.createStack(this.data);
    this.drawAxis();
    this.createLegend();
  }

  ngOnInit() {
    this.showChart();
  }

  private removeExistingChartFromParent() {
    d3.select(this.container.nativeElement).select('svg').remove();
  }

  private initData(){
    this.demodata.forEach(function(d) {
      d.treat = d.treat - d.seal;
    });

    this.data = this.demodata;
  }


  private initTitle() {
    this.xTitle = '';
    this.yTitle = 'ODI Event';
  }

  private initScales() {
    this.x = d3.scaleBand()
      .rangeRound([0, this.width])
      .padding(0.05);
    this.y = d3.scaleLinear().range([this.height, 0]);

  }

  private initSvg() {

    this.svg = d3.select(this.container.nativeElement)
      .select('.chart-container')
      .append('svg')
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr('class', 'chart')
      .attr('width', this.w)
      .attr('height', this.h)
      .attr("viewBox", "0 0 800 400");

    this.chart = this.svg.append('g')
      .classed('chart-contents', true)
      .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

    this.layersBarArea = this.chart.append('g')
      .classed('layers', true);
  }

  private drawAxis() {
    this.xAxis = this.chart.append('g')
      .classed('x axis', true)
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3.axisBottom(this.x))
      .selectAll("text")  
      .style("text-anchor", "end")
      .attr("transform", "rotate(-45)");

    this.chart.append("text")
      .attr("y", this.height + 40)
      .attr("x", (this.width / 2))
      .classed('axis-title', true)
      .style("text-anchor", "middle")
      .style('stroke', 'none')
      .text(this.xTitle);

    this.yAxis = this.chart.append('g')
      .classed('y axis', true)
      .call(d3.axisLeft(this.y)
        .ticks(7));

    this.chart.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - 60)
      .attr("x", 0 - (this.height / 2))
      .attr("fill", "black")
      .style("text-anchor", "middle")
      .style('stroke', 'none')
      .classed('axis-title', true)
      .text(this.yTitle);
  }

  private createStack(stackData: any) {
    this.stackedSeries = this.stack(stackData);
    console.log(this.stackedSeries)
    this.drawChart(this.stackedSeries)
  }

  private drawChart(data: any) {
    var parent = this;

    this.layersBar = this.layersBarArea.selectAll('.layer')
      .data(data)
      .enter()
      .append('g')
      .classed('layer', true)
      .style('fill', (d: any, i: any) => {
        return this.colors[i];
      });

    this.x.domain(this.data.map((d: any) => {
      return d.datetime_time
    }));


    this.y.domain([0, 60]);

    this.layersBar.selectAll('rect')
      .data((d: any) => {
        console.log(d)
        return d;
      })
      .enter()
      .append('rect')
      .attr('y', (d: any) => {
        return this.y(d[1])
      })
      .attr('x', (d: any, i: any) => {
        return this.x(d.data.datetime_time)
      })
      .attr('width', 30)//bar寬度 .attr('width', this.x.bandwidth())
      .attr('height', (d: any, i: any) => {
        return this.y(d[0]) - this.y(d[1]);
      })
      .attr('transform', `translate(${this.x.bandwidth() / 2 - 15}, 0)`)//add bar寬度
  }

  private createLegend(){
    var parent = this;

    this.legend = this.svg.append('g')
                .attr('class', 'legend')
                .attr('transform', 'translate(' + (100 + 12) + ', 0)');

    this.legend.selectAll('rect')
                .data(this.stackedSeries)
                .enter()
                .append('rect')
                .attr('x', 0)
                .attr('y', function(d, i){
                    return i * 18;
                })
                .attr('width', 12)
                .attr('height', 12)
                .attr('fill', function(d, i){
                    return parent.colors[i];
                });

            this.legend.selectAll('text')
                .data(this.stackedSeries)
                .enter()
                .append('text')
                .text(function(d){
                  var title = 'ODI';

                    return title;
                })
                .attr('x', 18)
                .attr('y', function(d, i){
                    return i * 18;
                })
                .attr("fill", "black")
                .attr('text-anchor', 'start')
                .attr('alignment-baseline', 'hanging');
  }
}
