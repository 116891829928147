
import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('Spo2Detail')

export class Spo2Detail extends Base{
    @JsonProperty('datetime_time', String)
    private _datetime_time: string;
    @JsonProperty('time', Number)
    private _time: number;
    @JsonProperty('spo2', Number)
    private _spo2: number;
    
    

    @prop()
    get datetime_time(): string {
        return this._datetime_time;
    }

    set datetime_time(datetime_time: string) {
        this._datetime_time = datetime_time;
    }

    @prop()
    get time(): number {
        return this._time;
    }
    set time(time: number) {
        this._time = time;
    }

    @prop()
    get spo2(): number {
        return this._spo2;
    }

    set spo2(spo2: number) {
        this._spo2 = spo2;
    }
    

}
