import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';

@JsonObject('Token')
export class Token extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('access_token', String)
    private _access_token: string;

    @prop()
    get id() {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }
    
    @prop()
    get access_token(): string {
        return this._access_token;
    }

    set access_token(access_token: string) {
        this._access_token = access_token;
    }
}
