import {Component, Input, ElementRef, ViewEncapsulation, OnChanges, OnInit, SimpleChanges, SimpleChange} from '@angular/core';
import * as d3 from 'd3';
import d3_save_pdf from 'd3-save-pdf';
import { jsPDF } from "jspdf";

import { Injector } from '@angular/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user-service';
import { UserlistViewModel } from 'src/app/routes/userlist/userlist-view-model';
import { fromEvent, Subject, Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import {Md5} from 'ts-md5/dist/md5';
import { PatientlistViewModel } from 'src/app/routes/patientlist/patientlist-view-model';
import { PatientdetailViewModel } from 'src/app/routes/patientlist/patientdetail/patientdetail-view-model';
import { SdkViewModel } from 'src/app/routes/pages/sdk/sdk-view-model';

import { Patient } from 'src/app/model/patient';
import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';

import { GroupedBar } from 'src/app/model/grouped-bar';
import * as CryptoJS from 'crypto-js';

declare var $: any;

interface StackedChart{
	datetime_time: string,
	pressure_percent: number,
	treat: number,
	seal: number
}
@Component({
  selector: 'app-compliance-chart',
  templateUrl: './compliance-chart.component.html',
  styleUrls: ['./compliance-chart.component.scss']
})

export class ComplianceChartComponent implements OnInit{
  private data: StackedChart[];

  private w: number = 1650;//1000;
  private h: number = 400;
  private margin = { top: 10, right: 50, bottom: 60, left: 100 };
  private width = this.w - this.margin.left - this.margin.right;
  private height = this.h - this.margin.top - this.margin.bottom;

  private x: any;
  private y: any;
  private svg: any;
  private g: any;
  private stack: any;
  private chart: any;
  private layersBarArea: any;
  private layersBar: any;
  private xAxis: any;
  private yAxis: any;
  private legend: any;
  private legendItems: any;
  private tooltip: any;
  private xTitle: string;
  private yTitle: string;
  private stackedSeries: any;
  private keys: ['seal', 'treat'];

  private colors = ['#63A9EB','#0053B5'];

  userFormGroup: FormGroup;
  router: Router;
  userService: UserService = this.injector.get(UserService);
  patient: Patient;
  patientlistViewModel: PatientlistViewModel = this.injector.get(PatientlistViewModel);
  patientdetailViewModel: PatientdetailViewModel = this.injector.get(PatientdetailViewModel);
  sdkViewModel: SdkViewModel = this.injector.get(SdkViewModel);
  
  loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
  public complianceImage$: Subject<any> = new Subject<any>();
  
  private demodata:any;
  private imgData: any;
  subscription = new Subscription();
  subscription_char = new Subscription();
  constructor(public injector: Injector, private rxfb: RxFormBuilder,private container: ElementRef) {
    this.router = this.injector.get(Router);
    this.patient = this.sdkViewModel.selectPatient;
    this.patient.clinicalUserList =  this.loginViewModel.loginUser.clinicalUserList;
    this.patient.data.sealPercentDetailComplianceList = [];
    this.demodata = [];//this.demodata = JSON.parse(JSON.stringify(this.patient.data.sealPercentDetailComplianceList));
    
    this.userFormGroup = this.rxfb.formGroup(this.patient);
  }

  ngAfterViewInit(): void {
    const parent = this;
    const observer = {
      next(patient) {
        parent.patient.data = patient.data;
        //parent.demodata = JSON.parse(JSON.stringify(parent.patient.data.sealPercentDetailComplianceList));
        parent.demodata = []
        for(var i=0;i < patient.data.sealPercentDetailComplianceList.length;i++){
          var item = patient.data.sealPercentDetailComplianceList[i];
          var groupedBar_treat = new GroupedBar()  
          groupedBar_treat.Group = 'treat'
          groupedBar_treat.count = item.treat
          groupedBar_treat.datetime_time = item.datetime_time
          parent.demodata.push(groupedBar_treat)
          var groupedBar_seal = new GroupedBar()  
          groupedBar_seal.Group = 'seal'
          groupedBar_seal.count = item.seal
          groupedBar_seal.datetime_time = item.datetime_time
          parent.demodata.push(groupedBar_seal)
        }
        
        parent.showChart();
      }
    };
    const getImgDataObserver = {
      next() {
        parent.complianceImage$.next(parent.getImgData());
      }
    };
    
    this.sdkViewModel.input.complianceImage = this.complianceImage$;
    this.subscription = this.sdkViewModel.output.getComplianceImgData.subscribe(getImgDataObserver);
    this.subscription_char = this.sdkViewModel.output.chart.subscribe(observer);
    
    

    if (this.sdkViewModel.setup_complianceImage() === false) {
      throw new Error('example error message');
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscription_char.unsubscribe();
    
  }
  private showChart(){
    this.stack = d3.stack().keys(['seal','treat'])
    this.removeExistingChartFromParent();
    this.initData();
    this.initTitle();
    this.initScales();
    this.initSvg();
    //this.createStack(this.data);
    this.drawAxis();
    this.createLegend();
    //<-
    var columHeight = this.height;
    var color = this.colors;//['#0053B5','#63A9EB'];
    var g = this.chart.append("g");
    var ymaxdomain = d3.max(this.demodata, function(d) {
      return d.count;
    });
    var x = d3.scaleLinear().domain([0,48]).range([0, this.width]);
    //var y = d3.scaleLinear().domain([0, ymaxdomain+20]).range([this.height, 0]);
    var y = d3.scaleLinear().domain([0, ymaxdomain]).range([this.height, 0]);

    var x1 = d3.scaleBand()
    .rangeRound([0, 24]).domain(this.demodata.map(function(d) {
        return d.Group;
      }));

    var groups = g.selectAll(null)
      .data(this.demodata)
      .enter()
      .append("g")
      .attr("transform", function(d,i) {
        return "translate(" + x(i) + ",-20)";;
      })

    var bars = groups.selectAll(null)
      .data(function(d) {
        return [d]
      })
      .enter()
      .append("rect")
      .attr("x", function(d, i) {
        if(d.Group == 'seal'){
          return x1(d.Group) - 30 -12
        }
        else{
          return x1(d.Group) -12
        }
      })
      .attr("y", function(d) {
        return y(d.count)+20;
      })
      .attr("width", x1.bandwidth())
      .attr("height", function(d) {
        return columHeight - y(d.count);
      })
      .attr("fill", function(d) {
        if(d.Group == 'seal'){
          return color[1]
        }
        else{
          return color[0]
        }
      })

      var xScale = d3.scaleLinear().domain([0,24]).range([0, this.width]);
      var xset = ["12","13","14","15","16","17","18","19","20","21","22","23","00","01","02","03","04","05","06","07","08","09","10","11"];
      this.xAxis = this.chart.append('g')
      .classed('x axis', true)
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3.axisBottom(xScale).ticks(24).tickFormat( (d: any, i: any) => {
        if(i < xset.length){ 
          return xset[i]
        }
      }));
      this.yAxis = this.chart.append('g')
      .classed('y axis', true)
      .call(d3.axisLeft(y)
        .ticks(7));
    ///->
    this.createImage();
  }
  ngOnInit() {
    this.showChart();
  }
  private removeExistingChartFromParent() {
    d3.select(this.container.nativeElement).select('svg').remove();
  }
  private initData(){
    /*this.demodata.forEach(function(d) {
      d.treat = d.treat - d.seal;
    });*/

    this.data = this.demodata;
  }


  private initTitle() {
    this.xTitle = 'Time ( Hour )';
    this.yTitle = 'Treatment Summary ( Minutes )';
  }

  private initScales() {
    /*this.x = d3.scaleBand()
      .rangeRound([0, this.width])
      .padding(0.05);
    this.x = d3.scaleLinear().domain([0,this.demodata.length]).range([0, this.width]);
    this.y = d3.scaleLinear().range([this.height, 0]);
    */
  }

  private initSvg() {

    this.svg = d3.select(this.container.nativeElement)
      .select('.chart-container')
      .append('svg')
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr('class', 'chart')
      .attr('width', this.w)
      .attr('height', this.h)
      .attr("viewBox", "0 0 800 400");

    this.chart = this.svg.append('g')
      .classed('chart-contents', true)
      .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

    this.layersBarArea = this.chart.append('g')
      .classed('layers', true);
  }

  private drawAxis() {
    /*this.xAxis = this.chart.append('g')
      .classed('x axis', true)
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3.axisBottom(this.x).ticks(24).tickFormat( (d: any, i: any) => {
        //console.log(i)
        //console.log(d) 
        if(i < this.demodata.length){ 
          return this.demodata[i].datetime_time
        }
      }));*/

    /*this.chart.append("text")
      .attr("y", this.height + 40)
      .attr("x", (this.width / 2))
      .classed('axis-title', true)
      .style("text-anchor", "middle")
      .style('stroke', 'none')
      .text(this.xTitle);*/
    this.chart.append("text")
      .attr("transform", "rotate(0)")//.attr("transform", "rotate(-90)")
      .attr("y",  370 )//.attr("y",  this.width + 40 )
      .attr("x",  760 )//.attr("x", 0 - (this.height) + 20 )
      .attr("fill", "black")
      .style("text-anchor", "middle")
      .style('stroke', 'none')
      .classed('axis-title', true)
      .text(this.xTitle);

    /*this.yAxis = this.chart.append('g')
      .classed('y axis', true)
      .call(d3.axisLeft(this.y)
        .ticks(7));*/

    this.chart.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - 60)
      .attr("x", 0 - (this.height / 2))
      .attr("fill", "black")
      .style("text-anchor", "middle")
      .style('stroke', 'none')
      .classed('axis-title', true)
      .text(this.yTitle);
  }

  private createStack(stackData: any) {
    this.stackedSeries = this.stack(stackData);
    console.log(this.stackedSeries)
    //this.drawChart(this.stackedSeries)
  }

  private drawChart(data: any) {
    var parent = this;

    this.layersBar = this.layersBarArea.selectAll('.layer')
      .data(data)
      .enter()
      .append('g')
      .classed('layer', true)
      .style('fill', (d: any, i: any) => {
        return this.colors[i];
      });

    /*this.x.domain(this.data.map((d: any) => {
      return d.datetime_time
    }));*/


    this.y.domain([0, +d3.max(this.stackedSeries, function (d: any) {
      return d3.max(d, (d: any) => {
        return d[1]
      })
    })]);

    this.layersBar.selectAll('rect')
      .data((d: any) => {
        console.log(d)
        return d;
      })
      .enter()
      .append('rect')
      .attr('y', (d: any) => {

        console.info("d[0]:d[1]===>" + d[0] + ":" + d[1])
        return this.y(d[1])
      })
      .attr('x', (d: any, i: any) => {
        return this.x(i)-12//return this.x(d.data.datetime_time)
      })
      .attr('width', 24)//.attr('width', this.x.bandwidth())
      .attr('height', (d: any, i: any) => {
        return this.y(d[0]) - this.y(d[1]);
      })

  }

  private createLegend(){
    var parent = this;

    var legendSeries =  [{'key':'treat','index':0},{'key':'seal','index':1}];
    this.legend = this.svg.append('g')
                .attr('class', 'legend')
                .attr('transform', 'translate(' + (100 + 12) + ', 0)');

    this.legend.selectAll('rect')
                .data(legendSeries)//.data(this.stackedSeries)
                .enter()
                .append('rect')
                .attr('x', 0)
                .attr('y', function(d, i){
                    return i * 18;
                })
                .attr('width', 12)
                .attr('height', 12)
                .attr('fill', function(d, i){
                    return parent.colors[i];
                });

            this.legend.selectAll('text')
                .data(legendSeries)//.data(this.stackedSeries)
                .enter()
                .append('text')
                .text(function(d){
                  var title = 'Treatment Time';
                  if (d.key == 'seal')
                    title = 'Sealing Time';

                    return title;
                })
                .attr('x', 18)
                .attr('y', function(d, i){
                    return i * 18;
                })
                .attr("fill", "black")
                .attr('text-anchor', 'start')
                .attr('alignment-baseline', 'hanging');
  }

  private createImage() {
    let svgdoc = d3_save_pdf.getSVGDoc(this.svg.node());
    svgdoc.source[0] = svgdoc.source[0].replaceAll("rgb(255, 255, 255)", "rgb(0, 0, 0)"); 
    var blob = new Blob(svgdoc.source, {type: "image/svg+xml;charset=utf-8"});
    var url = window.URL.createObjectURL(blob);

    var image = new Image();
    image.onload = () => {
      
      let canvas = document.createElement('canvas');
      
      canvas.width = this.width;
      canvas.height = this.height;

      let context = canvas.getContext('2d');
      // draw image in canvas starting left-0 , top - 0  
      context.fillStyle = "white";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, this.width, this.height );
      
      this.imgData = canvas.toDataURL("image/png");
      /*var doc = new jsPDF('p', 'pt', [1024,4096]);
      doc.addImage(this.imgData, 'PNG', 100,100, this.width, this.height);
      doc.save('chart.pdf');*/
      //  downloadImage(canvas); need to implement
    };
    
    image.src = url;
  }

  public getImgData(): any {
    return this.imgData;
  }
}
