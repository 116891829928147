import { Report } from './../../../../../model/report';
import {Component, Input, ElementRef, ViewEncapsulation, OnChanges, OnInit, SimpleChanges, SimpleChange} from '@angular/core';
import * as d3 from 'd3';
import d3_save_pdf from 'd3-save-pdf';
import { jsPDF } from "jspdf";


import { Injector } from '@angular/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user-service';
import { UserlistViewModel } from 'src/app/routes/userlist/userlist-view-model';
import { fromEvent, Subject, Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import {Md5} from 'ts-md5/dist/md5';
import { PatientlistViewModel } from 'src/app/routes/patientlist/patientlist-view-model';
import { PatientdetailViewModel } from 'src/app/routes/patientlist/patientdetail/patientdetail-view-model';
import { SdkViewModel } from 'src/app/routes/pages/sdk/sdk-view-model';
import { Patient } from 'src/app/model/patient';
import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';



declare var $: any;

interface StackedChart{
	datetime_time: string,
	pressure_percent: number,
	treat: number,
	seal: number
}
@Component({
  selector: 'app-usage-compliance-pie',
  templateUrl: './usage-compliance-pie.component.html',
  styleUrls: ['./usage-compliance-pie.component.scss']
})

export class UsageCompliancePieComponent implements OnInit{
  private data: StackedChart[];

  private w: number = 1650;//1000;
  private h: number = 400;
  private margin = { top: 10, right: 50, bottom: 60, left: 100 };
  private width = this.w - this.margin.left - this.margin.right;
  private height = this.h - this.margin.top - this.margin.bottom;

  private x: any;
  private y: any;
  private svg: any;
  private g: any;
  private stack: any;
  private chart: any;
  private layersBarArea: any;
  private layersBar: any;
  private xAxis: any;
  private yAxis: any;
  private legend: any;
  private legendItems: any;
  private tooltip: any;
  private xTitle: string;
  private yTitle: string;
  private stackedSeries: any;
  private keys: ['odi'];

  private colors = ['#232D37']//折線圖 ['#63A9EB'];

  userFormGroup: FormGroup;
  router: Router;
  userService: UserService = this.injector.get(UserService);
  patient: Patient;
  patientlistViewModel: PatientlistViewModel = this.injector.get(PatientlistViewModel);
  patientdetailViewModel: PatientdetailViewModel = this.injector.get(PatientdetailViewModel);
  sdkViewModel: SdkViewModel = this.injector.get(SdkViewModel);
  loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
  public reportCompliancePieImage$: Subject<any> = new Subject<any>();
  
  private pie_usagetime_compliance:any;
  private pie_odi:any;
  private pie_seal_percent_compliance:any;
  private demodata:any;
  private imgData: any;
  subscription = new Subscription();
  subscription_char = new Subscription();
  constructor(public injector: Injector, private rxfb: RxFormBuilder,private container: ElementRef) {
    this.router = this.injector.get(Router);
    this.patient = this.sdkViewModel.selectPatient;
    this.patient.clinicalUserList =  this.loginViewModel.loginUser.clinicalUserList;
    
    
    this.patient.data.spo2 = [];
    this.demodata = JSON.parse(JSON.stringify(this.patient.data.spo2));
    
    
    this.pie_usagetime_compliance = JSON.parse(JSON.stringify([]));
    this.pie_odi = JSON.parse(JSON.stringify([]));
    this.pie_seal_percent_compliance = JSON.parse(JSON.stringify([]));
    
    this.userFormGroup = this.rxfb.formGroup(this.patient);
  }

  ngAfterViewInit(): void {
    const parent = this;
    const observer = {
      next(patient) {
        parent.patient.report = patient.report;
        parent.pie_usagetime_compliance = JSON.parse(JSON.stringify(parent.patient.report.pie_usagetime_compliance));
        parent.pie_odi = JSON.parse(JSON.stringify(parent.patient.report.pie_odi));
        parent.pie_seal_percent_compliance = JSON.parse(JSON.stringify(parent.patient.report.pie_seal_percent_compliance));

        parent.patient.data = patient.data;
        parent.demodata = JSON.parse(JSON.stringify(parent.patient.data.spo2));
        parent.showChart();
      }
    };
    const getImgDataObserver = {
      next() {
        parent.reportCompliancePieImage$.next(parent.getImgData());
      }
    };
    this.sdkViewModel.input.reportCompliancePieImage = this.reportCompliancePieImage$;
    this.subscription = this.sdkViewModel.output.getReportCompliancePieImgData.subscribe(getImgDataObserver);
    this.subscription_char = this.sdkViewModel.output.chart.subscribe(observer);

    
    if (this.sdkViewModel.setup_reportCompliancePieImage() === false) {
      throw new Error('example error message');
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscription_char.unsubscribe();
    
  }
  private showChart(){
    this.stack = d3.stack().keys(['spo2']);
    this.removeExistingChartFromParent();
    this.initData();
    this.initTitle();
    this.initScales();
    this.initSvg();
    this.createStack(this.data);
    this.drawAxis();
    // 折線圖裡的長條圖不要畫 this.createLegend();
    //<- 圓餅圖 
    var svg = this.svg,
        width = this.width,
        height = this.height,
        radius = Math.min(width, height) / 2,
        pieXpos = 180,
        pieYpos = (height/2)+20;

    var data = this.pie_usagetime_compliance;//[{"pieLable":">=4h","pieValue":80},{"pieLable":"<4h","pieValue":20}];
    var g = svg.append("g").attr("transform", "translate(" + pieXpos + "," + pieYpos + ")");
    
    var colors = ['#0053B5','#63A9EB','#F5B888','#F58A88'];
    var pie = d3.pie().value(function(d) { 
      return d.pieValue; 
    });
    
    var path = d3.arc().outerRadius(radius - 10).innerRadius(0);
    
    var label = d3.arc().outerRadius(radius).innerRadius(radius - 80);
    
    var arc = g.selectAll(".arc")
               .data(pie(data))
               .enter().append("g")
               .attr("class", "arc");
   
    arc.append("path")
       .attr("d", path)
       .attr("fill", function(d,i) {
          if(d.data.pieLable == ">=4h"){
            return '#0053B5';
          }
          else{
            return '#63A9EB';
          } 
        });

    arc.append("text")
        .attr("transform", function(d) { 
          return "translate(" + label.centroid(d) + ")"; 
        })
        .attr("fill", "black")
        .text(function(d) { return d.data.pieValue+'%'; });
    
    svg.append("g")
       .attr("transform", "translate(" + (pieXpos+10) + "," + 20 + ")")
       .attr("fill", "black")
       .attr("font-weight", 700)
       .style("font-size", "28px")
       .append("text")
       .text("Compliance")
       .attr("class", "title")                
               
    this.legend = this.svg.append('g')
                   .attr('class', 'legend')
                   .attr('transform', 'translate(' + (100 + 12) + ', 0)');
    
    var legendLabel = [
      {"pieLable":">=4h","pieValue":0},
      {"pieLable":"<4h","pieValue":0}
    ];
    this.legend.selectAll('rect')
               .data(legendLabel)
               .enter()
               .append('rect')
               .attr('x', pieXpos*1.4)
               .attr('y', function(d, i){
                  return (i*18)+radius;
                })
               .attr('width', 12)
               .attr('height', 12)
               .attr('fill', function(d, i){              
                  return colors[i];
               });
   
    this.legend.selectAll('text')
               .data(legendLabel)
               .enter()
               .append('text')
               .text(function(d){
                    var title = d.pieLable;
                    return title;
                })
               .attr('x', pieXpos*1.4+18)
               .attr('y', function(d, i){
                  return (i*18)+radius;
                })
               .attr("fill", "black")
               .attr('text-anchor', 'start')
               .attr('alignment-baseline', 'hanging');
    //第二個圓餅
    /*
    colors = ['#F58A88','#F5B888','#0053B5','#63A9EB'];
    pieXpos = 180*4;

    var data2 = this.pie_odi;//[{"pieLable":">=30/h","pieValue":4},{"pieLable":"<30/h","pieValue":10},{"pieLable":"<15/h","pieValue":66},{"pieLable":"<5/h","pieValue":20}];
    var g = svg.append("g").attr("transform", "translate(" + pieXpos + "," + pieYpos + ")");
    
    
    var pie = d3.pie().value(function(d) { 
      return d.pieValue; 
    });
    
    var path = d3.arc().outerRadius(radius - 10).innerRadius(0);
    
    var label = d3.arc().outerRadius(radius).innerRadius(radius - 80);
    
    var arc = g.selectAll(".arc")
               .data(pie(data2))
               .enter().append("g")
               .attr("class", "arc");
   
    arc.append("path")
       .attr("d", path)
       .attr("fill", function(d,i) {
          if(d.data.pieLable == ">=30/h"){
            return '#F58A88';
          }
          if(d.data.pieLable == "<30/h"){
            return '#F5B888';
          }
          if(d.data.pieLable == "<15/h"){
            return '#0053B5';
          }
          else{
            return '#63A9EB';
          }
        });

    arc.append("text")
        .attr("transform", function(d) { 
          return "translate(" + label.centroid(d) + ")"; 
        })
        .attr("fill", "white")
        .text(function(d) { return d.data.pieValue+'%'; });
    
    svg.append("g")
       .attr("transform", "translate(" + (pieXpos+10) + "," + 20 + ")")
       .attr("fill", "white")
       .attr("font-weight", 700)
       .style("font-size", "28px")
       .append("text")
       .text("ODI")
       .attr("class", "title")                
               
    this.legend = this.svg.append('g')
                   .attr('class', 'legend')
                   .attr('transform', 'translate(' + (100 + 12) + ', 0)');
    
    var legendLabel2 = [
      {"pieLable":">=30/h","pieValue":0},
      {"pieLable":"<30/h","pieValue":0},
      {"pieLable":"<15/h","pieValue":0},
      {"pieLable":"<5/h","pieValue":0},
      ];            
    this.legend.selectAll('rect')
               .data(legendLabel2)
               .enter()
               .append('rect')
               .attr('x', pieXpos*1.1)
               .attr('y', function(d, i){
                  return (i*18)+radius;
                })
               .attr('width', 12)
               .attr('height', 12)
               .attr('fill', function(d, i){              
                return colors[i];
               });
   
    this.legend.selectAll('text')
               .data(legendLabel2)
               .enter()
               .append('text')
               .text(function(d){
                    var title = d.pieLable;
                    return title;
                })
               .attr('x', pieXpos*1.1+18)
               .attr('y', function(d, i){
                  return (i*18)+radius;
                })
               .attr("fill", "white")
               .attr('text-anchor', 'start')
               .attr('alignment-baseline', 'hanging');
    */
    //第三個圓餅
    colors = ['#F58A88','#F5B888','#0053B5','#63A9EB'];
    pieXpos = 180*7;

    var data3 = this.pie_seal_percent_compliance;//[{"pieLable":">=80%","pieValue":4},{"pieLable":"<80%","pieValue":10},{"pieLable":"<70%","pieValue":66},{"pieLable":"<60%","pieValue":20}];
    var g = svg.append("g").attr("transform", "translate(" + pieXpos + "," + pieYpos + ")");
    
    var pie = d3.pie().value(function(d) { 
      return d.pieValue; 
    });
    
    var path = d3.arc().outerRadius(radius - 10).innerRadius(0);
    
    var label = d3.arc().outerRadius(radius).innerRadius(radius - 80);
    
    var arc = g.selectAll(".arc")
               .data(pie(data3))
               .enter().append("g")
               .attr("class", "arc");
   
    arc.append("path")
       .attr("d", path)
       .attr("fill", function(d,i) { 
          if(d.data.pieLable == ">=80%"){
            return '#F58A88';
          }
          if(d.data.pieLable == "<80%"){
            return '#F5B888';
          }
          if(d.data.pieLable == "<70%"){
            return '#0053B5';
          }
          else{
            return '#63A9EB';
          }
        });

    arc.append("text")
        .attr("transform", function(d) { 
          return "translate(" + label.centroid(d) + ")"; 
        })
        .attr("fill", "black")
        .text(function(d) { return d.data.pieValue+'%'; });
    
    svg.append("g")
       .attr("transform", "translate(" + (pieXpos+10) + "," + 20 + ")")
       .attr("fill", "black")
       .attr("font-weight", 700)
       .style("font-size", "28px")
       .append("text")
       .text("Seal")
       .attr("class", "title")                
               
    this.legend = this.svg.append('g')
                   .attr('class', 'legend')
                   .attr('transform', 'translate(' + (100 + 12) + ', 0)');
    
    var legendLabel3 = [
      {"pieLable":">=80%","pieValue":0},
      {"pieLable":"<80%","pieValue":0},
      {"pieLable":"<70%","pieValue":0},
      {"pieLable":"<60%","pieValue":0},
    ];
    this.legend.selectAll('rect')
               .data(legendLabel3)
               .enter()
               .append('rect')
               .attr('x', pieXpos*1.05)
               .attr('y', function(d, i){
                  return (i*18)+radius;
                })
               .attr('width', 12)
               .attr('height', 12)
               .attr('fill', function(d, i){              
                return colors[i];
               });
   
    this.legend.selectAll('text')
               .data(legendLabel3)
               .enter()
               .append('text')
               .text(function(d){
                    var title = d.pieLable;
                    return title;
                })
               .attr('x', pieXpos*1.05+18)
               .attr('y', function(d, i){
                  return (i*18)+radius;
                })
               .attr("fill", "black")
               .attr('text-anchor', 'start')
               .attr('alignment-baseline', 'hanging');
    //->
    
    this.createImage();
  }

  ngOnInit() {
    this.showChart();
  }

  private removeExistingChartFromParent() {
    d3.select(this.container.nativeElement).select('svg').remove();
  }

  private initData(){
    this.demodata.forEach(function(d) {
      d.treat = d.treat - d.seal;
    });

    this.data = this.demodata;
  }


  private initTitle() {
    this.xTitle = '';
    this.yTitle = '';
  }

  private initScales() {
    this.x = d3.scaleBand()
      .rangeRound([0, this.width])
      .padding(0.05);
    this.y = d3.scaleLinear().range([this.height, 0]);

  }

  private initSvg() {

    this.svg = d3.select(this.container.nativeElement)
      .select('.chart-container')
      .append('svg')
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr('class', 'chart')
      .attr('width', this.w)
      .attr('height', this.h)
      .attr("viewBox", "0 0 800 400");

    this.chart = this.svg.append('g')
      .classed('chart-contents', true)
      .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

    this.layersBarArea = this.chart.append('g')
      .classed('layers', true);
  }

  private drawAxis() {
    /*折線圖x軸顯示時間
    this.xAxis = this.chart.append('g')
      .classed('x axis', true)
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3.axisBottom(this.x));*/

    this.chart.append("text")
      .attr("y", this.height + 40)
      .attr("x", (this.width / 2))
      .classed('axis-title', true)
      .style("text-anchor", "middle")
      .style('stroke', 'none')
      .text(this.xTitle);

    /*this.yAxis = this.chart.append('g')
      .classed('y axis', true)
      .call(d3.axisLeft(this.y)
        .ticks(7));*/

    this.chart.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - 60)
      .attr("x", 0 - (this.height / 2))
      .attr("fill", "black")
      .style("text-anchor", "middle")
      .style('stroke', 'none')
      .classed('axis-title', true)
      .text(this.yTitle);
  }

  private createStack(stackData: any) {
    this.stackedSeries = this.stack(stackData);
    console.log(this.stackedSeries)
    this.drawChart(this.stackedSeries)
  }

  private drawChart(data: any) {
    var parent = this;

    this.layersBar = this.layersBarArea.selectAll('.layer')
      .data(data)
      .enter()
      .append('g')
      .classed('layer', true)
      .style('fill', (d: any, i: any) => {
        return this.colors[i];
      });

    this.x.domain(this.data.map((d: any,i) => {
      //折線圖 return d.datetime_time
      if(i==0){
        let lastIdx = this.data.length-1;
        return ' from '+this.data[0].datetime_time+' to '+this.data[lastIdx].datetime_time;
      }
    }));


    this.y.domain([0, 100]);

    /* 折線圖裡的長條圖不要畫
    this.layersBar.selectAll('rect')
      .data((d: any) => {
        console.log(d)
        return d;
      })
      .enter()
      .append('rect')
      .attr('y', (d: any) => {
        return this.y(d[1])
      })
      .attr('x', (d: any, i: any) => {
        return this.x(d.data.datetime_time)
      })
      .attr('width', this.x.bandwidth())
      .attr('height', (d: any, i: any) => {
        return this.y(d[0]) - this.y(d[1]);
      })*/
  }

  private createLegend(){
    var parent = this;

    this.legend = this.svg.append('g')
                .attr('class', 'legend')
                .attr('transform', 'translate(' + (100 + 12) + ', 0)');

    this.legend.selectAll('rect')
                .data(this.stackedSeries)
                .enter()
                .append('rect')
                .attr('x', 0)
                .attr('y', function(d, i){
                    return i * 18;
                })
                .attr('width', 12)
                .attr('height', 12)
                .attr('fill', function(d, i){
                    return parent.colors[i];
                });

            this.legend.selectAll('text')
                .data(this.stackedSeries)
                .enter()
                .append('text')
                .text(function(d){
                  var title = '';//折線圖'SpO2';

                    return title;
                })
                .attr('x', 18)
                .attr('y', function(d, i){
                    return i * 18;
                })
                .attr("fill", "black")
                .attr('text-anchor', 'start')
                .attr('alignment-baseline', 'hanging');
  }

  private createImage() {
    let svgdoc = d3_save_pdf.getSVGDoc(this.svg.node());
    svgdoc.source[0] = svgdoc.source[0].replaceAll("rgb(255, 255, 255)", "rgb(0, 0, 0)"); 
    var blob = new Blob(svgdoc.source, {type: "image/svg+xml;charset=utf-8"});
    var url = window.URL.createObjectURL(blob);

    var image = new Image();
    image.onload = () => {
      
      let canvas = document.createElement('canvas');
      
      canvas.width = this.width;
      canvas.height = this.height;

      let context = canvas.getContext('2d');
      // draw image in canvas starting left-0 , top - 0  
      context.fillStyle = "white";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, this.width, this.height );
      
      this.imgData = canvas.toDataURL("image/png");
      /*var doc = new jsPDF('p', 'pt', [1024,4096]);
      doc.addImage(this.imgData, 'PNG', 100,100, this.width, this.height);
      doc.save('chart.pdf');*/
      //  downloadImage(canvas); need to implement
    };
    
    image.src = url;
  }

  public getImgData(): any {
    return this.imgData;
  }
}
