import { Injectable, InjectionToken, Inject, Injector } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user-service';
import { Router } from '@angular/router';

import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';
import { PatientService } from 'src/app/service/patient-service';
import { Patient } from 'src/app/model/patient';
import { UserWebResponse } from 'src/app/model/user-web-response';
import * as CryptoJS from 'crypto-js';
@Injectable({
    providedIn: 'root',
})
export class PatientlistViewModel {
    userService: UserService = this.injector.get(UserService);
    patientService: PatientService = this.injector.get(PatientService);
    router: Router;
    selectPatient:Patient;
    loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
    constructor(private injector: Injector) {
        this.router = this.injector.get(Router);
        console.log(this.loginViewModel.loginUser.token.access_token);
    }

    public input = new class {
        public addPatient: Observable<any>;
        public searchUser: Observable<any>;
        public pagination: Observable<any>;
        public selectPatient: Observable<any>;
        public editPatient: Observable<any>;
        public getCloudUser: Observable<any>;
        public getCloudUserBySerial: Observable<any>;
        public getUser: Observable<any>;
    };

    public output = new class {
        public patientlist = new Subject<User>();
        public cloudUser = new Subject<UserWebResponse>();
        public user = new Subject<User>();
    };

    setup(): boolean {
        let isSetUp = false;
        if (this.input.selectPatient != null) {
            this.onViewClick();
            isSetUp = true;
        }
        return isSetUp;
    }
    onViewClick(): void {
        this.input.selectPatient.subscribe((patient) => {
            
            this.patientService
            .getPatientById(patient.id)
            .subscribe((patient) => {
                this.selectPatient = patient;

                try {
                    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(patient), 'somnicSecretKey').toString();
                      localStorage.setItem('selectPatient', ciphertext);
                } catch (error) {
                    console.error('Error saving to localStorage', error);
                }

                this.router.navigate(['patientlist/patientdetail']);
                //this.output.patientlist.next(user);
            });
        });
        this.input.pagination.subscribe((user) => {
            console.log(user);
            this.userService
                .getUserAPI(user)
                .subscribe((user) => {
                    console.log(user);
                    this.output.patientlist.next(user);
                });
        });
        this.input.searchUser.subscribe((user) => {
            console.log(user);
            this.userService
                .getUserAPI(user)
                .subscribe((user) => {
                    console.log(user);
                    this.output.patientlist.next(user);
                });
        });
    }

    setup_addPatient(): boolean {
        let isSetUp = false;
        if (this.input.addPatient != null) {
            this.onViewClick_addPatient();
            isSetUp = true;
        }
        return isSetUp;
    }
    onViewClick_addPatient(): void {
        this.input.addPatient.subscribe((patient) => {
            console.log(patient);
            this.patientService
                .addPatient(patient)
                .subscribe((result) => {
                    if(result == true){
                        this.router.navigate(['patientlist']);
                    }
                });
        });
        this.input.getCloudUser.subscribe((patient) => {
            console.log(patient);
            this.patientService
                .getCloudUser(patient)
                .subscribe((result) => {
                    this.output.cloudUser.next(result);
                    //this.router.navigate(['patientlist']);
                });
        });
        this.input.getCloudUserBySerial.subscribe((patient) => {
            console.log(patient);
            this.patientService
                .getCloudUserBySerial(patient)
                .subscribe((result) => {
                    this.output.cloudUser.next(result);
                    //this.router.navigate(['patientlist']);
                });
        });
        this.input.getUser.subscribe((patient) => {
            console.log(patient);
            this.patientService
                .getUser(patient)
                .subscribe((user) => {
                    this.output.user.next(user);
                });
        });
    }

    setup_editPatient(): boolean {
        let isSetUp = false;
        if (this.input.editPatient != null) {
            this.onViewClick_editPatient();
            isSetUp = true;
        }
        return isSetUp;
    }

    onViewClick_editPatient(): void {
        this.input.editPatient.subscribe((patient) => {
            console.log(patient);
            this.patientService
                .editPatient(patient)
                .subscribe((result) => {
                    //this.router.navigate(['patientlist']);
                    this.patientService
                    .getPatientById(patient.id)
                    .subscribe((patient) => {
                        this.selectPatient = patient;
                        try {
                            var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(patient), 'somnicSecretKey').toString();
                            localStorage.setItem('selectPatient', ciphertext);
                        } catch (error) {
                            console.error('Error saving to localStorage', error);
                        }
                        
                        if(this.selectPatient.enable == 1){
                            this.router.navigate(['patientlist/patientdetail']);
                        }
                        else{
                            this.router.navigate(['patientlist']);
                        }
                        
                    });
                });
        });
        this.input.getCloudUser.subscribe((patient) => {
            console.log(patient);
            this.patientService
                .getCloudUser(patient)
                .subscribe((result) => {
                    this.output.cloudUser.next(result);
                    //this.router.navigate(['patientlist']);
                });
        });
        this.input.getCloudUserBySerial.subscribe((patient) => {
            console.log(patient);
            this.patientService
                .getCloudUserBySerial(patient)
                .subscribe((result) => {
                    this.output.cloudUser.next(result);
                    //this.router.navigate(['patientlist']);
                });
        });
        this.input.getUser.subscribe((patient) => {
            console.log(patient);
            this.patientService
                .getUser(patient)
                .subscribe((user) => {
                    this.output.user.next(user);
                });
        });
    }
}


