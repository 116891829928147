import { maxNumber,minNumber,minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';
import { RemoteTherapy } from 'src/app/model/remote-therapy';
import { WebDevice } from 'src/app/model/web-device';


@JsonObject('Ecg')
export class Ecg extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('user_id', Number)
    private _user_id: number;
    @JsonProperty('serial', String)
    private _serial: string;
    @JsonProperty('start_time', String)
    private _start_time: string;
    @JsonProperty('start_time_original', Number)
    private _start_time_original: number;
    @JsonProperty('start_date', Number)
    private _start_date: number;
    @JsonProperty('timezone', Number)
    private _timezone: number;
    @JsonProperty('data_type', Number)
    private _data_type: number;
    @JsonProperty('protocol', Number)
    private _protocol: number;
    @JsonProperty('frame_count', Number)
    private _frame_count: number;
    @JsonProperty('data_block_size', Number)
    private _data_block_size: number;
    @JsonProperty('cli_sbp', Number)
    private _cli_sbp: number;
    @JsonProperty('cli_dbp', Number)
    private _cli_dbp: number;
    @JsonProperty('sbp', Number)
    private _sbp: number;
    @JsonProperty('dbp', Number)
    private _dbp: number;
    @JsonProperty('heart_rate', Number)
    private _heart_rate: number;
    @JsonProperty('pr', Number)
    private _pr: number;
    @JsonProperty('status', Number)
    private _status: number;
    @JsonProperty('flag', Number)
    private _flag: number;
    @JsonProperty('data_num', Number)
    private _data_num: number;
    @JsonProperty('ch_ecg', [Number])
    private _ch_ecg: Array<number>;

    @prop()
    get id() {
        return this._id;
    }
    set id(id: number) {
        this._id = id;
    }

    @prop()
    get user_id() {
        return this._user_id;
    }
    set user_id(user_id: number) {
        this._user_id = user_id;
    }

    @prop()
    get serial(): string {
        return this._serial;
    }
    set serial(serial: string) {
        this._serial = serial;
    }

    @prop()
    get start_time() {
        return this._start_time;
    }
    set start_time(start_time: string) {
        this._start_time = start_time;
    }

    @prop()
    get start_time_original() {
        return this._start_time_original;
    }
    set start_time_original(start_time_original: number) {
        this._start_time_original = start_time_original;
    }

    @prop()
    get start_date() {
        return this._start_date;
    }
    set start_date(start_date: number) {
        this._start_date = start_date;
    }

    @prop()
    get timezone() {
        return this._timezone;
    }
    set timezone(timezone: number) {
        this._timezone = timezone;
    }

    @prop()
    get data_type() {
        return this._data_type;
    }
    set data_type(data_type: number) {
        this._data_type = data_type;
    }

    @prop()
    get protocol() {
        return this._protocol;
    }
    set protocol(protocol: number) {
        this._protocol = protocol;
    }

    @prop()
    get frame_count() {
        return this._frame_count;
    }
    set frame_count(frame_count: number) {
        this._frame_count = frame_count;
    }

    @prop()
    get data_block_size() {
        return this._data_block_size;
    }
    set data_block_size(data_block_size: number) {
        this._data_block_size = data_block_size;
    }

    @prop()
    get cli_sbp() {
        return this._cli_sbp;
    }
    set cli_sbp(cli_sbp: number) {
        this._cli_sbp = cli_sbp;
    }

    @prop()
    get cli_dbp() {
        return this._cli_dbp;
    }
    set cli_dbp(cli_dbp: number) {
        this._cli_dbp = cli_dbp;
    }

    @prop()
    get sbp() {
        return this._sbp;
    }
    set sbp(sbp: number) {
        this._sbp = sbp;
    }

    @prop()
    get dbp() {
        return this._dbp;
    }
    set dbp(dbp: number) {
        this._dbp = dbp;
    }

    @prop()
    get heart_rate() {
        return this._heart_rate;
    }
    set heart_rate(heart_rate: number) {
        this._heart_rate = heart_rate;
    }

    @prop()
    get pr() {
        return this._pr;
    }
    set pr(pr: number) {
        this._pr = pr;
    }

    @prop()
    get status() {
        return this._status;
    }
    set status(status: number) {
        this._status = status;
    }

    @prop()
    get flag() {
        return this._flag;
    }
    set flag(flag: number) {
        this._flag = flag;
    }

    @prop()
    get data_num() {
        return this._data_num;
    }
    set data_num(data_num: number) {
        this._data_num = data_num;
    }

    @prop()
    get ch_ecg(): Array<number> {
        return this._ch_ecg;
    }
    set ch_ecg(ch_ecg: Array<number>) {
        this._ch_ecg = ch_ecg;
    }

}
