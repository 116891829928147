import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert, Any} from 'json2typescript';

@JsonObject('RemoteTherapy')
export class RemoteTherapy extends Base{
    @JsonProperty('id', Number)
    private _id: number;
    @JsonProperty('user_id', Number)
    private _user_id: number;
    @JsonProperty('patient_id', Number)
    private _patient_id: number;
    @JsonProperty('cloud_user_id', Number)
    private _cloud_user_id: number;
    @JsonProperty('serial', String)
    private _serial: string;
    @JsonProperty('current', Number)
    private _current: number;
    @JsonProperty('intensity', Number)
    private _intensity: number;
    @JsonProperty('web_timezone', String)
    private _web_timezone: string;
    @JsonProperty('web_time_original', Number)
    private _web_time_original: number;
    @JsonProperty('web_time', Number)
    private _web_time: number;
    @JsonProperty('web_date', String)
    private _web_date: string;
    @JsonProperty('revoked', Number)
    private _revoked: number;
    @JsonProperty('done', Number)
    private _done: number;

    @JsonProperty('auth_code', String)
    private _auth_code: string;

    @prop()
    get id() {
        return this._id;
    }
    set id(id: number) {
        this._id = id;
    }
    
    @prop()
    get user_id(): number {
        return this._user_id;
    }
    set user_id(user_id: number) {
        this._user_id = user_id;
    }

    @prop()
    get patient_id(): number {
        return this._patient_id;
    }
    set patient_id(patient_id: number) {
        this._patient_id = patient_id;
    }

    @prop()
    get cloud_user_id(): number {
        return this._cloud_user_id;
    }
    set cloud_user_id(cloud_user_id: number) {
        this._cloud_user_id = cloud_user_id;
    }

    @prop()
    get serial(): string {
        return this._serial;
    }
    set serial(serial: string) {
        this._serial = serial;
    }

    @prop()
    get current(): number {
        return this._current;
    }
    set current(current: number) {
        this._current = current;
    }

    @prop()
    get intensity(): number {
        return this._intensity;
    }
    set intensity(intensity: number) {
        this._intensity = intensity;
    }

    @prop()
    get web_timezone(): string {
        return this._web_timezone;
    }
    set web_timezone(web_timezone: string) {
        this._web_timezone = web_timezone;
    }

    @prop()
    get web_time_original(): number {
        return this._web_time_original;
    }
    set web_time_original(web_time_original: number) {
        this._web_time_original = web_time_original;
    }

    @prop()
    get web_time(): number {
        return this._web_time;
    }
    set web_time(web_time: number) {
        this._web_time = web_time;
    }

    @prop()
    get web_date(): string {
        return this._web_date;
    }
    set web_date(web_date: string) {
        this._web_date = web_date;
    }

    @prop()
    get revoked(): number {
        return this._revoked;
    }
    set revoked(revoked: number) {
        this._revoked = revoked;
    }

    @prop()
    get done(): number {
        return this._done;
    }
    set done(done: number) {
        this._done = done;
    }

    @prop()
    get auth_code(): string {
        return this._auth_code;
    }
    set auth_code(auth_code: string) {
        this._auth_code = auth_code;
    }

}
