import {Component, Input, ElementRef, ViewEncapsulation, OnChanges, OnInit, SimpleChanges, SimpleChange} from '@angular/core';
import * as d3 from 'd3';
import d3_save_pdf from 'd3-save-pdf';
import { jsPDF } from "jspdf";


import { Injector } from '@angular/core';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user-service';
import { UserlistViewModel } from 'src/app/routes/userlist/userlist-view-model';
import { fromEvent, Subject, Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import {Md5} from 'ts-md5/dist/md5';
import { PatientlistViewModel } from 'src/app/routes/patientlist/patientlist-view-model';
import { PatientdetailViewModel } from 'src/app/routes/patientlist/patientdetail/patientdetail-view-model';
import { SdkViewModel } from 'src/app/routes/pages/sdk/sdk-view-model';

import { Patient } from 'src/app/model/patient';
import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';
import { NullAstVisitor } from '@angular/compiler';



declare var $: any;

interface StackedChart{
	datetime_time: string,
	pressure_percent: number,
	treat: number,
	seal: number
}
@Component({
  selector: 'app-pressure-chart',
  templateUrl: './pressure-chart.component.html',
  styleUrls: ['./pressure-chart.component.scss']
})

export class PressureChartComponent implements OnInit{
  private data: StackedChart[];

  private w: number = 1650;//1000;
  private h: number = 400;
  private margin = { top: 10, right: 50, bottom: 60, left: 100 };
  private width = this.w - this.margin.left - this.margin.right;
  private height = this.h - this.margin.top - this.margin.bottom;

  private x: any;
  private y: any;
  private svg: any;
  private g: any;
  private stack: any;
  private chart: any;
  private layersBarArea: any;
  private layersBar: any;
  private xAxis: any;
  private yAxis: any;
  private legend: any;
  private legendItems: any;
  private tooltip: any;
  private xTitle: string;
  private yTitle: string;
  private stackedSeries: any;
  private keys: ['odi'];

  private colors = ['#232D37']//折線圖 ['#63A9EB'];

  userFormGroup: FormGroup;
  router: Router;
  userService: UserService = this.injector.get(UserService);
  patient: Patient;
  patientlistViewModel: PatientlistViewModel = this.injector.get(PatientlistViewModel);
  patientdetailViewModel: PatientdetailViewModel = this.injector.get(PatientdetailViewModel);
  sdkViewModel: SdkViewModel = this.injector.get(SdkViewModel);
  
  loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
  public pressureImage$: Subject<any> = new Subject<any>();
  
  private demodata:any;
  private imgData: any;
  subscription = new Subscription();
  subscription_char = new Subscription();
  constructor(public injector: Injector, private rxfb: RxFormBuilder,private container: ElementRef) {
    this.router = this.injector.get(Router);
    this.patient = this.sdkViewModel.selectPatient;
    this.patient.clinicalUserList =  this.loginViewModel.loginUser.clinicalUserList;
    
    //初始化
    this.patient.data.pressure = [];
    this.demodata = JSON.parse(JSON.stringify(this.patient.data.pressure));
    
    this.userFormGroup = this.rxfb.formGroup(this.patient);
  }

  ngAfterViewInit(): void {
    const parent = this;
    const observer = {
      next(patient) {
        parent.patient.data = patient.data;
        console.info(parent.patient.data.pressure);
        parent.demodata = JSON.parse(JSON.stringify(parent.patient.data.pressure));
        parent.showChart();
      }
    };
    const getImgDataObserver = {
      next() {
        parent.pressureImage$.next(parent.getImgData());
      }
    };
    
    this.sdkViewModel.input.pressureImage = this.pressureImage$;
    this.subscription = this.sdkViewModel.output.getPressureImgData.subscribe(getImgDataObserver);
    this.subscription_char = this.sdkViewModel.output.chart.subscribe(observer);

    if (this.sdkViewModel.setup_pressureImage() === false) {
      throw new Error('example error message');
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscription_char.unsubscribe();
    
  }
  private showChart(){
    this.stack = d3.stack().keys(['pressure']);
    this.removeExistingChartFromParent();
    this.initData();
    this.initTitle();
    this.initScales();
    this.initSvg();
    this.createStack(this.data);
    this.drawAxis();
    // 折線圖裡的長條圖不要畫 this.createLegend();

    //<- 折線圖 
    var w = this.width;//800 ; // 寬
    var h = this.height;//150 ; // 高
    var dataset = []; //建立空的資料陣列
    var xset = [];
    var hour = [];
    for (var i=0; i < this.demodata.length; i++){
        dataset.push(this.demodata[i].pressure*(-1));
        
        var label = this.demodata[i].datetime_time;
        if(label.indexOf(":00") != -1){
          label =  label.replace(':00', '')
          hour.push(i);
        }
        else{
          label = ''
        } 
        xset.push(label);
    }
    /*var chart_start = this.patient.data.chart_start;
    var chart_end = this.patient.data.chart_end;
    var pre = chart_start;
    var xset = [];
    if(this.demodata.length > 0){
      this.demodata.forEach(element => {
        var first = element[0].time;
        var diff = first - pre;
        for (var i=0; i < diff; i++){
          if( (i != 0) && ((i % 60) == 0)){
            dataset.push(0);
            xset.push('');
          }
        }

        for (var i=0; i < element.length; i++){
          dataset.push(element[i].pressure*(-1));
          xset.push(element[i].datetime_time);
        }

        var idx = element.length -1;
        if(idx >=0){
          pre = element[idx].time;
        }
      });
      
      var diff = chart_end - pre;
      for (var i=0; i < diff; i++){
        if( (i != 0) && ((i % 60) == 0)){
            dataset.push(0);
            xset.push('');
        }
      }
    }*/
    var Ymax = 90;//d3.max(dataset);
    console.info(Ymax)
    var Ymin = 0;//d3.min(dataset);
    console.info(Ymin)
    var xScale = d3.scaleLinear().domain([0, dataset.length]).range([0, w]);
    var yScale = d3.scaleLinear().domain([Ymin, Ymax]).range([h, 0]);
    var line = d3.line()
      .x(function(d,i) {
          return 100+xScale(i + 1); //利用尺度運算資料索引，傳回x的位置
      })
      .y(function(d) {
          return 10+yScale(d); //利用尺度運算資料的值，傳回y的位置
      })
      .defined(function(d){return d > 0 });//只繪製資料y中大於0的點
    this.svg.append('path').attr('d', line(dataset)).attr("stroke", '#63A9EB')
    .attr("stroke-width", "1px")
    .attr("fill", "none");
    //->
    //折線圖x軸顯示時間
    /*var xset = [];
    for (var i=0; i < this.demodata.length; i++){
      xset.push(this.demodata[i].datetime_time);
    }
    this.xAxis = this.chart.append('g')
    .classed('x axis', true)
    .attr("transform", "translate(0," + this.height + ")")
    .call( d3.axisBottom(xScale).tickFormat(i => xset[i] ));
    */
   this.xAxis = this.chart.append('g')
    .classed('x axis', true)
    .attr("transform", "translate(0," + this.height + ")")
    .call( d3.axisBottom(xScale).tickValues(hour)
    .tickFormat(function (d) {
      return xset[d];
    }));
    this.createImage();
  }

  ngOnInit() {
    this.showChart();
  }

  private removeExistingChartFromParent() {
    d3.select(this.container.nativeElement).select('svg').remove();
  }

  private initData(){
    this.demodata.forEach(function(d) {
      d.treat = d.treat - d.seal;
    });

    this.data = this.demodata;
  }


  private initTitle() {
    this.xTitle = 'Time ( Hour )';
    this.yTitle = 'Pressure ( mmHg )';
  }

  private initScales() {
    this.x = d3.scaleBand()
      .rangeRound([0, this.width])
      .padding(0.05);
    this.y = d3.scaleLinear().range([this.height, 0]);

  }

  private initSvg() {

    this.svg = d3.select(this.container.nativeElement)
      .select('.chart-container')
      .append('svg')
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr('class', 'chart')
      .attr('width', this.w)
      .attr('height', this.h)
      .attr("viewBox", "0 0 800 400");

    this.chart = this.svg.append('g')
      .classed('chart-contents', true)
      .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

    this.layersBarArea = this.chart.append('g')
      .classed('layers', true);
  }

  private drawAxis() {
    /*折線圖x軸顯示時間
    this.xAxis = this.chart.append('g')
      .classed('x axis', true)
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3.axisBottom(this.x));*/

    /*this.chart.append("text")
      .attr("y", this.height + 40)
      .attr("x", (this.width / 2))
      .classed('axis-title', true)
      .style("text-anchor", "middle")
      .style('stroke', 'none')
      .text(this.xTitle);*/
    this.chart.append("text")
      .attr("transform", "rotate(0)")//.attr("transform", "rotate(-90)")
      .attr("y",  370 )//.attr("y",  this.width + 40 )
      .attr("x",  760 )//.attr("x", 0 - (this.height) + 20 )
      .attr("fill", "black")
      .style("text-anchor", "middle")
      .style('stroke', 'none')
      .classed('axis-title', true)
      .text(this.xTitle);

    this.yAxis = this.chart.append('g')
      .classed('y axis', true)
      .call(d3.axisLeft(this.y)
        .ticks(7));

    this.chart.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - 60)
      .attr("x", 0 - (this.height / 2))
      .attr("fill", "black")
      .style("text-anchor", "middle")
      .style('stroke', 'none')
      .classed('axis-title', true)
      .text(this.yTitle);
  }

  private createStack(stackData: any) {
    this.stackedSeries = this.stack(stackData);
    console.log(this.stackedSeries)
    this.drawChart(this.stackedSeries)
  }

  private drawChart(data: any) {
    var parent = this;

    this.layersBar = this.layersBarArea.selectAll('.layer')
      .data(data)
      .enter()
      .append('g')
      .classed('layer', true)
      .style('fill', (d: any, i: any) => {
        return this.colors[i];
      });

    this.x.domain(this.data.map((d: any,i) => {
      //折線圖 return d.datetime_time
      if(i==0){
        let lastIdx = this.data.length-1;
        return ' from '+this.data[0].datetime_time+' to '+this.data[lastIdx].datetime_time;
      }
    }));


    this.y.domain([0, -90]);//y軸顯示負壓 this.y.domain([0, 90]);

    /* 折線圖裡的長條圖不要畫
    this.layersBar.selectAll('rect')
      .data((d: any) => {
        console.log(d)
        return d;
      })
      .enter()
      .append('rect')
      .attr('y', (d: any) => {
        return this.y((-1)*d[1])//return this.y(d[1])
      })
      .attr('x', (d: any, i: any) => {
        return this.x(d.data.datetime_time)
      })
      .attr('width', this.x.bandwidth())
      .attr('height', (d: any, i: any) => {
        return this.y(d[1]) - this.y(d[0]);//return this.y(d[0]) - this.y(d[1]);
      })*/
  }

  private createLegend(){
    var parent = this;

    this.legend = this.svg.append('g')
                .attr('class', 'legend')
                .attr('transform', 'translate(' + (100 + 12) + ', 0)');

    this.legend.selectAll('rect')
                .data(this.stackedSeries)
                .enter()
                .append('rect')
                .attr('x', 0)
                .attr('y', function(d, i){
                    return i * 18;
                })
                .attr('width', 12)
                .attr('height', 12)
                .attr('fill', function(d, i){
                    return parent.colors[i];
                });

            this.legend.selectAll('text')
                .data(this.stackedSeries)
                .enter()
                .append('text')
                .text(function(d){
                  var title = '';//折線圖'Pressure';

                    return title;
                })
                .attr('x', 18)
                .attr('y', function(d, i){
                    return i * 18;
                })
                .attr("fill", "black")
                .attr('text-anchor', 'start')
                .attr('alignment-baseline', 'hanging');
  }

  private createImage() {
    let svgdoc = d3_save_pdf.getSVGDoc(this.svg.node());
    svgdoc.source[0] = svgdoc.source[0].replaceAll("rgb(255, 255, 255)", "rgb(0, 0, 0)"); 
    var blob = new Blob(svgdoc.source, {type: "image/svg+xml;charset=utf-8"});
    var url = window.URL.createObjectURL(blob);

    var image = new Image();
    image.onload = () => {
      
      let canvas = document.createElement('canvas');
      
      canvas.width = this.width;
      canvas.height = this.height;

      let context = canvas.getContext('2d');
      // draw image in canvas starting left-0 , top - 0  
      context.fillStyle = "white";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, this.width, this.height );
      
      this.imgData = canvas.toDataURL("image/png");
      /*var doc = new jsPDF('p', 'pt', [1024,4096]);
      doc.addImage(this.imgData, 'PNG', 100,100, this.width, this.height);
      doc.save('chart.pdf');*/
      //  downloadImage(canvas); need to implement
    };
    
    image.src = url;
  }

  public getImgData(): any {
    return this.imgData;
  }
}
