import { minLength,password,email,prop, propObject, propArray } from "@rxweb/reactive-form-validators";

import { Base } from 'src/app/model/base';
import {JsonObject, JsonProperty, JsonConvert} from 'json2typescript';


@JsonObject('TongueDetail')
export class TongueDetail {

    @JsonProperty('time_flag', String)
    private _time_flag: string;
    @JsonProperty('first_max_pressure', Number)
    private _first_max_pressure: number;
    @JsonProperty('second_max_pressure', Number)
    private _second_max_pressure: number;
    @JsonProperty('third_max_pressure', Number)
    private _third_max_pressure: number;
    @JsonProperty('time_for_quit', Number)
    private _time_for_quit: number;
    @JsonProperty('success_threshold_pressure', Number)
    private _success_threshold_pressure: number;
    @JsonProperty('fail_threshold_pressure', Number)
    private _fail_threshold_pressure: number;
    @JsonProperty('time_for_endurance', Number)
    private _time_for_endurance: number;
    @JsonProperty('switch_threshold_basic', Number)
    private _switch_threshold_basic: number;
    @JsonProperty('time_for_below_threshold', Number)
    private _time_for_below_threshold: number;
    @JsonProperty('pressure_preset', Number)
    private _pressure_preset: number;
    @JsonProperty('threshold_percent', Number)
    private _threshold_percent: number;

    @prop()
    get time_flag(): string {
        return this._time_flag;
    }
    set time_flag(time_flag: string) {
        this._time_flag = time_flag;
    }
    @prop()
    get first_max_pressure(): number {
        return this._first_max_pressure;
    }
    set first_max_pressure(first_max_pressure: number) {
        this._first_max_pressure = first_max_pressure;
    }

    @prop()
    get second_max_pressure(): number {
        return this._second_max_pressure;
    }
    set second_max_pressure(second_max_pressure: number) {
        this._second_max_pressure = second_max_pressure;
    }

    @prop()
    get third_max_pressure(): number {
        return this._third_max_pressure;
    }
    set third_max_pressure(third_max_pressure: number) {
        this._third_max_pressure = third_max_pressure;
    }

    @prop()
    get time_for_quit(): number {
        return this._time_for_quit;
    }
    set time_for_quit(time_for_quit: number) {
        this._time_for_quit = time_for_quit;
    }

    @prop()
    get success_threshold_pressure(): number {
        return this._success_threshold_pressure;
    }
    set success_threshold_pressure(success_threshold_pressure: number) {
        this._success_threshold_pressure = success_threshold_pressure;
    }

    @prop()
    get fail_threshold_pressure(): number {
        return this._fail_threshold_pressure;
    }
    set fail_threshold_pressure(fail_threshold_pressure: number) {
        this._fail_threshold_pressure = fail_threshold_pressure;
    }

    @prop()
    get time_for_endurance(): number {
        return this._time_for_endurance;
    }
    set time_for_endurance(time_for_endurance: number) {
        this._time_for_endurance = time_for_endurance;
    }

    @prop()
    get switch_threshold_basic(): number {
        return this._switch_threshold_basic;
    }
    set switch_threshold_basic(switch_threshold_basic: number) {
        this._switch_threshold_basic = switch_threshold_basic;
    }

    @prop()
    get time_for_below_threshold(): number {
        return this._time_for_below_threshold;
    }
    set time_for_below_threshold(time_for_below_threshold: number) {
        this._time_for_below_threshold = time_for_below_threshold;
    }

    @prop()
    get pressure_preset(): number {
        return this._pressure_preset;
    }
    set pressure_preset(pressure_preset: number) {
        this._pressure_preset = pressure_preset;
    }

    @prop()
    get threshold_percent(): number {
        return this._threshold_percent;
    }
    set threshold_percent(threshold_percent: number) {
        this._threshold_percent = threshold_percent;
    }



}
