import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Router } from '@angular/router';
import { Injector } from '@angular/core';
import { fromEvent, Subject, Observable } from 'rxjs';
import { LoginViewModel } from 'src/app/routes/pages/login/login-view-model';
import { TranslatorService } from 'src/app/core/translator/translator.service';//多國語言

import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user-service';

import {Md5} from 'ts-md5/dist/md5';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    valForm: FormGroup;
    router: Router;

    userService: UserService = this.injector.get(UserService);
    user: User;
    loginViewModel: LoginViewModel = this.injector.get(LoginViewModel);
    public login$: Subject<any> = new Subject<any>();
    loginFail:Boolean;
    selectedLanguage: string;
    china_license:boolean;//20231031
    constructor(public settings: SettingsService, private rxfb: RxFormBuilder, public injector: Injector, public translator: TranslatorService) {
        
        if(navigator.language == 'zh-TW'){
            //this.selectedLanguage = 'cn';//for develop
            this.selectedLanguage = 'en';
            //this.selectedLanguage = 'zh'; //繁中版因為翻譯因素先關閉(用英文版)
        }
        else if(navigator.language == 'zh-CN'){
            this.selectedLanguage = 'cn';
        }
        else{
            this.selectedLanguage = 'en';
        }
        this.setLang(this.selectedLanguage);//this.translator.useLanguage(this.selectedLanguage);//多國語言 'en'/'zh'
        
        this.user = this.userService.getUser();
        this.valForm = this.rxfb.formGroup(this.user);
    }

    getLangs() {
        return this.translator.getAvailableLanguages();
    }

    setLang(value) {
        this.translator.useLanguage(value);
    }

    submitForm($ev, user: any) {
        // $ev.preventDefault();
        // for (let c in this.valForm.controls) {
        //     this.valForm.controls[c].markAsTouched();
        // }
        if (this.valForm.controls['email'].valid && this.valForm.controls['password'].valid) {

            const md5 = new Md5();
            this.user.password = md5.appendStr(this.user.password).end() as string;

            console.log('Valid!');
            console.log(this.user);
            this.login$.next(this.user);
            
        }
    }

    ngOnInit() {
        this.router = this.injector.get(Router);
        this.china_license = environment.china_license//20231031
    }
    ngAfterViewInit(): void {
        const parent = this;
        const observer = {
          next(user) {
            console.log('=======>' + user.token.access_token);
            try {
                var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(user), 'somnicSecretKey').toString();
  	            localStorage.setItem('loginUser', ciphertext);
            } catch (error) {
                console.error('Error saving to localStorage', error);
            }
          }
        };
        const loginFailObserver = {
            next(loginFail) {
              console.log('=======>' + loginFail);
              parent.loginFail = loginFail;
            }
        };
        this.loginViewModel.input.login = this.login$;
        this.loginViewModel.output.loginFail.subscribe(loginFailObserver);
        this.loginViewModel.output.userSubject.subscribe(observer);
    
        if (this.loginViewModel.setup() === false) {
          throw new Error('example error message');
        }
        
    }
    
    onSubmit() {
        
    }

}
