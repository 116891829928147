import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';

import { SettingsService } from '../../core/settings/settings.service';
import { ThemesService } from '../../core/themes/themes.service';
import { TranslatorService } from '../../core/translator/translator.service';
import * as CryptoJS from 'crypto-js';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
@Component({
    selector: 'app-offsidebar',
    templateUrl: './offsidebar.component.html',
    styleUrls: ['./offsidebar.component.scss']
})
export class OffsidebarComponent implements OnInit, OnDestroy {
    router: Router;
    currentTheme: any;
    selectedLanguage: string;

    constructor(public injector: Injector,public settings: SettingsService, public themes: ThemesService, public translator: TranslatorService, public elem: ElementRef) {
        this.router = this.injector.get(Router);
        this.currentTheme = themes.getDefaultTheme();
        //this.selectedLanguage = this.getLangs()[0].code;
        //<- 多國語言重新整裡 20230810
        try {
            let ciphertext = localStorage.getItem('lang');
            var bytes  = CryptoJS.AES.decrypt(ciphertext, 'somnicSecretKey');
            var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.selectedLanguage =  decryptedData;//'cn';
            this.setLang(this.selectedLanguage);
        } catch (error) {
            console.error('Error getting data from localStorage', error);
            localStorage.clear();//發生錯誤,清空localStorage
            this.router.navigate(['login']);//發生錯誤,導向404頁
        }
        //->
    }

    ngOnInit() {
        this.anyClickClose();
    }

    setTheme() {
        this.themes.setTheme(this.currentTheme);
    }

    getLangs() {
        return this.translator.getAvailableLanguages();
    }

    setLang(value) {
        this.translator.useLanguage(value);
    }

    anyClickClose() {
        document.addEventListener('click', this.checkCloseOffsidebar, false);
    }

    checkCloseOffsidebar = e => {
        const contains = (this.elem.nativeElement !== e.target && this.elem.nativeElement.contains(e.target));
        if (!contains) {
            this.settings.setLayoutSetting('offsidebarOpen', false);
        }
    }

    ngOnDestroy() {
        document.removeEventListener('click', this.checkCloseOffsidebar);
    }
}
